import useStyleSheet from '@hooks/useStyleSheet';
import loadPlyr from '@utils/loadPlyr';
import React, { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import s from './Video.styles';

export interface VideoProps {
  video: string;
  videoProvider: string;
}

const Video: React.FC<VideoProps> = ({ video, videoProvider }) => {
  const refPlyrElement = useRef<HTMLDivElement>(null);
  const refPlyr = useRef<Plyr>();

  const { ref: refInView, inView } = useInView({ threshold: 0.5 });

  useStyleSheet('https://cdn.plyr.io/3.5.2/plyr.css');

  useEffect(() => {
    doAsync();

    async function doAsync(): Promise<void> {
      if (inView && !refPlyr.current && refPlyrElement.current) {
        refPlyr.current = await loadPlyr(refPlyrElement.current);
      }
    }
  }, [inView]);

  return (
    <s.Container ref={refInView}>
      <div
        ref={refPlyrElement}
        data-plyr-provider={videoProvider}
        data-plyr-embed-id={video}
        data-plyr-config='{ "youtube": { "modestbranding": 1, "playsinline": 1 } }'
      />
    </s.Container>
  );
};

export default Video;
