import { graphql } from 'gatsby';
import React from 'react';

import VerticalFactsSectionPres, {
  badge,
  FactModel,
  image,
} from '@presentation/VerticalFactsSection';
import { ContentModels } from 'schema/ContentModels';
import { Contentful } from 'schema/Contentful';
import { isFact } from '@utils/guards';

export interface VerticalFactsSectionProps {
  data: ContentModels.ContentfulVerticalFactsSection;
}

const VerticalFactsSection: React.FC<VerticalFactsSectionProps> = ({ data }) => {
  return (
    <VerticalFactsSectionPres
      badge={badge(data.badge?.[0]?.public_id)}
      content={data.content?.content}
      ctaNewWindow={data.ctaNewWindow}
      ctaText={data.ctaText}
      ctaUrl={data.ctaUrl}
      facts={createItemArray(data.facts)}
      image={image(data.image?.[0]?.public_id)}
      reverse={data.reverse}
      subtitle={data.subtitle}
      title={data.title ?? ''}
    />
  );
};

export default VerticalFactsSection;

function createItemArray(entries?: Contentful.ContentfulEntry[]): FactModel[] {
  const menuItems = entries?.filter(isFact) ?? [];
  return menuItems.map(createItemModel);
}

function createItemModel(entry: ContentModels.ContentfulFact): FactModel {
  return {
    id: entry.id ?? '',
    content: entry.content ?? '',
  };
}

export const ContentfulVerticalFactsSectionFragment = graphql`
  fragment ContentfulVerticalFactsSectionFragment on ContentfulVerticalFactsSection {
    badge
    content {
      content
    }
    ctaNewWindow
    ctaText
    ctaUrl
    image
    reverse
    subtitle
    title
    facts {
      id
      sys {
        contentType {
          sys {
            id
          }
        }
      }
      ... on ContentfulFact {
        content
      }
    }
  }
`;
