import { markdownStyles } from '@utils/markdown';
import { Device, from } from '@utils/media';
import theme from '@utils/theme';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export interface WrapperProps {
  reverse: boolean;
}

const Wrapper = styled(motion.section)<WrapperProps>`
  @media ${from(Device.TabletLarge)} {
    display: grid;
    align-items: center;
    gap: 32px;
    grid-template-columns: repeat(12, 1fr);

    ${({ reverse }) =>
      reverse
        ? css`
            ${ContentColumn} {
              grid-column: -7 / span 6;
            }

            ${ImageColumn} {
              grid-column: 2 / span 4;
            }
          `
        : css`
            ${ContentColumn} {
              grid-column: 2 / span 6;
            }

            ${ImageColumn} {
              grid-column: -5 / span 4;
            }
          `}
  }
`;

const ContentColumn = styled.div`
  display: flex;
  flex-direction: column;

  @media ${from(Device.TabletLarge)} {
    grid-row: 1;
  }
`;

const ImageColumn = styled(motion.div)`
  position: relative;
  margin: 32px auto;
  max-width: 514px;

  @media ${from(Device.TabletLarge)} {
    grid-row: 1;
    margin: 100px 0;
  }
`;

const Badge = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100px;
  transform: translate3d(-10%, -25%, 0);
  width: 100px;
  z-index: 1;

  .gatsby-image-wrapper {
    height: 100px !important;
    width: 100px !important;
  }

  @media ${from(Device.TabletLarge)} {
    height: 200px;
    transform: translate3d(-50%, -50%, 0);
    width: 200px;

    .gatsby-image-wrapper {
      height: 200px !important;
      width: 200px !important;
    }
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;

const Title = styled(motion.h2)`
  color: ${theme.brand.red.default};
  font-size: 35px;
  line-height: 40px;
  margin: 0 0 24px;
  text-transform: uppercase;

  @media ${from(Device.TabletLarge)} {
    font-size: 60px;
    line-height: 80px;
  }
`;

const Subtitle = styled(motion.h3)`
  color: ${theme.brand.blue.dark};
  font-size: 14px;
  line-height: 16px;
  margin: 0;
  text-transform: uppercase;

  @media ${from(Device.TabletLarge)} {
    font-size: 18px;
    line-height: 20px;
  }
`;

const Content = styled(motion.div)`
  ${markdownStyles};

  font-size: 16px;
  letter-spacing: 0.02em;
  line-height: 26px;
  margin: 0 0 48px;
  text-transform: uppercase;

  @media ${from(Device.TabletLarge)} {
    font-size: 20px;
    line-height: 28px;
  }
`;

const FactsList = styled(motion.div)`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(1, 1fr);
  margin: 0 0 48px;

  @media ${from(Device.TabletLarge)} {
    gap: 32px;
    grid-template-columns: repeat(2, 1fr);
  }
`;

const FactItem = styled(motion.div)`
  display: flex;
  flex-direction: row;
  min-width: 0;
  place-items: center;
`;

const FactItemIconWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  background: var(--bg-color);
  border: 3px solid ${theme.brand.blue.dark};
  border-radius: 50%;
  color: ${theme.brand.red.default};
  flex: 0 0 66px;
  height: 66px;
  margin: 0 32px 0 0;
  padding: 16px;
  place-content: center;
  place-items: center;
  width: 66px;

  .theme-none & {
    background: ${theme.brand.paper.default};
  }

  @media ${from(Device.TabletLarge)} {
    flex: 0 0 110px;
    height: 110px;
    padding: 24px;
    width: 110px;
  }
`;

const FactItemIcon = styled.img`
  display: block;
  height: 100%;
  object-fit: contain;
  object-position: center center;
  width: 100%;
`;

const FactItemContent = styled(motion.div)`
  color: ${theme.brand.blue.dark};
  flex: 1 1 auto;
  ${theme.fonts.brandon.bold};
  font-size: 16px;
  letter-spacing: 0.02em;
  line-height: 26px;
  margin: 0;
  max-width: 180px;
  text-transform: uppercase;

  @media ${from(Device.TabletLarge)} {
    line-height: 28px;
  }
`;

const CallToAction = styled.div`
  margin: 0 auto;

  @media ${from(Device.TabletLarge)} {
    margin: 0;
  }
`;

export default {
  Badge,
  CallToAction,
  Content,
  ContentColumn,
  FactItem,
  FactItemContent,
  FactItemIcon,
  FactItemIconWrapper,
  FactsList,
  ImageColumn,
  Subtitle,
  Title,
  TitleContainer,
  Wrapper,
};
