import bgLinesTiled from '@img/lines-bg-tiled.jpg';
import bgPaperTiled from '@img/paper-bg-tiled.jpg';
import { markdownStyles } from '@utils/markdown';
import { Device, from, until } from '@utils/media';
import theme from '@utils/theme';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const Container = styled(motion.div)`
  position: relative;
  display: flex;
  background: transparent url(${bgLinesTiled});
  background-repeat: repeat;
  border: 3px solid ${theme.brand.blue.dark};
  border-radius: 5px;
  flex-direction: column;
  overflow: hidden;
  padding: 32px 24px;
  place-content: center;
  place-items: center;
  margin: 0 auto;
  max-width: max-content;
  width: 100%;

  @media ${from(Device.TabletLarge)} {
    padding: 192px 160px 160px;
  }

  @media ${from(Device.Desktop)} {
    padding: 192px 248px;
  }
`;

const Inner = styled.div`
  display: flex;
  background: ${theme.brand.paper.default} url(${bgPaperTiled});
  background-repeat: repeat;
  border: 3px solid ${theme.brand.blue.dark};
  border-radius: 5px;
  flex-direction: column;
  max-width: 820px;
  padding: 152px 12px 40px;
  place-content: center;
  place-items: center;
  text-align: center;
  width: 100%;

  @media ${from(Device.TabletLarge)} {
    padding: 48px 64px;
  }
`;

const Title = styled(motion.h2)`
  font-size: 30px;
  line-height: 40px;
  margin-bottom: 16px;
  max-width: 700px;
  text-transform: uppercase;

  @media ${from(Device.Desktop)} {
    font-size: 50px;
    line-height: 58px;
  }
`;

const Content = styled(motion.div)`
  ${markdownStyles};
  font-size: 16px;
  letter-spacing: 0.02em;
  line-height: 24px;
  margin-bottom: 24px;
  max-width: 700px;
  text-transform: uppercase;

  @media ${from(Device.Desktop)} {
    font-size: 18px;
    line-height: 26px;
  }
`;

const CallToAction = styled(motion.div)``;

const Badge = styled(motion.div)`
  position: absolute;
  top: 54px;
  left: 50%;
  display: block;
  height: 120px;
  object-fit: contain;
  object-position: center center;
  transform: translate3d(-50%, 0, 0);
  width: 120px;

  @media ${from(Device.TabletLarge)} {
    top: 24px;
    height: 150px;
    width: 150px;
  }

  @media ${until(Device.TabletLarge)} {
    .gatsby-image-wrapper {
      height: 120px !important;
      width: 120px !important;
    }
  }
`;

const Turtle = styled(motion.div)`
  position: absolute;
  bottom: 50%;
  left: -96px;
  display: none;
  object-fit: contain;
  object-position: center center;
  transform: translateY(100%);

  @media ${from(Device.TabletLarge)} {
    display: block;
  }

  @media ${from(Device.Desktop)} {
    left: 0;
  }
`;

export default {
  Badge,
  CallToAction,
  Container,
  Content,
  Inner,
  Title,
  Turtle,
};
