import styled from 'styled-components';
import plyrStyles from '@utils/plyrStyles';

const Container = styled.section`
  ${plyrStyles};
  margin: 64px 0;
`;

export default {
  Container,
};
