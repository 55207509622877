import SectionWrapper from '@presentation/SectionWrapper';
import { markdownStyles } from '@utils/markdown';
import { Device, from } from '@utils/media';
import scrollbar from '@utils/scrollbar';
import theme from '@utils/theme';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

const Wrapper = styled(SectionWrapper)``;

const Container = styled(motion.div)`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  place-content: space-evenly;
  place-items: center;
  text-align: center;
`;

const ContentContainer = styled.div``;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin: 0 0 16px;
`;

const Title = styled(motion.h2)`
  color: ${theme.brand.red.default};
  font-size: 35px;
  line-height: 40px;
  margin: 0;
  max-width: 820px;
  text-transform: uppercase;

  @media ${from(Device.TabletLarge)} {
    font-size: 60px;
    line-height: 70px;
  }
`;

const Subtitle = styled(motion.h3)`
  font-size: 14px;
  letter-spacing: 0.05em;
  line-height: 16px;
  margin: 0 0 16px;
  max-width: 820px;
  text-transform: uppercase;

  @media ${from(Device.TabletLarge)} {
    font-size: 18px;
    letter-spacing: 0.05em;
    line-height: 20px;
  }
`;

const Content = styled(motion.div)`
  ${markdownStyles};
  ${theme.fonts.brandon.bold};
  font-size: 16px;
  letter-spacing: 0.02em;
  line-height: 26px;
  margin: 0 0 32px;
  max-width: 820px;
  text-transform: uppercase;

  @media ${from(Device.TabletLarge)} {
    font-size: 20px;
    letter-spacing: 0.02em;
    line-height: 28px;
  }
`;

const ItemsContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  min-width: 0;
  place-content: center;
  place-items: center;
  width: 100%;
`;

const ItemsContainerInner = styled.div`
  ${scrollbar}
  display: grid;
  min-width: 0;
  overflow-x: auto;
  width: 100%;
`;

const ItemsTrack = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 -16px;
  padding-bottom: 16px;
`;

const Item = styled.div`
  position: relative;
  flex-basis: 25vw;
  margin: 0 16px;
  min-width: 80vw;

  @media ${from(Device.MobileLarge)} {
    min-width: 40vw;
  }

  @media ${from(Device.TabletLarge)} {
    min-width: 25vw;
  }

  &:focus,
  &:hover {
    ${() => ItemTitle} {
      opacity: 1;
    }
  }
`;

const ItemImage = styled.div`
  display: block;
`;

const ItemTitle = styled.h3`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  background: linear-gradient(to bottom, rgba(14, 77, 143, 0), rgba(14, 77, 143, 1));
  color: ${theme.brand.white.default};
  flex-direction: column;
  ${theme.fonts.brandon.bold};
  font-size: 18px;
  height: 50%;
  justify-content: flex-end;
  letter-spacing: 0.02em;
  line-height: 28px;
  opacity: 0;
  padding: 32px 48px;
  text-align: left;
  text-transform: uppercase;
  transition: opacity 0.3s ease-out;
  width: 100%;
`;

export default {
  Container,
  Content,
  ContentContainer,
  Item,
  ItemImage,
  ItemTitle,
  ItemsContainer,
  ItemsContainerInner,
  ItemsTrack,
  Subtitle,
  Title,
  TitleContainer,
  Wrapper,
};
