import ContentImagePres, { image } from '@presentation/ContentImage';
import { graphql } from 'gatsby';
import React from 'react';
import { ContentModels } from 'schema/ContentModels';

export interface ContentImageProps {
  data: ContentModels.ContentfulContentImage;
}

const ContentImage: React.FC<ContentImageProps> = ({ data }) => {
  const imageData = data.contentImage?.[0];
  const imageId = imageData?.public_id ?? '';
  const height = imageData?.height ?? 1;
  const width = imageData?.width ?? 1;

  return <ContentImagePres image={image(imageId, width / height)} title={data.title} />;
};

export default ContentImage;

export const ContentfulContentImageFragment = graphql`
  fragment ContentfulContentImageFragment on ContentfulContentImage {
    contentImage: image
    title
  }
`;
