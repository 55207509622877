import FactsSectionPres, { badge, FactModel, image } from '@presentation/FactsSection';
import { ComponentThemeTaxonomy } from '@presentation/SectionWrapper';
import { isFact } from '@utils/guards';
import { graphql } from 'gatsby';
import snakeCase from 'lodash/snakeCase';
import React from 'react';
import { Contentful } from 'schema/Contentful';
import { ContentModels } from 'schema/ContentModels';

export interface FactsSectionProps {
  data: ContentModels.ContentfulFactsSection;
}

const FactsSection: React.FC<FactsSectionProps> = ({ data }) => {
  return (
    <FactsSectionPres
      badge={badge(data.badge?.[0]?.public_id)}
      content={data.content?.content}
      ctaNewWindow={data.ctaNewWindow}
      ctaText={data.ctaText}
      ctaUrl={data.ctaUrl}
      facts={createItemArray(data.facts)}
      image={image(data.image?.[0]?.public_id)}
      reverse={data.reverse}
      subtitle={data.subtitle}
      theme={snakeCase(data.theme) as ComponentThemeTaxonomy}
      title={data.title ?? ''}
    />
  );
};

export default FactsSection;

function createItemArray(entries?: Contentful.ContentfulEntry[]): FactModel[] {
  const menuItems = entries?.filter(isFact) ?? [];
  return menuItems.map(createItemModel);
}

function createItemModel(entry: ContentModels.ContentfulFact): FactModel {
  return {
    id: entry.id ?? '',
    content: entry.content ?? '',
    icon: entry.icon?.[0]?.secure_url ?? '',
  };
}

export const ContentfulFactsSectionFragment = graphql`
  fragment ContentfulFactsSectionFragment on ContentfulFactsSection {
    badge
    content {
      content
    }
    ctaNewWindow
    ctaText
    ctaUrl
    image
    reverse
    subtitle
    theme
    title
    facts {
      id
      sys {
        contentType {
          sys {
            id
          }
        }
      }
      ... on ContentfulFact {
        content
        icon
      }
    }
  }
`;
