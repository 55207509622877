import { markdownStyles } from '@utils/markdown';
import theme from '@utils/theme';
import styled from 'styled-components';

const Container = styled.blockquote`
  display: flex;
  border-left: 5px solid ${theme.brand.red.default};
  flex-direction: column;
  margin: 64px 0;
  padding: 16px 0 16px 48px;
  place-content: flex-start;
  place-items: flex-start;
`;

const Content = styled.div`
  ${markdownStyles};
  ${theme.fonts.venusian};
  font-size: 20px;
  line-height: 30px;
  max-width: 620px;
  quotes: '“' '”';
  text-transform: uppercase;

  &::before {
    content: open-quote;
  }

  &::after {
    content: close-quote;
  }
`;

const Citation = styled.cite`
  ${theme.fonts.brandon.bold};
  font-size: 16px;
  line-height: 23px;
  margin-top: 24px;
  text-transform: uppercase;
`;

export default {
  Citation,
  Container,
  Content,
};
