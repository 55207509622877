import { graphql } from 'gatsby';
import React from 'react';

import RumFactsPres, { badge, FactModel, image } from '@presentation/RumFacts';
import { ContentModels } from 'schema/ContentModels';
import { Contentful } from 'schema/Contentful';
import { isFact } from '@utils/guards';

export interface RumFactsProps {
  data: ContentModels.ContentfulRumFacts;
}

const RumFacts: React.FC<RumFactsProps> = ({ data }) => {
  return (
    <RumFactsPres
      badge={badge(data.badge?.[0]?.public_id)}
      content={data.content?.content}
      facts={createItemArray(data.facts)}
      image={image(data.image?.[0]?.public_id)}
      subtitle={data.subtitle}
      title={data.title}
    />
  );
};

export default RumFacts;

function createItemArray(entries?: Contentful.ContentfulEntry[]): FactModel[] {
  const menuItems = entries?.filter(isFact) ?? [];
  return menuItems.map(createItemModel);
}

function createItemModel(entry: ContentModels.ContentfulFact): FactModel {
  return {
    id: entry.id ?? '',
    content: entry.content ?? '',
    icon: entry.icon?.[0]?.secure_url ?? '',
  };
}

export const ContentfulRumFactsFragment = graphql`
  fragment ContentfulRumFactsFragment on ContentfulRumFacts {
    badge
    content {
      content
    }
    image
    subtitle
    title
    facts {
      id
      sys {
        contentType {
          sys {
            id
          }
        }
      }
      ... on ContentfulFact {
        content
        icon
      }
    }
  }
`;
