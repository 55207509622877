import GatsbyImage, { FixedObject } from 'gatsby-image';
import React from 'react';
import SectionWrapper, { ComponentThemeTaxonomy } from '@presentation/SectionWrapper';
import s from './Testimonials.styles';
import imageUrl from '@utils/cloudinary';
import Markdown from 'markdown-to-jsx';
import { Variants } from 'framer-motion';
import useWasViewed from '@hooks/useWasViewed';

export interface TestimonialItemModel {
  id: string;
  content: string;
  logo: FixedObject;
  title: string;
}

export interface TestimonialItemProps extends TestimonialItemModel {}

export interface TestimonialsProps {
  title: string;
  items: TestimonialItemModel[];
  theme?: ComponentThemeTaxonomy;
}

const TestimonialItem: React.FC<TestimonialItemProps> = ({ content, logo, title }) => {
  const itemVariants: Variants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.15,
      },
    },
  };

  const logoVariants: Variants = {
    hidden: {
      opacity: 0,
      scale: 0.5,
      y: 50,
    },
    visible: {
      opacity: 1,
      scale: 1,
      y: 0,
    },
  };

  const contentVariants: Variants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  };

  return (
    <s.Item initial="hidden" variants={itemVariants}>
      <s.Logo initial="hidden" variants={logoVariants}>
        <GatsbyImage fixed={logo} alt={title} />
      </s.Logo>
      <s.Content initial="hidden" variants={contentVariants}>
        <Markdown>{content}</Markdown>
      </s.Content>
    </s.Item>
  );
};

const Testimonials: React.FC<TestimonialsProps> = ({
  items,
  title,
  theme = ComponentThemeTaxonomy.WHITE,
}) => {
  const { ref: refInView, wasViewed } = useWasViewed({
    rootMargin: '0px 0px -50% 0px',
  });

  const titleVariants: Variants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  };

  const itemsVariants: Variants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.15,
      },
    },
  };

  return (
    <SectionWrapper ref={refInView} padding theme={theme}>
      <s.Wrapper animate={wasViewed ? 'visible' : 'hidden'}>
        <s.Title initial="hidden" variants={titleVariants}>
          {title}
        </s.Title>
        <s.Items initial="hidden" variants={itemsVariants}>
          {items.map((item) => (
            <TestimonialItem key={item.id} {...item} />
          ))}
        </s.Items>
      </s.Wrapper>
    </SectionWrapper>
  );
};

export default Testimonials;

export function logo(publicId?: string): FixedObject {
  return {
    height: 126,
    src: imageUrl(publicId, 'c_pad,w_227,h_126'),
    srcSet: `
      ${imageUrl(publicId, 'c_pad,w_227,h_126')} 1x,
      ${imageUrl(publicId, 'c_pad,w_227,h_126,dpr_2')} 2x
    `,
    width: 227,
  };
}
