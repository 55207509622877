import { MotionStyle, MotionValue, useMotionValue } from 'framer-motion';
import React, { useEffect, useRef } from 'react';
import { CSSProperties } from 'styled-components';
import s from './CtaSection.styles';

export interface AnimatedTurtleProps {
  motionStyle: MotionStyle;
  offset: number;
  progress: MotionValue<number>;
  style: CSSProperties;
}

const AnimatedTurtle: React.FC<AnimatedTurtleProps> = ({
  motionStyle,
  offset = 0,
  progress,
  style,
}) => {
  const refPath = useRef<SVGPathElement>(null);
  const refTurtle = useRef<SVGSVGElement>(null);

  const x = useMotionValue(0);
  const y = useMotionValue(0);
  const rotate = useMotionValue(0);

  useEffect(() => {
    const totalLength = refPath.current?.getTotalLength() ?? 0;

    return progress.onChange((v) => {
      // Get the point on the path of the current progress and offset.
      const point = refPath.current?.getPointAtLength((v - offset) * totalLength) ?? { x: 0, y: 0 };

      // Get point, subtract half turtle width/height to center.
      const pX = point.x - 36;
      const pY = point.y - 36;

      // Get previous values.
      const lX = x.getPrevious();
      const lY = y.getPrevious();

      // Calculate position deltas.
      const dX = pX - lX;
      const dY = pY - lY;

      // Calculate a normalized vector representing direction.
      const direction = normalize({ x: dX, y: dY });

      // Calculate the facing in degrees.
      let angle = getAngle(direction) + 90;

      // If we're scrolling backwards avoid rotating the turtle to face up - prefer it to "reverse"
      if (progress.getVelocity() < 0) {
        angle += 180;
      }

      // Update turtle transforms.
      x.set(pX);
      y.set(pY);
      rotate.set(angle);
    });
  }, [progress]);

  return (
    <s.TurtleWrapper style={style}>
      <s.Turtle
        ref={refTurtle}
        style={{
          ...motionStyle,
          rotate: rotate,
          x: x,
          y: y,
          originX: '50',
          originY: '50',
        }}
      />
      <s.TurtlePath viewBox="0 0 51.36 635.22">
        <path
          ref={refPath}
          d="M20.91.26s-45.92,74.08,0,149.2c39,63.8,40.87,90.71,0,159.42-27,45.38-22,94.38,7,150.72C74.37,550.07,23.26,635,23.26,635"
        />
      </s.TurtlePath>
    </s.TurtleWrapper>
  );
};

export default AnimatedTurtle;

type Vector2D = { x: number; y: number };

function getAngle(v: Vector2D): number {
  const angle = Math.atan2(v.y, v.x);
  const degrees = (180 * angle) / Math.PI;
  return (360 + Math.round(degrees)) % 360;
}

function magnitude(v: Vector2D): number {
  return Math.sqrt(Math.pow(v.x, 2) + Math.pow(v.y, 2));
}

function normalize(v: Vector2D): Vector2D {
  const m = magnitude(v);
  return { x: v.x / m, y: v.y / m };
}
