import React from 'react';
import SectionWrapper from '@presentation/SectionWrapper';
import s from './RichText.styles';

export interface RichTextProps {}

const RichText: React.FC<RichTextProps> = ({ children }) => {
  return (
    <SectionWrapper padding>
      <s.Container>{children}</s.Container>
    </SectionWrapper>
  );
};

export default RichText;
