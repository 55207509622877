import { graphql } from 'gatsby';
import React from 'react';

import ReviewsCarouselPres, { ReviewModel } from '@presentation/ReviewsCarousel';
import { ContentModels } from 'schema/ContentModels';
import { Contentful } from 'schema/Contentful';
import { isReview } from '@utils/guards';

export interface ReviewsCarouselProps {
  data: ContentModels.ContentfulReviewsCarousel;
}

const ReviewsCarousel: React.FC<ReviewsCarouselProps> = ({ data }) => {
  return <ReviewsCarouselPres items={createItemArray(data.reviews)} title={data.title} />;
};

export default ReviewsCarousel;

function createItemArray(entries?: Contentful.ContentfulEntry[]): ReviewModel[] {
  const menuItems = entries?.filter(isReview) ?? [];
  return menuItems.map(createItemModel);
}

function createItemModel(entry: ContentModels.ContentfulReview): ReviewModel {
  return {
    id: entry.id ?? '',
    citation: entry.citation ?? '',
    content: entry.content?.content ?? '',
  };
}

export const ContentfulReviewsCarouselFragment = graphql`
  fragment ContentfulReviewsCarouselFragment on ContentfulReviewsCarousel {
    reviews {
      id
      sys {
        contentType {
          sys {
            id
          }
        }
      }
      ... on ContentfulReview {
        citation
        content {
          content
        }
      }
    }
    title
  }
`;
