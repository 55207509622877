import { graphql } from 'gatsby';
import React from 'react';

import BackgroundImageCtaPres, { image } from '@presentation/BackgroundImageCta';
import { ContentModels } from 'schema/ContentModels';

export interface BackgroundImageCtaProps {
  data: ContentModels.ContentfulBackgroundImageCta;
}

const BackgroundImageCta: React.FC<BackgroundImageCtaProps> = ({ data }) => {
  return (
    <BackgroundImageCtaPres
      align={getAlignment(data.align)}
      cta1NewWindow={data.cta1NewWindow}
      cta1Text={data.cta1Text}
      cta1Url={data.cta1Url}
      cta2NewWindow={data.cta2NewWindow}
      cta2Text={data.cta2Text}
      cta2Url={data.cta2Url}
      image={image(data.image?.[0]?.public_id)}
      showContent={data.showContent}
      title={data.title ?? ''}
    />
  );
};

export default BackgroundImageCta;

function getAlignment(align?: string): 'left' | 'center' | 'right' {
  switch (align?.toLowerCase()) {
    case 'left':
      return 'left';

    case 'right':
      return 'right';

    default:
      return 'center';
  }
}

export const ContentfulBackgroundImageCtaFragment = graphql`
  fragment ContentfulBackgroundImageCtaFragment on ContentfulBackgroundImageCta {
    align
    cta1NewWindow
    cta1Text
    cta1Url
    cta2NewWindow
    cta2Text
    cta2Url
    image
    showContent
    title
  }
`;
