import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import CentreBannerPres, { videoPoster, videoPosterMobile } from '@presentation/CentreBanner';
import { ContentModels } from 'schema/ContentModels';
import { FixedObject } from 'gatsby-image';

export interface CentreBannerProps {
  data: ContentModels.ContentfulCentreBanner;
}

const CentreBanner: React.FC<CentreBannerProps> = ({ data }) => {
  const { turtleImage1, turtleImage2 } = useTurtleImages();

  return (
    <CentreBannerPres
      content={data.content?.content}
      subtitle={data.subtitle}
      title={data.title ?? ''}
      turtleImage1={turtleImage1}
      turtleImage2={turtleImage2}
      video={data.video}
      videoPoster={videoPoster(data.videoPoster?.[0]?.public_id)}
      videoPosterMobile={videoPosterMobile(data.videoPosterMobile?.[0]?.public_id)}
    />
  );
};

export default CentreBanner;

interface FileQuery {
  turtleImage1?: {
    childCloudinaryAsset?: {
      fixed?: FixedObject;
    };
  };
  turtleImage2?: {
    childCloudinaryAsset?: {
      fixed?: FixedObject;
    };
  };
}

function useTurtleImages(): {
  turtleImage1: FixedObject | undefined;
  turtleImage2: FixedObject | undefined;
} {
  const data = useStaticQuery<FileQuery>(graphql`
    {
      turtleImage1: file(name: { eq: "turtle-red" }) {
        name
        childCloudinaryAsset {
          fixed(width: 483, height: 343, transformations: "f_auto,q_auto") {
            aspectRatio
            height
            src
            srcSet
            width
          }
        }
      }
      turtleImage2: file(name: { eq: "turtle-red" }) {
        name
        childCloudinaryAsset {
          fixed(width: 483, height: 343, transformations: "f_auto,q_auto") {
            aspectRatio
            height
            src
            srcSet
            width
          }
        }
      }
    }
  `);

  return {
    turtleImage1: data.turtleImage1?.childCloudinaryAsset?.fixed,
    turtleImage2: data.turtleImage2?.childCloudinaryAsset?.fixed,
  };
}

export const ContentfulCentreBannerFragment = graphql`
  fragment ContentfulCentreBannerFragment on ContentfulCentreBanner {
    content {
      content
    }
    subtitle
    title
    video
    videoPoster
    videoPosterMobile
  }
`;
