import IconChevronRight from '!babel-loader!react-svg-loader!@img/icon-chevron-right.svg';
import IconClose from '!babel-loader!react-svg-loader!@img/icon-close.svg';
import bgTiled from '@img/stressed-bg-tiled.jpg';
import { SiteWide } from '@presentation/SectionWrapper/SectionWrapper.styles';
import { markdownStyles } from '@utils/markdown';
import { Device, from, until } from '@utils/media';
import plyrStyles from '@utils/plyrStyles';
import theme from '@utils/theme';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

const ContentWrapper = styled.div`
  position: relative;
  display: grid;
  min-height: 100vh;
  overflow: hidden;
  padding: 158px 0 64px;

  @supports (-webkit-touch-callout: none) {
    min-height: -webkit-fill-available;
  }

  @media ${from(Device.TabletLarge)} {
    padding: 224px 0 calc(20vh + 16px);
  }
`;

const Wrapper = styled(SiteWide)`
  display: flex;
  flex-direction: column;
  place-content: space-evenly;
  place-items: center;
  text-align: center;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  place-content: center;
  place-items: center;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;

const Title = styled(motion.h2)`
  color: ${theme.brand.red.default};
  font-size: 40px;
  line-height: 1.1em;
  margin: 0 0 16px;
  max-width: 740px;
  text-transform: uppercase;

  @media ${from(Device.TabletLarge)} {
    font-size: 55px;
  }

  @media ${from(Device.DesktopLarge)} {
    font-size: 75px;
  }
`;

const Subtitle = styled(motion.h3)`
  font-size: 14px;
  letter-spacing: 0.02em;
  line-height: 16px;
  margin: 0 0 16px;
  text-transform: uppercase;

  @media ${from(Device.TabletLarge)} {
    font-size: 18px;
    letter-spacing: 0.05em;
    line-height: 20px;
  }
`;

const Content = styled(motion.div)`
  ${markdownStyles};
  ${theme.fonts.brandon.bold};
  font-size: 16px;
  letter-spacing: 0.02em;
  line-height: 26px;
  margin: 0 0 24px;
  max-width: 660px;
  text-transform: uppercase;

  @media ${from(Device.TabletLarge)} {
    font-size: 20px;
    letter-spacing: 0.02em;
    line-height: 28px;
  }
`;

const VideoCta = styled(motion.button)`
  display: flex;
  flex-direction: column;
  color: ${theme.brand.blue.dark};
  cursor: pointer;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  place-content: center;
  place-items: center;
  text-align: center;
  transition: color 0.3s ease-out;

  @media ${from(Device.Tablet)} {
    margin: 48px 0 0;
  }

  @media ${from(Device.DesktopSmall)} {
    margin: 0;
  }

  &:disabled {
    cursor: not-allowed;
    filter: grayscale(1);
    opacity: 0.7;
  }

  &:focus:not(:disabled),
  &:hover:not(:disabled) {
    color: ${theme.brand.red.default};

    ${() => VideoCtaIconWrapper} {
      background: ${theme.brand.blue.dark};
      color: ${theme.brand.white.default};
      transform: translate3d(0, 4px, 0);
    }
  }
`;

const VideoCtaText = styled.span`
  ${theme.fonts.venusian};
  font-size: 16px;
  letter-spacing: 0.05em;
  line-height: 18px;
  margin-bottom: 8px;
  text-transform: uppercase;

  @media ${from(Device.TabletLarge)} {
    font-size: 18px;
    line-height: 20px;
  }
`;

const VideoCtaIconWrapper = styled.span`
  display: inline-flex;
  background: ${theme.brand.white.default};
  border: 0;
  border-radius: 50%;
  color: ${theme.brand.blue.dark};
  flex: 0 0 auto;
  flex-basis: 32px;
  height: 32px;
  margin: 0;
  padding: 0;
  place-content: center;
  place-items: center;
  pointer-events: all;
  transition: background-color 0.3s ease-out, color 0.3s ease-out, filter 0.3s ease-out,
    opacity 0.3s ease-out, transform 0.3s ease-out;
  transform: translate3d(0, 0, 0.1);
  user-select: none;
  width: 32px;
  will-change: transform;
`;

const VideoCtaIcon = styled(IconChevronRight)`
  display: block;
  height: 12px;
  width: 6px;
  transform: rotate(90deg);

  path {
    stroke-width: 5px;
  }
`;

const TurtleImage1 = styled(motion.div)`
  position: absolute;
  top: 32px;
  left: 0;
  opacity: 0.1;
  pointer-events: none;
  transform: translate3d(-40%, 0, 0);
  width: 483px;

  @media ${from(Device.DesktopSmall)} {
    transform: translate3d(0, 0, 0);
  }
`;

const TurtleImage2 = styled(motion.div)`
  position: absolute;
  bottom: 32px;
  right: 0;
  display: none;
  opacity: 0.1;
  pointer-events: none;
  transform: translate3d(40%, 0, 0) scaleX(-1);
  width: 332px;

  @media ${from(Device.DesktopSmall)} {
    display: block;
    transform: translate3d(0, 0, 0) scaleX(-1);
  }
`;

const VideoWrapper = styled(motion.div)`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;

  @media ${from(Device.TabletLarge)} {
    position: absolute;
    top: 100%;
    background: ${theme.brand.black.default};
    clip-path: url(#video-frame);
    width: 100%;
  }
`;

const VideoInner = styled(motion.div)`
  @media ${from(Device.TabletLarge)} {
    height: 0;
    padding-bottom: ${(9 / 16) * 100}%;
    width: 100%;
  }
`;

const VideoImageWrapper = styled(motion.div)`
  position: relative;
  clip-path: url(#video-frame);
  z-index: 2;

  @media ${from(Device.TabletLarge)} {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    clip-path: none;
    pointer-events: none;
  }
`;

const VideoImage = styled.div`
  display: none;

  @media ${from(Device.TabletLarge)} {
    display: block;
  }
`;

const VideoImageMobile = styled.div`
  display: block;

  @media ${from(Device.TabletLarge)} {
    display: none;
  }
`;

export interface VideoPlayerProps {
  active: boolean;
}

const VideoPlayer = styled.div<VideoPlayerProps>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;

  @media ${until(Device.TabletLarge)} {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    background: ${theme.brand.black.default};
    border-radius: 5px;
    place-content: center;
    place-items: center;
    transition: opacity 0.3s ease-out, width 0.3s ease-out;
    z-index: 100;

    ${({ active }) =>
      active
        ? css`
            opacity: 1;
            pointer-events: all;
          `
        : css`
            opacity: 0;
            pointer-events: none;
          `}
  }
`;

const VideoPlayerInner = styled.div`
  ${plyrStyles};
  width: 100%;
`;

const VideoClose = styled.button`
  position: absolute;
  top: 24px;
  right: 24px;
  z-index: 4;
  display: flex;
  background: transparent url(${bgTiled});
  background-repeat: repeat;
  border: 0;
  border-radius: 50%;
  color: ${theme.brand.white.default};
  cursor: pointer;
  height: 48px;
  place-content: center;
  place-items: center;
  margin: 0;
  outline: 0;
  padding: 0;
  transition: filter 0.3s ease-out, transform 0.3s ease-out;
  width: 48px;
  will-change: transform;
  z-index: 2;

  &:focus,
  &:hover {
    filter: brightness(1.35);
    transform: translate3d(0, -2px, 0);
  }
`;

const VideoCloseIcon = styled(IconClose)`
  display: block;
  height: 50%;
  width: 50%;
`;

const VideoClipPath = styled.svg`
  position: absolute;
  width: 0;
  height: 0;
`;

export default {
  VideoClipPath,
  Content,
  ContentContainer,
  ContentWrapper,
  Subtitle,
  Title,
  TitleContainer,
  TurtleImage1,
  TurtleImage2,
  VideoClose,
  VideoCloseIcon,
  VideoCta,
  VideoCtaIcon,
  VideoCtaIconWrapper,
  VideoCtaText,
  VideoImage,
  VideoImageMobile,
  VideoImageWrapper,
  VideoInner,
  VideoPlayer,
  VideoPlayerInner,
  VideoWrapper,
  Wrapper,
};
