import SectionWrapper, { ComponentThemeTaxonomy } from '@presentation/SectionWrapper';
import imageUrl from '@utils/cloudinary';
import { Device, until } from '@utils/media';
import { MotionValue, useMotionValue } from 'framer-motion';
import { FixedObject, FluidObject } from 'gatsby-image';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import clamp from 'lodash/clamp';
import React, { useLayoutEffect, useRef } from 'react';
import { useBoolean, useMedia } from 'react-use';
import CentreBannerContent from './CentreBannerContent';
import CentreBannerVideo from './CentreBannerVideo';

export interface CentreBannerProps {
  content?: string;
  subtitle?: string;
  title: string;
  turtleImage1?: FixedObject;
  turtleImage2?: FixedObject;
  video?: string;
  videoPoster?: FluidObject;
  videoPosterMobile?: FluidObject;
  videoProvider?: string;
  theme?: ComponentThemeTaxonomy;
}

const CentreBanner: React.FC<CentreBannerProps> = ({
  content,
  subtitle,
  title,
  turtleImage1,
  turtleImage2,
  video,
  videoPoster,
  videoPosterMobile,
  videoProvider = 'youtube',
  theme = ComponentThemeTaxonomy.LIGHT_BLUE,
}) => {
  const { ref: refScrollTrigger, progress } = useScrollTriggerProgress();
  const [active, toggleActive] = useBoolean(false);
  const isTabletOrSmaller = useMedia(until(Device.TabletLarge));

  return (
    <SectionWrapper fullWidth overflow theme={theme}>
      <div ref={refScrollTrigger}>
        <CentreBannerContent
          content={content}
          progress={progress}
          subtitle={subtitle}
          title={title}
          turtleImage1={turtleImage1}
          turtleImage2={turtleImage2}
          video={video}
          onClickVideoCta={() => {
            if (isTabletOrSmaller) {
              toggleActive(true);
            }
          }}
        />
        <CentreBannerVideo
          active={active}
          progress={progress}
          video={video}
          videoPoster={videoPoster}
          videoPosterMobile={videoPosterMobile}
          videoProvider={videoProvider}
          onClose={() => {
            toggleActive(false);
          }}
        />
      </div>
    </SectionWrapper>
  );
};

export default CentreBanner;

type UseScrollTriggerProgressReturn = {
  progress: MotionValue<number>;
  ref: React.RefObject<HTMLDivElement>;
};

function useScrollTriggerProgress(): UseScrollTriggerProgressReturn {
  const isTabletOrSmaller = useMedia(until(Device.TabletLarge));
  const refTimeline = useRef<gsap.core.Timeline>();
  const refScrollTrigger = useRef<HTMLDivElement>(null);

  const progress = useMotionValue(0);

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    if (refScrollTrigger.current) {
      refTimeline.current = gsap.timeline({
        scrollTrigger: {
          start: 'top top',
          end: '+=100%',
          markers: process.env.NODE_ENV === 'development',
          pin: !isTabletOrSmaller,
          scrub: 0.2,
          trigger: refScrollTrigger.current,
          onUpdate: (instance) => {
            progress.set(clamp(instance.progress, 0, 1));
          },
        },
      });
    }

    return cleanup;

    /** Kill timeline if unmounted/updated. */
    function cleanup(): void {
      refTimeline.current?.scrollTrigger?.kill();
      refTimeline.current?.kill();
      refTimeline.current?.clear();
    }
  }, [isTabletOrSmaller]);

  return { progress, ref: refScrollTrigger };
}

export function videoPoster(publicId?: string): FluidObject {
  return {
    aspectRatio: 16 / 9,
    sizes: '(max-width: 1920px) 100vw, 1920px',
    src: imageUrl(publicId, 'c_fill,g_auto,w_1920,ar_16:9'),
    srcSet: `
      ${imageUrl(publicId, 'c_fill,g_auto,w_480,ar_16:9')} 480w,
      ${imageUrl(publicId, 'c_fill,g_auto,w_960,ar_16:9')} 960w,
      ${imageUrl(publicId, 'c_fill,g_auto,w_1440,ar_16:9')} 1440w,
      ${imageUrl(publicId, 'c_fill,g_auto,w_1920,ar_16:9')} 1920w,
    `,
  };
}

export function videoPosterMobile(publicId?: string): FluidObject {
  return {
    aspectRatio: 2 / 3,
    sizes: '(max-width: 1280px) 100vw, 1280px',
    src: imageUrl(publicId, 'c_fill,g_auto,w_1280,ar_2:3'),
    srcSet: `
      ${imageUrl(publicId, 'c_fill,g_auto,w_320,ar_2:3')} 320w,
      ${imageUrl(publicId, 'c_fill,g_auto,w_640,ar_2:3')} 640w,
      ${imageUrl(publicId, 'c_fill,g_auto,w_960,ar_2:3')} 960w,
      ${imageUrl(publicId, 'c_fill,g_auto,w_1280,ar_2:3')} 1280w
    `,
  };
}
