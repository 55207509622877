import SectionWrapper, { ComponentThemeTaxonomy } from '@presentation/SectionWrapper';
import { MotionValue, useMotionValue, useTransform } from 'framer-motion';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import clamp from 'lodash/clamp';
import React, { Fragment, useLayoutEffect, useRef } from 'react';
import s from './Ticker.styles';

export interface TickerItemModel {
  id: string;
  content: string;
}

export interface TickerItemProps extends TickerItemModel {}

export interface TickerProps {
  direction?: 'forwards' | 'backwards';
  items: TickerItemModel[];
  theme?: ComponentThemeTaxonomy;
}

const TickerItem: React.FC<TickerItemProps> = ({ content, ...otherProps }) => {
  return <s.Text {...otherProps}>{content}</s.Text>;
};

const Ticker: React.FC<TickerProps> = ({
  direction = 'forwards',
  items,
  theme = ComponentThemeTaxonomy.NONE,
}) => {
  const { progress, ref: refWrapper } = useScrollTriggerProgress();

  const xItems = useTransform(
    progress,
    [0, 1],
    direction === 'forwards' ? ['0%', '-50%'] : ['-50%', '0%'],
  );

  return (
    <SectionWrapper ref={refWrapper} padding theme={theme}>
      <s.Wrapper>
        <s.Inner style={{ x: xItems }}>
          {items.map((item) => (
            <Fragment key={item.id}>
              <s.Separator role="presentation" />
              <TickerItem {...item} />
            </Fragment>
          ))}
          {items.map((item) => (
            <Fragment key={item.id}>
              <s.Separator aria-hidden="true" />
              <TickerItem aria-hidden="true" {...item} />
            </Fragment>
          ))}
        </s.Inner>
      </s.Wrapper>
    </SectionWrapper>
  );
};

export default Ticker;

type UseScrollTriggerProgressReturn = {
  progress: MotionValue<number>;
  ref: React.RefObject<HTMLDivElement>;
};

function useScrollTriggerProgress(): UseScrollTriggerProgressReturn {
  const refTimeline = useRef<gsap.core.Timeline>();
  const refScrollTrigger = useRef<HTMLDivElement>(null);

  const progress = useMotionValue(0);

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    if (refScrollTrigger.current) {
      refTimeline.current = gsap.timeline({
        scrollTrigger: {
          end: '+=100%',
          markers: process.env.NODE_ENV === 'development',
          scrub: 1,
          trigger: refScrollTrigger.current,
          onUpdate: (instance) => {
            progress.set(clamp(instance.progress, 0, 1));
          },
        },
      });
    }

    return cleanup;

    /** Kill timeline if unmounted/updated. */
    function cleanup(): void {
      refTimeline.current?.scrollTrigger?.kill();
      refTimeline.current?.kill();
      refTimeline.current?.clear();
    }
  }, []);

  return { progress, ref: refScrollTrigger };
}
