import React from 'react';
import s from './Table.styles';

export interface TableProps {
  tableData: string[][];
}

const Table: React.FC<TableProps> = ({ tableData }) => {
  const headerRows = tableData.slice(0, 1);
  const bodyRows = tableData.slice(1);

  return (
    <s.Table>
      <s.TableHeader>
        {headerRows.map((row, index) => (
          <s.TableHeaderRow key={`${index}_${row.join('_')}`}>
            {row.map((cell, index) => (
              <s.TableHeaderCell key={`${index}_${cell}`}>{cell}</s.TableHeaderCell>
            ))}
          </s.TableHeaderRow>
        ))}
      </s.TableHeader>
      <s.TableBody>
        {bodyRows.map((row, index) => (
          <s.TableBodyRow key={`${index}_${row.join('_')}`}>
            {row.map((cell, index) => (
              <s.TableBodyCell key={`${index}_${cell}`}>{cell}</s.TableBodyCell>
            ))}
          </s.TableBodyRow>
        ))}
      </s.TableBody>
    </s.Table>
  );
};

export default Table;
