import { Link } from '@presentation/Button';
import { LinkUnderline } from '@presentation/LinkUnderline';
import { fadeLeft, fadeRight } from '@utils/animations';
import { Variants } from 'framer-motion';
import GatsbyImage, { FluidObject } from 'gatsby-image';
import Markdown from 'markdown-to-jsx';
import React from 'react';
import s from './HeroBanner.styles';
import HeroBannerItemVideo from './HeroBannerItemVideo';

export interface HeroBannerItemModel {
  id: string;
  title: string;
  subtitle?: string;
  content?: string;
  cta1Text?: string;
  cta1Url?: string;
  cta1NewWindow?: boolean;
  cta2Text?: string;
  cta2Url?: string;
  cta2NewWindow?: boolean;
  image: FluidObject;
  video?: string;
  videoProvider?: string;
}

export interface HeroBannerItemProps extends HeroBannerItemModel {
  isActive: boolean;
  isVideoPlaying: boolean;
  loading?: `auto` | `lazy` | `eager`;
  onVideoPlay?: () => void;
  onVideoPause?: () => void;
}

const HeroBannerItem: React.FC<HeroBannerItemProps> = ({
  content,
  cta1Text,
  cta1Url,
  cta1NewWindow,
  cta2Text,
  cta2Url,
  cta2NewWindow,
  image,
  isActive,
  isVideoPlaying,
  loading = 'lazy',
  subtitle,
  title,
  video,
  videoProvider = 'youtube',
  onVideoPlay,
  onVideoPause,
}) => {
  const containerVariants: Variants = {
    hidden: {
      pointerEvents: 'none',
      transition: {
        when: 'afterChildren',
      },
    },
    visible: {
      transition: {
        when: 'beforeChildren',
      },
      transitionEnd: {
        pointerEvents: 'all',
      },
    },
  };

  const videoVariants: Variants = {
    hidden: {
      opacity: 0,
      pointerEvents: 'none',
      transition: {
        duration: 0.3,
        ease: 'easeOut',
        type: 'tween',
        when: 'afterChildren',
      },
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.3,
        ease: 'easeOut',
        type: 'tween',
        when: 'beforeChildren',
      },
      transitionEnd: {
        pointerEvents: 'all',
      },
    },
  };

  return (
    <s.ItemContainer
      animate={isActive ? 'visible' : 'hidden'}
      initial="hidden"
      variants={containerVariants}
    >
      <s.ItemContentContainer>
        <s.ItemTitleContainer>
          {title && <s.ItemTitle {...fadeRight(0.65)}>{title}</s.ItemTitle>}
          {subtitle && <s.ItemSubtitle {...fadeRight(0.5)}>{subtitle}</s.ItemSubtitle>}
        </s.ItemTitleContainer>
        {content && (
          <s.ItemContent {...fadeRight(0.8)}>
            <Markdown>{content}</Markdown>
          </s.ItemContent>
        )}
        {(cta1Url || cta2Url) && (
          <s.ItemCTAs {...fadeRight(0.95)}>
            {cta1Url && (
              <Link href={cta1Url} target={cta1NewWindow ? '_blank' : '_self'}>
                {cta1Text}
              </Link>
            )}
            {cta2Url && (
              <LinkUnderline href={cta2Url} target={cta2NewWindow ? '_blank' : '_self'}>
                {cta2Text}
              </LinkUnderline>
            )}
          </s.ItemCTAs>
        )}
      </s.ItemContentContainer>
      <s.ItemImage {...fadeLeft(0.5)}>
        {video && (
          <s.PlayButton
            onClick={(event) => {
              event.preventDefault();

              if (!isVideoPlaying) {
                if (onVideoPlay) {
                  onVideoPlay();
                }
              } else {
                if (onVideoPause) {
                  onVideoPause();
                }
              }
            }}
          >
            <s.PlayIcon />
          </s.PlayButton>
        )}
        <GatsbyImage alt="" fluid={image} loading={loading} />
      </s.ItemImage>
      {video && (
        <s.ItemVideo
          animate={isActive && isVideoPlaying ? 'visible' : 'hidden'}
          initial="hidden"
          variants={videoVariants}
        >
          <s.ItemVideoClose
            onClick={(event): void => {
              event.preventDefault();
              if (onVideoPause) {
                onVideoPause();
              }
            }}
          >
            <s.ItemVideoCloseIcon />
          </s.ItemVideoClose>
          <HeroBannerItemVideo
            isPlaying={isActive && isVideoPlaying}
            video={video}
            videoProvider={videoProvider}
          />
        </s.ItemVideo>
      )}
    </s.ItemContainer>
  );
};

export default HeroBannerItem;
