import { graphql } from 'gatsby';
import React from 'react';

import OurRumBannerPres, { image, IngredientModel } from '@presentation/OurRumBanner';
import { ContentModels } from 'schema/ContentModels';
import { Contentful } from 'schema/Contentful';
import { isIngredient } from '@utils/guards';

export interface OurRumBannerProps {
  data: ContentModels.ContentfulOurRumBanner;
}

const OurRumBanner: React.FC<OurRumBannerProps> = ({ data }) => {
  return (
    <OurRumBannerPres
      content={data.content?.content}
      ctaNewWindow={data.ctaNewWindow}
      ctaText={data.ctaText}
      ctaUrl={data.ctaUrl}
      image={image(data.image?.[0]?.public_id)}
      ingredients={createItemArray(data.ingredients)}
      subtitle={data.subtitle}
      title={data.title ?? ''}
      video={data.video}
    />
  );
};

export default OurRumBanner;

function createItemArray(entries?: Contentful.ContentfulEntry[]): IngredientModel[] {
  const menuItems = entries?.filter(isIngredient) ?? [];
  return menuItems.map(createItemModel);
}

function createItemModel(entry: ContentModels.ContentfulIngredient): IngredientModel {
  return {
    id: entry.id ?? '',
    icon: entry.icon?.[0]?.secure_url ?? '',
    title: entry.title ?? '',
  };
}

export const ContentfulOurRumBannerFragment = graphql`
  fragment ContentfulOurRumBannerFragment on ContentfulOurRumBanner {
    content {
      content
    }
    ctaNewWindow
    ctaText
    ctaUrl
    image
    ingredients {
      id
      sys {
        contentType {
          sys {
            id
          }
        }
      }
      ... on ContentfulIngredient {
        icon
        title
      }
    }
    subtitle
    title
    video
  }
`;
