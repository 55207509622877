import svgMap from '!babel-loader!react-svg-loader!@img/map.svg';
import svgStamp from '!babel-loader!react-svg-loader!@img/sot-stamp.svg';
import { markdownStyles } from '@utils/markdown';
import { Device, from } from '@utils/media';
import theme from '@utils/theme';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const Wrapper = styled(motion.div)`
  position: relative;
  padding: 72px 0 0;
  text-align: center;
  z-index: 2;

  @media ${from(Device.Tablet)} {
    padding: 0;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;

const Title = styled(motion.h2)`
  color: ${theme.brand.red.default};
  font-size: 35px;
  line-height: 40px;
  margin: 0 auto 16px;
  max-width: 920px;
  text-transform: uppercase;

  @media ${from(Device.Tablet)} {
    font-size: 60px;
    line-height: 68px;
  }
`;

const Subtitle = styled(motion.h3)`
  font-size: 14px;
  letter-spacing: 0.02em;
  line-height: 16px;
  margin: 0 auto 16px;
  max-width: 920px;
  text-transform: uppercase;

  @media ${from(Device.Tablet)} {
    font-size: 18px;
    letter-spacing: 0.05em;
    line-height: 20px;
  }
`;

const Content = styled(motion.div)`
  ${markdownStyles};
  font-size: 16px;
  letter-spacing: 0.02em;
  line-height: 26px;
  margin: 0 auto;
  max-width: 670px;
  text-transform: uppercase;

  @media ${from(Device.Tablet)} {
    font-size: 20px;
    letter-spacing: 0.02em;
    line-height: 28px;
    margin: 0 auto 160px;
  }
`;

const SvgMap = styled(svgMap)`
  position: absolute;
  top: 50%;
  left: 50%;
  height: 53vw;
  opacity: 0.1;
  transform: translate3d(-50%, -50%, 0) translate3d(0, -50px, 0);
  width: 88vw;
  z-index: 1;

  @media ${from(Device.Tablet)} {
    transform: translate3d(-50%, -50%, 0) translate3d(0, 50px, 0);
  }
`;

const StampWrapper = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
`;

const SvgStamp = styled(svgStamp)`
  color: ${theme.brand.red.default};
  height: 58px;
  width: 115px;

  @media ${from(Device.Tablet)} {
    height: 116px;
    width: 230px;
  }
`;

export default {
  Content,
  StampWrapper,
  Subtitle,
  SvgMap,
  SvgStamp,
  Title,
  TitleContainer,
  Wrapper,
};
