import theme from '@utils/theme';
import styled, { css } from 'styled-components';

const cellStyles = css`
  border: 1px solid ${theme.brand.blue.dark};
  padding: 4px 8px;
`;

const Table = styled.table`
  margin-bottom: 1em;
  width: 100%;
`;

const TableHeader = styled.thead``;

const TableHeaderRow = styled.tr``;

const TableHeaderCell = styled.th`
  ${theme.fonts.brandon.bold};
  text-align: left;
  ${cellStyles};
`;

const TableBody = styled.tbody``;

const TableBodyRow = styled.tr``;

const TableBodyCell = styled.td`
  ${cellStyles};
`;

export default {
  Table,
  TableHeader,
  TableHeaderRow,
  TableHeaderCell,
  TableBody,
  TableBodyRow,
  TableBodyCell,
};
