import HeroBannerPres, { itemImage } from '@presentation/HeroBanner';
import { HeroBannerItemModel } from '@presentation/HeroBanner/HeroBannerItem';
import { isHeroBannerItem } from '@utils/guards';
import { FixedObject } from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Contentful } from 'schema/Contentful';
import { ContentModels } from 'schema/ContentModels';

interface HeroBannerProps {
  data: ContentModels.ContentfulHeroBanner;
}

const HeroBanner: React.FC<HeroBannerProps> = ({ data }) => {
  const turtleImage = useTurtleImage();
  return <HeroBannerPres items={createItemArray(data.items)} turtleImage={turtleImage} />;
};

export default HeroBanner;

interface FileQuery {
  file?: {
    childCloudinaryAsset?: {
      fixed?: FixedObject;
    };
  };
}

function useTurtleImage(): FixedObject | undefined {
  const data = useStaticQuery<FileQuery>(graphql`
    {
      file(name: { eq: "turtle-red" }) {
        name
        childCloudinaryAsset {
          fixed(width: 483, height: 343, transformations: "f_auto,q_auto") {
            aspectRatio
            height
            src
            srcSet
            width
          }
        }
      }
    }
  `);

  return data.file?.childCloudinaryAsset?.fixed;
}

function createItemArray(entries?: Contentful.ContentfulEntry[]): HeroBannerItemModel[] {
  const items = entries?.filter(isHeroBannerItem) ?? [];
  return items.map(createItemModel);
}

function createItemModel(entry: ContentModels.ContentfulHeroBannerItem): HeroBannerItemModel {
  return {
    id: entry.id ?? '',
    content: entry.content?.content,
    cta1NewWindow: entry.cta1NewWindow,
    cta1Text: entry.cta1Text,
    cta1Url: entry.cta1Url,
    cta2NewWindow: entry.cta2NewWindow,
    cta2Text: entry.cta2Text,
    cta2Url: entry.cta2Url,
    image: itemImage(entry.image?.[0]?.public_id),
    subtitle: entry.subtitle,
    title: entry.title ?? '',
    video: entry.video,
    videoProvider: 'youtube',
  };
}

export const ContentfulHeroBannerFragment = graphql`
  fragment ContentfulHeroBannerFragment on ContentfulHeroBanner {
    items {
      ... on ContentfulHeroBannerItem {
        id
        content {
          content
        }
        cta1NewWindow
        cta1Text
        cta1Url
        cta2NewWindow
        cta2Text
        cta2Url
        image
        subtitle
        title
        video
        sys {
          contentType {
            sys {
              id
            }
          }
        }
      }
    }
  }
`;
