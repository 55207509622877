import SvgWaveMulti from '!babel-loader!react-svg-loader!@img/wave-multi.svg';
import { Button } from '@presentation/Button';
import imageFrameStyles from '@presentation/ImageFrame/ImageFrame.styles';
import { ButtonUnderline } from '@presentation/LinkUnderline';
import buttonUnderlineStyles from '@presentation/LinkUnderline/LinkUnderline.styles';
import { Device, from } from '@utils/media';
import theme from '@utils/theme';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

const Container = styled(motion.div)``;

const Navigation = styled(motion.nav)`
  display: flex;
  flex-direction: row;
  place-content: center;
  place-items: center;
  margin-bottom: 96px;
`;

export interface NavigationItemProps {
  active: boolean;
}

const NavigationItem = styled(ButtonUnderline)<NavigationItemProps>`
  color: ${theme.brand.paper.dark};
  ${theme.fonts.venusian};
  font-size: 22px;
  letter-spacing: normal;
  line-height: 1.2em;

  @media ${from(Device.MobileLarge)} {
    font-size: 35px;
    letter-spacing: normal;
    line-height: 40px;
  }

  ${buttonUnderlineStyles.UnderlineWrapper} {
    opacity: 0;
  }

  & + & {
    margin-left: 32px;
  }

  ${({ active }) =>
    !active &&
    css`
      &:focus,
      &:hover {
        color: ${theme.brand.blue.dark};

        ${buttonUnderlineStyles.UnderlineWrapper} {
          opacity: 1;
        }
      }
    `}

  ${({ active }) =>
    active &&
    css`
      color: ${theme.brand.blue.dark};
      pointer-events: none;

      ${buttonUnderlineStyles.UnderlineWrapper} {
        opacity: 1;
      }
    `}
`;

const ItemGridContainer = styled.div`
  display: grid;
`;

const ItemGrid = styled(motion.div)`
  display: flex;
  flex-direction: column;
  grid-column: 1;
  grid-row: 1;
  justify-items: center;

  @media ${from(Device.MobileLarge)} {
    display: grid;
    gap: 32px;
    grid-template-columns: repeat(auto-fill, minmax(385px, 1fr));
  }
`;

const Item = styled(motion.div)`
  display: block;
  aspect-ratio: 1 / 1;
  max-width: 385px;
  transform: translate3d(0, 0, 0.1);
  transition: transform 0.15s ease-out;
  will-change: transform;
  width: 100%;

  & + & {
    margin-top: 32px;
  }

  @media ${from(Device.MobileLarge)} {
    & + & {
      margin-top: 0;
    }
  }

  .gatsby-image-wrapper {
    max-width: 300px;
  }
`;

const ItemLink = styled(motion.a)`
  display: block;
`;

const ItemImage = styled.div`
  display: flex;
  background: ${theme.brand.white.default};
  height: 100%;
  padding: 16px;
  place-content: center;
  place-items: center;
  width: 100%;

  .gatsby-image-wrapper {
    max-width: 300px;
    width: 100%;
  }
`;

const Cta = styled(motion.div)`
  display: block;
  aspect-ratio: 1 / 1;
  max-width: 385px;
  transform: translate3d(0, 0, 0.1);
  transition: transform 0.15s ease-out;
  will-change: transform;
  width: 100%;

  & + & {
    margin-top: 32px;
  }

  @media ${from(Device.MobileLarge)} {
    & + & {
      margin-top: 0;
    }
  }

  ${imageFrameStyles.FrameInnerBorder} {
    /* Height with the 7% margin accounted for. */
    height: 86%;
  }

  ${imageFrameStyles.FrameContentWrapper} {
    height: 100%;
  }
`;

const CtaLink = styled(motion.a)`
  display: block;
  height: 100%;
  text-decoration: none;
  width: 100%;
`;

const CtaInner = styled.div`
  display: flex;
  background: ${theme.brand.paper.default};
  flex-direction: column;
  height: 100%;
  padding: 32px;
  place-content: space-around;
  place-items: center;
  width: 100%;
`;

const CtaTitle = styled.h3`
  color: ${theme.brand.red.default};
  ${theme.fonts.venusian};
  font-size: 35px;
  line-height: 45px;
  text-align: center;
  text-transform: uppercase;
`;

const CtaButton = styled(Button)``;

const Wave = styled(SvgWaveMulti)`
  position: absolute;
  display: none;
  color: ${theme.brand.blue.dark};
  height: 118px;
  width: 232px;

  @media ${from(Device.TabletLarge)} {
    display: block;
  }
`;

const Wave1 = styled(Wave)`
  bottom: 128px;
  left: -48px;
`;

const Wave2 = styled(Wave)`
  top: 128px;
  right: -48px;
`;

export default {
  Container,
  Cta,
  CtaButton,
  CtaInner,
  CtaLink,
  CtaTitle,
  Item,
  ItemGrid,
  ItemGridContainer,
  ItemImage,
  ItemLink,
  Navigation,
  NavigationItem,
  Wave1,
  Wave2,
};
