import GatsbyImage, { FixedObject, FluidObject } from 'gatsby-image';
import React from 'react';
import { Link } from '@presentation/Button';
import ImageFrame from '@presentation/ImageFrame';
import SectionWrapper, { ComponentThemeTaxonomy } from '@presentation/SectionWrapper';
import s from './VerticalFactsSection.styles';
import imageUrl from '@utils/cloudinary';
import Markdown from 'markdown-to-jsx';
import { Variants } from 'framer-motion';
import useWasViewed from '@hooks/useWasViewed';

export interface FactModel {
  id: string;
  content: string;
}

export interface FactItemProps extends FactModel {}

const FactItem: React.FC<FactItemProps> = ({ content }) => {
  const itemVariants: Variants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.15,
      },
    },
  };

  const iconVariants: Variants = {
    hidden: {
      opacity: 0,
      scale: 0.5,
    },
    visible: {
      opacity: 1,
      scale: 1,
    },
  };

  const contentVariants: Variants = {
    hidden: {
      opacity: 0,
      x: -50,
    },
    visible: {
      opacity: 1,
      x: 0,
    },
  };

  return (
    <s.FactItem initial="hidden" variants={itemVariants}>
      <s.FactItemIconWrapper initial="hidden" variants={iconVariants}>
        <s.FactItemIcon />
      </s.FactItemIconWrapper>
      <s.FactItemContent initial="hidden" variants={contentVariants}>
        {content}
      </s.FactItemContent>
    </s.FactItem>
  );
};

export interface VerticalFactsSectionProps {
  badge?: FixedObject;
  content?: string;
  ctaNewWindow?: boolean;
  ctaText?: string;
  ctaUrl?: string;
  facts: FactModel[];
  image: FluidObject;
  reverse?: boolean;
  subtitle?: string;
  title: string;
  theme?: ComponentThemeTaxonomy;
}

const VerticalFactsSection: React.FC<VerticalFactsSectionProps> = ({
  badge,
  content,
  ctaNewWindow,
  ctaText,
  ctaUrl,
  facts,
  image,
  reverse = false,
  subtitle,
  title,
  theme = ComponentThemeTaxonomy.LIGHT_BLUE,
}) => {
  const { ref: refInView, wasViewed } = useWasViewed({
    rootMargin: '0px 0px -50% 0px',
  });

  const titleVariants: Variants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  };

  const subtitleVariants: Variants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.15,
      },
    },
  };

  const contentVariants: Variants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.3,
      },
    },
  };

  const itemListVariants: Variants = {
    hidden: {},
    visible: {
      transition: {
        delay: 0.45,
        delayChildren: 0.3,
        staggerChildren: 0.15,
      },
    },
  };

  const imageVariants: Variants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  };

  const ctaVariants: Variants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.6,
      },
    },
  };

  return (
    <SectionWrapper padding theme={theme}>
      <s.Wrapper ref={refInView} animate={wasViewed ? 'visible' : 'hidden'} reverse={reverse}>
        <s.ImageColumn initial="hidden" variants={imageVariants}>
          {badge && (
            <s.Badge>
              <GatsbyImage fixed={badge} />
            </s.Badge>
          )}
          <ImageFrame>
            <GatsbyImage fluid={image} />
          </ImageFrame>
        </s.ImageColumn>
        <s.ContentColumn>
          <s.TitleContainer>
            <s.Title initial="hidden" variants={titleVariants}>
              {title}
            </s.Title>
            <s.Subtitle initial="hidden" variants={subtitleVariants}>
              {subtitle}
            </s.Subtitle>
          </s.TitleContainer>
          {content && (
            <s.Content initial="hidden" variants={contentVariants}>
              <Markdown>{content}</Markdown>
            </s.Content>
          )}
          <s.FactsList initial="hidden" variants={itemListVariants}>
            {facts.map((item) => (
              <FactItem key={item.id} {...item} />
            ))}
          </s.FactsList>
          {ctaUrl && (
            <s.CallToAction initial="hidden" variants={ctaVariants}>
              <Link href={ctaUrl} target={ctaNewWindow ? '_blank' : '_self'}>
                {ctaText}
              </Link>
            </s.CallToAction>
          )}
        </s.ContentColumn>
      </s.Wrapper>
    </SectionWrapper>
  );
};

export default VerticalFactsSection;

export function image(publicId?: string): FluidObject {
  return {
    aspectRatio: 450 / 540,
    sizes: '(max-width: 450px) 100vw, 450px',
    src: imageUrl(publicId, 'c_fill,g_auto,w_450,ar_450:540'),
    srcSet: `
      ${imageUrl(publicId, 'c_fill,g_auto,w_450,ar_450:540')} 450w,
      ${imageUrl(publicId, 'c_fill,g_auto,w_900,ar_450:540')} 900w
    `,
  };
}

export function badge(publicId?: string): FixedObject {
  return {
    height: 200,
    src: imageUrl(publicId, 'c_fill,g_auto,w_200,ar_1:1'),
    srcSet: `
      ${imageUrl(publicId, 'c_fill,g_auto,w_200,ar_1:1')} 1x,
      ${imageUrl(publicId, 'c_fill,g_auto,w_400,ar_1:1')} 2x
    `,
    width: 200,
  };
}
