import ContentSectionPres from '@presentation/ContentSection';
import { graphql } from 'gatsby';
import React from 'react';
import { ContentModels } from 'schema/ContentModels';

export interface ContentSectionProps {
  data: ContentModels.ContentfulContentSection;
}

const ContentSection: React.FC<ContentSectionProps> = ({ data }) => {
  return <ContentSectionPres content={data.contentSectionContent} />;
};

export default ContentSection;

export const ContentfulContentSectionFragment = graphql`
  fragment ContentfulContentSectionFragment on ContentfulContentSection {
    contentSectionContent: content {
      ...RichTextFragment
    }
  }
`;
