import useWasViewed from '@hooks/useWasViewed';
import { Link } from '@presentation/Button';
import SectionWrapper, { ComponentThemeTaxonomy } from '@presentation/SectionWrapper';
import TitleContainer from '@presentation/TitleContainer';
import { MotionValue, useMotionValue, Variants } from 'framer-motion';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import clamp from 'lodash/clamp';
import React, { useCallback, useLayoutEffect, useRef } from 'react';
import AnimatedTurtle from './AnimatedTurtle';
import s from './CtaSection.styles';

export interface CtaSectionProps {
  title?: string;
  subtitle?: string;
  ctaUrl?: string;
  ctaNewWindow?: boolean;
  ctaText?: string;
  theme?: ComponentThemeTaxonomy;
}

const CtaSection: React.FC<CtaSectionProps> = ({
  ctaNewWindow = false,
  ctaText,
  ctaUrl,
  subtitle,
  title,
  theme = ComponentThemeTaxonomy.NONE,
}) => {
  const { progress, ref: refScrollTrigger } = useScrollTriggerProgress();

  const { ref: refInView, wasViewed } = useWasViewed({
    rootMargin: '0px 0px -50% 0px',
  });

  // We need two refs for the section wrapper, so this method handles setting both.
  // See https://www.npmjs.com/package/react-intersection-observer#how-can-i-assign-multiple-refs-to-a-component
  const setRefs = useCallback(
    (node) => {
      refInView(node);
      refScrollTrigger.current = node;
    },
    [refInView, refScrollTrigger],
  );

  const ctaVariants: Variants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.3,
      },
    },
  };

  return (
    <SectionWrapper ref={setRefs} padding theme={theme} overflow>
      <s.Container animate={wasViewed ? 'visible' : 'hidden'}>
        <TitleContainer title={title} subtitle={subtitle} />
        {ctaUrl && (
          <s.CallToAction initial="hidden" variants={ctaVariants}>
            <Link href={ctaUrl} target={ctaNewWindow ? '_blank' : '_self'}>
              {ctaText}
            </Link>
          </s.CallToAction>
        )}
        <AnimatedTurtle
          offset={0.2}
          progress={progress}
          motionStyle={{
            scale: 0.8,
          }}
          style={{
            top: '60%',
            left: '5%',
          }}
        />
        <AnimatedTurtle
          offset={0.1}
          progress={progress}
          motionStyle={{
            scale: 1,
          }}
          style={{
            top: '10%',
            left: '15%',
          }}
        />
        <AnimatedTurtle
          offset={0}
          progress={progress}
          motionStyle={{
            scale: 0.6,
          }}
          style={{
            top: '70%',
            left: '25%',
          }}
        />
        <AnimatedTurtle
          offset={0.3}
          progress={progress}
          motionStyle={{
            scale: 0.8,
          }}
          style={{
            top: '50%',
            right: '5%',
          }}
        />
        <AnimatedTurtle
          offset={0}
          progress={progress}
          motionStyle={{
            scale: 1,
          }}
          style={{
            top: '0%',
            right: '15%',
          }}
        />
        <AnimatedTurtle
          offset={0.15}
          progress={progress}
          motionStyle={{
            scale: 0.6,
          }}
          style={{
            top: '60%',
            right: '25%',
          }}
        />
      </s.Container>
    </SectionWrapper>
  );
};

export default CtaSection;

type UseScrollTriggerProgressReturn = {
  progress: MotionValue<number>;
  ref: React.MutableRefObject<HTMLDivElement | undefined>;
};

function useScrollTriggerProgress(): UseScrollTriggerProgressReturn {
  const refTimeline = useRef<gsap.core.Timeline>();
  const refScrollTrigger = useRef<HTMLDivElement>();

  const progress = useMotionValue(0);

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    if (refScrollTrigger.current) {
      refTimeline.current = gsap.timeline({
        scrollTrigger: {
          start: 'top 70%',
          markers: process.env.NODE_ENV === 'development',
          pin: false,
          scrub: 1,
          trigger: refScrollTrigger.current,
          onUpdate: (instance) => {
            progress.set(clamp(instance.progress, 0, 1));
          },
        },
      });
    }

    return cleanup;

    /** Kill timeline if unmounted/updated. */
    function cleanup(): void {
      refTimeline.current?.scrollTrigger?.kill();
      refTimeline.current?.kill();
      refTimeline.current?.clear();
    }
  }, []);

  return { progress, ref: refScrollTrigger };
}
