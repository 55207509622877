import useStyleSheet from '@hooks/useStyleSheet';
import useWasViewed from '@hooks/useWasViewed';
import { Link } from '@presentation/Button';
import { ButtonUnderline } from '@presentation/LinkUnderline';
import SectionWrapper, { ComponentThemeTaxonomy, SectionFlush } from '@presentation/SectionWrapper';
import imageUrl from '@utils/cloudinary';
import loadPlyr from '@utils/loadPlyr';
import { Variants } from 'framer-motion';
import GatsbyImage, { FluidObject } from 'gatsby-image';
import Markdown from 'markdown-to-jsx';
import React, { useEffect, useRef, useState } from 'react';
import s from './OurRumBanner.styles';

export interface IngredientModel {
  id: string;
  icon: string;
  title: string;
}

export interface IngredientItemProps extends IngredientModel {}

export interface OurRumBannerProps {
  content?: string;
  ctaNewWindow?: boolean;
  ctaText?: string;
  ctaUrl?: string;
  image?: FluidObject;
  ingredients?: IngredientModel[];
  subtitle?: string;
  title: string;
  video?: string;
  videoProvider?: string;
  theme?: ComponentThemeTaxonomy;
}

type OurRumBannerVideoProps = Pick<OurRumBannerProps, 'video' | 'videoProvider'> & {
  isPlaying: boolean;
  onClose: () => void;
};

const IngredientItem: React.FC<IngredientItemProps> = ({ icon, title }) => {
  const itemVariants: Variants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.15,
      },
    },
  };

  const iconVariants: Variants = {
    hidden: {
      opacity: 0,
      scale: 0.5,
    },
    visible: {
      opacity: 1,
      scale: 1,
    },
  };

  const titleVariants: Variants = {
    hidden: {
      opacity: 0,
      y: -50,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  };

  return (
    <s.IngredientItem initial="hidden" variants={itemVariants}>
      <s.IngredientIconWrapper initial="hidden" variants={iconVariants}>
        <s.IngredientIcon src={icon} />
      </s.IngredientIconWrapper>
      <s.IngredientTitle initial="hidden" variants={titleVariants}>
        {title}
      </s.IngredientTitle>
    </s.IngredientItem>
  );
};

const OurRumBannerVideo: React.FC<OurRumBannerVideoProps> = ({
  isPlaying,
  video,
  videoProvider,
  onClose,
}) => {
  useStyleSheet('https://cdn.plyr.io/3.5.2/plyr.css');

  const playerRef = useRef<Plyr>();
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    doAsync();

    async function doAsync(): Promise<void> {
      // Load Plyr then start playing.
      if (isPlaying && !playerRef.current && elementRef.current) {
        playerRef.current = await loadPlyr(elementRef.current);
      }

      playerRef.current?.togglePlay(isPlaying);
    }
  }, [isPlaying]);

  const videoVariants: Variants = {
    hidden: {
      opacity: 0,
      transitionEnd: {
        display: 'none',
      },
    },
    visible: {
      display: 'flex',
      opacity: 1,
    },
  };

  return (
    <s.VideoContainer
      initial="hidden"
      animate={isPlaying ? 'visible' : 'hidden'}
      variants={videoVariants}
    >
      <s.VideoClose
        onClick={(event): void => {
          event.preventDefault();
          onClose();
        }}
      >
        <s.VideoCloseIcon />
      </s.VideoClose>
      <s.Video>
        <div
          ref={elementRef}
          data-plyr-provider={videoProvider}
          data-plyr-embed-id={video}
          data-plyr-config='{ "youtube": { "modestbranding": 1, "playsinline": 1 } }'
        />
      </s.Video>
    </s.VideoContainer>
  );
};

const OurRumBanner: React.FC<OurRumBannerProps> = ({
  content,
  ctaNewWindow,
  ctaText,
  ctaUrl,
  image,
  ingredients,
  subtitle,
  title,
  video,
  videoProvider = 'youtube',
  theme = ComponentThemeTaxonomy.NONE,
}) => {
  const { ref: refInView, wasViewed } = useWasViewed({
    rootMargin: '0px 0px -50% 0px',
  });

  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const titleVariants: Variants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.15,
      },
    },
  };

  const subtitleVariants: Variants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  };

  const contentVariants: Variants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.3,
      },
    },
  };

  const ctaVariants: Variants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.45,
      },
    },
  };

  const imageVariants: Variants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  };

  const listVariants: Variants = {
    hidden: {},
    visible: {
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.15,
      },
    },
  };

  return (
    <SectionWrapper ref={refInView} flush={SectionFlush.TOP} padding theme={theme}>
      <s.Sugarcane />
      <s.Inner animate={wasViewed ? 'visible' : 'hidden'}>
        <s.ContentWrapper>
          <s.TitleContainer>
            <s.Title initial="hidden" variants={titleVariants}>
              {title}
            </s.Title>
            {subtitle && (
              <s.Subtitle initial="hidden" variants={subtitleVariants}>
                {subtitle}
              </s.Subtitle>
            )}
          </s.TitleContainer>
          {content && (
            <s.Content initial="hidden" variants={contentVariants}>
              <Markdown>{content}</Markdown>
            </s.Content>
          )}
          {!!ingredients?.length && (
            <s.IngredientList initial="hidden" variants={listVariants}>
              {ingredients.map((item) => (
                <IngredientItem key={item.id} {...item} />
              ))}
            </s.IngredientList>
          )}
          {(ctaUrl || video) && (
            <s.CallToActions>
              {ctaUrl && (
                <s.CallToAction1 initial="hidden" variants={ctaVariants}>
                  <Link href={ctaUrl} target={ctaNewWindow ? '_blank' : '_self'}>
                    {ctaText}
                  </Link>
                </s.CallToAction1>
              )}
              {video && (
                <s.CallToAction2 initial="hidden" variants={ctaVariants}>
                  <ButtonUnderline
                    onClick={() => {
                      setIsVideoPlaying(true);
                    }}
                  >
                    Watch The Video
                  </ButtonUnderline>
                </s.CallToAction2>
              )}
            </s.CallToActions>
          )}
        </s.ContentWrapper>
        {image && (
          <s.Image initial="hidden" variants={imageVariants}>
            <GatsbyImage fluid={image} />
          </s.Image>
        )}
      </s.Inner>
      {video && (
        <OurRumBannerVideo
          isPlaying={isVideoPlaying}
          video={video}
          videoProvider={videoProvider}
          onClose={() => {
            setIsVideoPlaying(false);
          }}
        />
      )}
    </SectionWrapper>
  );
};

export default OurRumBanner;

export function image(publicId?: string): FluidObject {
  return {
    aspectRatio: 0.5,
    sizes: '(max-width: 768px) 100vw, (max-width: 1280px) 30vw, 400px',
    src: imageUrl(publicId, 'c_pad,w_800,ar_1:2'),
    srcSet: `
      ${imageUrl(publicId, 'c_pad,w_400,ar_1:2')} 400w,
      ${imageUrl(publicId, 'c_pad,w_800,ar_1:2')} 800w,
      ${imageUrl(publicId, 'c_pad,w_1600,ar_1:2')} 1600w
    `,
  };
}
