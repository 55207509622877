import loadPlyr from '@utils/loadPlyr';
import React, { memo, useEffect, useRef } from 'react';
import s from './HeroBanner.styles';

interface HeroBannerItemVideoProps {
  isPlaying: boolean;
  video: string;
  videoProvider: string;
}

const HeroBannerItemVideo: React.FC<HeroBannerItemVideoProps> = ({
  isPlaying,
  video,
  videoProvider,
}) => {
  const playerRef = useRef<Plyr>();
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    doAsync();

    async function doAsync(): Promise<void> {
      // Load Plyr then start playing.
      if (isPlaying && !playerRef.current && elementRef.current) {
        playerRef.current = await loadPlyr(elementRef.current);
      }

      playerRef.current?.togglePlay(isPlaying);
    }
  }, [isPlaying]);

  return (
    <s.ItemVideoWrapper>
      <div
        ref={elementRef}
        data-plyr-provider={videoProvider}
        data-plyr-embed-id={video}
        data-plyr-config='{ "youtube": { "modestbranding": 1, "playsinline": 1 } }'
      />
    </s.ItemVideoWrapper>
  );
};

export default memo(HeroBannerItemVideo);
