import contentFrameStyles from '@presentation/ContentFrame/ContentFrame.styles';
import { SiteWide } from '@presentation/SectionWrapper/SectionWrapper.styles';
import { Device, from } from '@utils/media';
import theme from '@utils/theme';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  display: grid;
`;

const Image = styled.div`
  grid-column: 1;
  grid-row: 1;
  min-width: 0;

  .gatsby-image-wrapper {
    height: 100%;
    width: 100%;
  }
`;

const StyledSiteWide = styled(SiteWide)`
  grid-column: 1;
  grid-row: 1;
  min-width: 0;
`;

export interface ContentWrapperProps {
  align: 'left' | 'center' | 'right';
}

const ContentWrapper = styled.div<ContentWrapperProps>`
  display: flex;
  height: 100%;
  padding: 200px 0 100px;
  place-items: center;
  width: 100%;

  @media ${from(Device.Tablet)} {
    padding: 0 7%;
  }

  ${({ align }) => {
    switch (align) {
      case 'left':
        return css`
          place-content: flex-start;
        `;

      case 'right':
        return css`
          place-content: flex-end;
        `;

      default:
      case 'center':
        return css`
          place-content: center;
        `;
    }
  }}
`;

const ContentFrame = styled.div`
  position: relative;
  max-width: 680px;
  width: 100%;
  z-index: 1;

  ${contentFrameStyles.FrameContentWrapper} {
    padding: 24px;
    text-align: center;
  }

  @media ${from(Device.Tablet)} {
    margin-top: -10%;

    ${contentFrameStyles.FrameContentWrapper} {
      padding: 64px;
      text-align: left;
    }
  }
`;

const Title = styled.h2`
  color: ${theme.brand.red.default};
  font-size: 25px;
  line-height: 35px;
  margin-bottom: 16px;
  text-transform: uppercase;

  @media ${from(Device.Tablet)} {
    font-size: 50px;
    line-height: 60px;
  }
`;

const CallToActions = styled.div`
  display: flex;
  flex-direction: column;

  @media ${from(Device.Tablet)} {
    flex-direction: row;
    place-items: center;
  }
`;

const CallToAction1 = styled.div``;

const CallToAction2 = styled.div`
  margin-top: 16px;

  @media ${from(Device.Tablet)} {
    margin-top: 0;
    margin-left: 32px;
  }
`;

export default {
  CallToActions,
  CallToAction1,
  CallToAction2,
  ContentFrame,
  ContentWrapper,
  Image,
  SiteWide: StyledSiteWide,
  Title,
  Wrapper,
};
