import useWasViewed from '@hooks/useWasViewed';
import ImageFrame from '@presentation/ImageFrame';
import SectionWrapper, { ComponentThemeTaxonomy } from '@presentation/SectionWrapper';
import imageUrl from '@utils/cloudinary';
import { useLocation } from '@reach/router';
import { Variants } from 'framer-motion';
import GatsbyImage, { FluidObject } from 'gatsby-image';
import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import s from './CocktailsList.styles';

export interface CocktailModel {
  id: string;
  content: string;
  image: FluidObject;
  imageId: string;
  ingredients?: string[];
  published: Date;
  title: string;
  url: string;
}

export interface CocktailsListItemProps extends CocktailModel {}

const CocktailsListItem: React.FC<CocktailsListItemProps> = ({
  content,
  id,
  image,
  ingredients,
  title,
}) => {
  const { hash } = useLocation();
  const refAnchor = useRef<HTMLAnchorElement>(null);

  const { ref: refInView, wasViewed } = useWasViewed({
    rootMargin: '0px 0px -30% 0px',
  });

  useEffect(() => {
    if (!refAnchor.current) {
      return;
    }

    if (hash.substring(1) === id) {
      requestAnimationFrame(() => {
        if (!refAnchor.current) {
          return;
        }

        const elementTop = refAnchor.current.getBoundingClientRect().top;
        const bodyTop = document.body.getBoundingClientRect().top;

        window.scrollTo({
          behavior: 'smooth',
          // Arbitrary 100px offset to fix header overlap.
          top: elementTop - bodyTop - 100,
        });
      });
    }
  }, [hash]);

  const itemVariants: Variants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  };

  return (
    <s.ItemContainer
      ref={refInView}
      animate={wasViewed ? 'visible' : 'hidden'}
      initial="hidden"
      variants={itemVariants}
    >
      <s.Anchor ref={refAnchor} id={id} />
      <s.ImageContainer>
        <ImageFrame>
          <GatsbyImage fluid={image} />
        </ImageFrame>
      </s.ImageContainer>
      <s.ContentContainer>
        <s.Title>{title}</s.Title>
        <s.Content>{content}</s.Content>
        {!!ingredients?.length && (
          <>
            <s.Subtitle>Ingredients</s.Subtitle>
            <s.Ingredients>
              {ingredients.map((item) => (
                <s.Ingredient key={item}>{item}</s.Ingredient>
              ))}
            </s.Ingredients>
          </>
        )}
      </s.ContentContainer>
    </s.ItemContainer>
  );
};

export interface CocktailsListProps {
  items: CocktailModel[];
  theme?: ComponentThemeTaxonomy;
}

const CocktailsList: React.FC<CocktailsListProps> = ({
  items,
  theme = ComponentThemeTaxonomy.NONE,
}) => {
  const itemListSchema = createItemListSchema(items);

  return (
    <SectionWrapper padding theme={theme}>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(itemListSchema)}</script>
      </Helmet>
      <s.Container>
        {items.map((item) => (
          <CocktailsListItem key={item.id} {...item} />
        ))}
      </s.Container>
    </SectionWrapper>
  );
};

export default CocktailsList;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function createItemListSchema(items: CocktailModel[]): any {
  const cocktailSchemaItems = items.map((item) => ({
    '@context': 'https://schema.org/',
    '@type': 'Recipe',
    name: item.title,
    image: [
      imageUrl(item.imageId, 'c_fill,g_auto,w_1000,ar_1:1,f_jpg'),
      imageUrl(item.imageId, 'c_fill,g_auto,w_1000,ar_4:3,f_jpg'),
      imageUrl(item.imageId, 'c_fill,g_auto,w_1000,ar_16:9,f_jpg'),
    ],
    datePublished: item.published,
    description: item.content,
    recipeCategory: 'Drink',
    recipeIngredient: item.ingredients,
    url: item.url,
  }));

  const itemListSchema = {
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    itemListElement: cocktailSchemaItems.map((item, index) => ({
      '@type': 'ListItem',
      position: index,
      item: item,
    })),
  };

  return itemListSchema;
}

export function itemImage(publicId?: string): FluidObject {
  return {
    aspectRatio: 322 / 384,
    sizes: '(max-width: 1024px) 100vw, 322px',
    src: imageUrl(publicId, 'c_fill,g_auto,w_322,ar_322:384'),
    srcSet: `
      ${imageUrl(publicId, 'c_fill,g_auto,w_322,ar_322:384')} 322w,
      ${imageUrl(publicId, 'c_fill,g_auto,w_644,ar_322:384')} 644w,
      ${imageUrl(publicId, 'c_fill,g_auto,w_966,ar_322:384')} 966w,
      ${imageUrl(publicId, 'c_fill,g_auto,w_1288,ar_322:384')} 1288w
    `,
  };
}
