import useStyleSheet from '@hooks/useStyleSheet';
import useWasViewed from '@hooks/useWasViewed';
import SectionWrapper, { ComponentThemeTaxonomy } from '@presentation/SectionWrapper';
import imageUrl from '@utils/cloudinary';
import GatsbyImage, { FixedObject, FluidObject } from 'gatsby-image';
import React, { useState } from 'react';
import { useBoolean, useInterval } from 'react-use';
import s from './HeroBanner.styles';
import HeroBannerItem, { HeroBannerItemModel } from './HeroBannerItem';

export interface HeroBannerProps {
  interval?: number;
  items: HeroBannerItemModel[];
  turtleImage?: FixedObject;
  theme?: ComponentThemeTaxonomy;
}

const HeroBanner: React.FC<HeroBannerProps> = ({
  interval = 5000,
  items,
  turtleImage,
  theme = ComponentThemeTaxonomy.NONE,
}) => {
  const { ref: refInView, wasViewed } = useWasViewed({
    rootMargin: '0px 0px -30% 0px',
  });

  const [activeIndex, setActiveIndex] = useState(0);
  const [isRunning, toggleIsRunning] = useBoolean(true);
  const [isVideoPlaying, toggleIsVideoPlaying] = useBoolean(false);

  useStyleSheet('https://cdn.plyr.io/3.5.2/plyr.css');

  useInterval(
    () => {
      if (activeIndex + 1 === items.length) {
        setActiveIndex(0);
      } else {
        setActiveIndex(activeIndex + 1);
      }
    },
    isRunning && !isVideoPlaying ? interval : null,
  );

  return (
    <SectionWrapper ref={refInView} theme={theme}>
      {turtleImage && (
        <s.TurtleImage>
          <GatsbyImage fixed={turtleImage} loading="eager" />
        </s.TurtleImage>
      )}
      <s.Container
        onMouseEnter={() => {
          toggleIsRunning(false);
        }}
        onMouseLeave={() => {
          toggleIsRunning(true);
        }}
      >
        {items.map((item, index) => (
          <HeroBannerItem
            key={item.id}
            {...item}
            isActive={wasViewed && index === activeIndex}
            isVideoPlaying={isVideoPlaying}
            loading={index === 0 ? 'eager' : 'lazy'}
            onVideoPause={() => {
              toggleIsVideoPlaying(false);
            }}
            onVideoPlay={() => {
              toggleIsVideoPlaying(true);
            }}
          />
        ))}
        {items.length > 1 && (
          <s.Pagination>
            {items.map((item, index) => (
              <s.PaginationItem
                key={item.id}
                active={index === activeIndex}
                onClick={(event) => {
                  event.preventDefault();
                  setActiveIndex(index);
                }}
              >
                Go to slide {index}
              </s.PaginationItem>
            ))}
          </s.Pagination>
        )}
      </s.Container>
    </SectionWrapper>
  );
};

export default HeroBanner;

export function itemImage(publicId?: string): FluidObject {
  return {
    aspectRatio: 1,
    sizes: '(max-width: 800px) 100vw, 800px',
    src: imageUrl(publicId, 'c_fill,g_auto,w_800,ar_1:1'),
    srcSet: `
      ${imageUrl(publicId, 'c_fill,g_auto,w_800,ar_1:1')} 800w,
      ${imageUrl(publicId, 'c_fill,g_auto,w_1200,ar_1:1')} 1200w,
      ${imageUrl(publicId, 'c_fill,g_auto,w_1600,ar_1:1')} 1600w
    `,
  };
}
