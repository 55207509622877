import { graphql } from 'gatsby';
import React from 'react';
import TablePres from '@presentation/Table';
import { ContentModels } from 'schema/ContentModels';

export interface TableProps {
  data: ContentModels.ContentfulTable;
}

const Table: React.FC<TableProps> = ({ data }) => {
  if (!data.table?.tableData) {
    return null;
  }

  return <TablePres tableData={data.table?.tableData} />;
};

export default Table;

export const ContentfulTableFragment = graphql`
  fragment ContentfulTableFragment on ContentfulTable {
    table
  }
`;
