import useWasViewed from '@hooks/useWasViewed';
import { ComponentThemeTaxonomy } from '@presentation/SectionWrapper';
import imageUrl from '@utils/cloudinary';
import GatsbyImage, { FluidObject } from 'gatsby-image';
import Markdown from 'markdown-to-jsx';
import React from 'react';
import s from './Gallery.styles';

export interface GalleryItemModel {
  id: string;
  title?: string;
  image: FluidObject;
}

export interface GalleryItemProps extends GalleryItemModel {}

export interface GalleryProps {
  title?: string;
  subtitle?: string;
  content?: string;
  items: GalleryItemModel[];
  reverse?: boolean;
  theme?: ComponentThemeTaxonomy;
}

const GalleryItem: React.FC<GalleryItemProps> = ({ image, title }) => {
  return (
    <s.Item>
      <s.ItemImage>
        <GatsbyImage fluid={image} />
      </s.ItemImage>
      {title && <s.ItemTitle>{title}</s.ItemTitle>}
    </s.Item>
  );
};

const Gallery: React.FC<GalleryProps> = ({
  title,
  subtitle,
  content,
  items,
  reverse = false,
  theme = ComponentThemeTaxonomy.LIGHT_BLUE,
}) => {
  const { ref: refInView, wasViewed } = useWasViewed({
    rootMargin: '0px 0px -50% 0px',
  });

  const titleVariants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.15,
      },
    },
  };

  const subtitleVariants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  };

  const contentVariants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.3,
      },
    },
  };

  const itemsVariants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.45,
      },
    },
  };

  return (
    <s.Wrapper ref={refInView} padding theme={theme}>
      <s.Container animate={wasViewed ? 'visible' : 'hidden'}>
        {(title || subtitle || content) && (
          <s.ContentContainer>
            {(title || subtitle) && (
              <s.TitleContainer>
                {title && (
                  <s.Title initial="hidden" variants={titleVariants}>
                    {title}
                  </s.Title>
                )}
                {subtitle && (
                  <s.Subtitle initial="hidden" variants={subtitleVariants}>
                    {subtitle}
                  </s.Subtitle>
                )}
              </s.TitleContainer>
            )}
            {content && (
              <s.Content initial="hidden" variants={contentVariants}>
                <Markdown>{content}</Markdown>
              </s.Content>
            )}
          </s.ContentContainer>
        )}
        <s.ItemsContainer initial="hidden" variants={itemsVariants}>
          <s.ItemsContainerInner>
            <s.ItemsTrack>
              {items.map((item) => (
                <GalleryItem key={item.id} {...item} />
              ))}
            </s.ItemsTrack>
          </s.ItemsContainerInner>
        </s.ItemsContainer>
      </s.Container>
    </s.Wrapper>
  );
};

export default Gallery;

export function image(publicId?: string): FluidObject {
  return {
    aspectRatio: 1,
    sizes: '(max-width: 1920px) 25vw, 520px',
    src: imageUrl(publicId, 'c_fill,g_auto,w_520,ar_1:1'),
    srcSet: `
      ${imageUrl(publicId, 'c_fill,g_auto,w_260,ar_1:1')} 260w,
      ${imageUrl(publicId, 'c_fill,g_auto,w_520,ar_1:1')} 520w
    `,
  };
}
