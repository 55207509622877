import SvgWave from '!babel-loader!react-svg-loader!@img/wave.svg';
import { Device, from } from '@utils/media';
import theme from '@utils/theme';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const Inner = styled(motion.div)`
  display: flex;
  flex-direction: row;
  place-items: center;
`;

const Text = styled.span`
  display: inline-block;
  color: ${theme.brand.blue.dark};
  ${theme.fonts.venusian};
  font-size: 16px;
  line-height: 18px;
  margin: 0 22px;
  text-transform: uppercase;
  white-space: nowrap;

  @media ${from(Device.Desktop)} {
    font-size: 18px;
    line-height: 20px;
    margin: 0 28px;
  }
`;

const Separator = styled(SvgWave)`
  color: ${theme.brand.blue.dark};
  height: 10px;
  width: 60px;

  path {
    stroke-width: 24px;
  }
`;

export default {
  Inner,
  Separator,
  Text,
  Wrapper,
};
