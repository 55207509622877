import { graphql } from 'gatsby';
import React from 'react';
import TestimonialsPres, { logo, TestimonialItemModel } from '@presentation/Testimonials';
import { isTestimonialsItem } from '@utils/guards';
import { Contentful } from 'schema/Contentful';
import { ContentModels } from 'schema/ContentModels';

export interface TestimonialsProps {
  data: ContentModels.ContentfulTestimonials;
}

const Testimonials: React.FC<TestimonialsProps> = ({ data }) => {
  return <TestimonialsPres items={createItemArray(data.items)} title={data.title ?? ''} />;
};

export default Testimonials;

function createItemArray(entries?: Contentful.ContentfulEntry[]): TestimonialItemModel[] {
  const menuItems = entries?.filter(isTestimonialsItem) ?? [];
  return menuItems.map(createItemModel);
}

function createItemModel(entry: ContentModels.ContentfulTestimonialsItem): TestimonialItemModel {
  return {
    id: entry.id ?? '',
    content: entry.testimonial?.testimonial ?? '',
    logo: logo(entry.logo?.[0]?.public_id),
    title: entry.title ?? '',
  };
}

export const ContentfulTestimonialsFragment = graphql`
  fragment ContentfulTestimonialsFragment on ContentfulTestimonials {
    items {
      id
      sys {
        contentType {
          sys {
            id
          }
        }
      }
      ... on ContentfulTestimonialsItem {
        id
        logo
        testimonial {
          testimonial
        }
        title
      }
    }
    title
  }
`;
