import { Device, until } from '@utils/media';
import { MotionValue, useTransform } from 'framer-motion';
import GatsbyImage, { FixedObject } from 'gatsby-image';
import Markdown from 'markdown-to-jsx';
import React from 'react';
import { useMedia } from 'react-use';
import s from './CentreBanner.styles';

export interface CentreBannerContentProps {
  progress: MotionValue<number>;
  content?: string;
  subtitle?: string;
  title: string;
  turtleImage1?: FixedObject;
  turtleImage2?: FixedObject;
  video?: string;
  onClickVideoCta?: () => void;
}

const CentreBannerContent: React.FC<CentreBannerContentProps> = ({
  content,
  subtitle,
  progress,
  title,
  turtleImage1,
  turtleImage2,
  video,
  onClickVideoCta = () => null,
}) => {
  const isTabletOrSmaller = useMedia(until(Device.TabletLarge));

  const xTurtle1 = useTransform(progress, [0, 1], [isTabletOrSmaller ? '-40%' : '0%', '-100%']);
  const xTurtle2 = useTransform(progress, [0, 1], [isTabletOrSmaller ? '40%' : '0%', '100%']);

  const yTitle = useTransform(progress, [0, 0.8], [0, -200]);
  const opacityTitle = useTransform(progress, [0, 0.8], [1, 0]);

  const ySubtitle = useTransform(progress, [0, 0.6], [0, -200]);
  const opacitySubtitle = useTransform(progress, [0, 0.6], [1, 0]);

  const yContent = useTransform(progress, [0, 1], [0, -200]);
  const opacityContent = useTransform(progress, [0, 1], [1, 0]);

  return (
    <s.ContentWrapper>
      {turtleImage1 && (
        <s.TurtleImage1 style={{ x: xTurtle1, scaleX: 1 }}>
          <GatsbyImage fixed={turtleImage1} />
        </s.TurtleImage1>
      )}
      {turtleImage2 && (
        <s.TurtleImage2 style={{ x: xTurtle2, scaleX: -1 }}>
          <GatsbyImage fixed={turtleImage2} />
        </s.TurtleImage2>
      )}
      <s.Wrapper>
        <s.ContentContainer>
          <s.TitleContainer>
            {title && (
              <s.Title
                style={{
                  opacity: opacityTitle,
                  y: yTitle,
                }}
              >
                {title}
              </s.Title>
            )}
            {subtitle && (
              <s.Subtitle
                style={{
                  opacity: opacitySubtitle,
                  y: ySubtitle,
                }}
              >
                {subtitle}
              </s.Subtitle>
            )}
          </s.TitleContainer>
          {content && (
            <s.Content
              style={{
                opacity: opacityContent,
                y: yContent,
              }}
            >
              <Markdown>{content}</Markdown>
            </s.Content>
          )}
        </s.ContentContainer>
        {video && (
          <s.VideoCta
            style={{
              opacity: opacityContent,
              y: yContent,
            }}
            onClick={(event) => {
              event.preventDefault();
              onClickVideoCta();
            }}
          >
            <s.VideoCtaText>Watch the video</s.VideoCtaText>
            <s.VideoCtaIconWrapper>
              <s.VideoCtaIcon />
            </s.VideoCtaIconWrapper>
          </s.VideoCta>
        )}
      </s.Wrapper>
    </s.ContentWrapper>
  );
};

export default CentreBannerContent;
