import { graphql } from 'gatsby';
import React from 'react';

import MapSectionPres from '@presentation/MapSection';
import { ContentModels } from 'schema/ContentModels';

export interface MapSectionProps {
  data: ContentModels.ContentfulMapSection;
}

const MapSection: React.FC<MapSectionProps> = ({ data }) => {
  return (
    <MapSectionPres
      content={data.content?.content}
      subtitle={data.subtitle}
      title={data.title ?? ''}
    />
  );
};

export default MapSection;

export const ContentfulMapSectionFragment = graphql`
  fragment ContentfulMapSectionFragment on ContentfulMapSection {
    content {
      content
    }
    subtitle
    title
  }
`;
