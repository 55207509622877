import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import ContactFormPres from '@presentation/ContactForm';
import { ContentModels } from 'schema/ContentModels';
import { SocialLinkProps } from '@presentation/SocialIcon';
import { Contentful } from 'schema/Contentful';
import { isSocialLink } from '@utils/guards';

export interface ContactFormProps {
  data: ContentModels.ContentfulContactForm;
}

const ContactForm: React.FC<ContactFormProps> = ({ data }) => {
  const queryData = useContactFormQuery();

  return (
    <ContactFormPres
      checkboxLabel={queryData.master.newsletterFormCheckboxLabel ?? ''}
      confirmationContent={data.confirmationContent?.confirmationContent}
      email={data.email}
      formContent={queryData.master.marketingCheckboxContent?.marketingCheckboxContent}
      phone={data.phone}
      socialLinks={createItemArray(data.socialLinks)}
      subtitle={data.subtitle}
      title={data.title}
    />
  );
};

export default ContactForm;

function createItemArray(entries?: Contentful.ContentfulEntry[]): SocialLinkProps[] {
  const items = entries?.filter(isSocialLink) ?? [];
  return items.map(createItemModel);
}

function createItemModel(entry: ContentModels.ContentfulSocialLink): SocialLinkProps {
  return {
    id: entry.id ?? '',
    icon: entry.icon ?? '',
    url: entry.url ?? '',
  };
}

interface ContactFormQuery {
  master: ContentModels.ContentfulMaster;
}

function useContactFormQuery(): ContactFormQuery {
  const data = useStaticQuery<ContactFormQuery>(graphql`
    {
      master: contentfulMaster {
        marketingCheckboxContent {
          marketingCheckboxContent
        }
        newsletterFormCheckboxLabel
      }
    }
  `);

  return data;
}

export const ContentfulContactFormFragment = graphql`
  fragment ContentfulContactFormFragment on ContentfulContactForm {
    email
    phone
    subtitle
    title
    confirmationContent {
      confirmationContent
    }
    socialLinks {
      id
      sys {
        contentType {
          sys {
            id
          }
        }
      }
      ... on ContentfulSocialLink {
        icon
        title
        url
      }
    }
  }
`;
