import SvgSugarcane from '!babel-loader!react-svg-loader!@img/sugarcane-01.svg';
import { markdownStyles } from '@utils/markdown';
import { Device, from } from '@utils/media';
import theme from '@utils/theme';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

const Wrapper = styled(motion.div)`
  position: relative;
  display: flex;
  flex-direction: column;
  place-content: center;
  place-items: center;

  @media ${from(Device.TabletLarge)} {
    min-height: 634px;
  }
`;

const sugarcaneStyles = css`
  position: absolute;
  top: 50%;
  display: none;
  transform-origin: center bottom;

  @media ${from(Device.TabletLarge)} {
    display: block;
  }
`;

const Sugarcane = styled(SvgSugarcane)`
  color: #1d1d1b;
  opacity: 0.1;
  z-index: 1;
`;

const SugarcaneLeft = styled(motion.div)`
  ${sugarcaneStyles};
  left: 0;
  transform: translate3d(-50%, -50%, 0) translate3d(-60%, -80px, 0) rotate(51deg);
`;

const SugarcaneRight = styled(motion.div)`
  ${sugarcaneStyles};
  right: 0;
  transform: translate3d(50%, -50%, 0) translate3d(60%, -80px, 0) rotate(-51deg);
`;

const Title = styled(motion.h2)`
  font-size: 14px;
  letter-spacing: 0.02em;
  line-height: 16px;
  margin: 0 0 40px;
  text-align: center;
  text-transform: uppercase;

  @media ${from(Device.TabletLarge)} {
    font-size: 16px;
    letter-spacing: 0.02em;
    line-height: 18px;
  }
`;

const Items = styled(motion.div)`
  display: grid;
  padding: 0 26px;

  @media ${from(Device.TabletLarge)} {
    padding: 0 96px;
  }
`;

const Item = styled(motion.blockquote)`
  display: flex;
  flex-direction: column;
  grid-column: 1;
  grid-row: 1;
  place-content: center;
  place-items: center;
  text-align: center;
  transform-origin: center bottom;
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
`;

const ItemContent = styled(motion.div)`
  ${markdownStyles};
  color: ${theme.brand.blue.default};
  ${theme.fonts.venusian};
  font-size: 25px;
  line-height: 35px;
  margin: 0 0 24px;
  max-width: 1080px;
  quotes: '“' '”';
  text-transform: uppercase;

  @media ${from(Device.TabletLarge)} {
    font-size: 40px;
    line-height: 60px;
    margin: 0 0 96px;
  }

  &::before {
    content: open-quote;
  }

  &::after {
    content: close-quote;
  }
`;

const ItemCitation = styled.cite`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  letter-spacing: 0.02em;
  line-height: 18px;
  place-content: center;
  place-items: center;
  text-transform: uppercase;
`;

const ItemCitationText = styled(motion.span)`
  margin-bottom: 8px;
`;

const ItemWave = styled.svg`
  color: ${theme.brand.red.default};
  width: 96px;
`;

const Controls = styled.div`
  position: absolute;
  top: 50%;
  display: flex;
  flex-direction: row;
  place-content: space-between;
  pointer-events: none;
  transform: translate3d(0, -50%, 0);
  width: calc(100% + 20px);
  z-index: 1;

  @media ${from(Device.TabletLarge)} {
    width: 100%;
  }
`;

export default {
  Controls,
  Item,
  ItemContent,
  ItemCitation,
  ItemCitationText,
  ItemWave,
  Items,
  Sugarcane,
  SugarcaneLeft,
  SugarcaneRight,
  Title,
  Wrapper,
};
