import { Device, from } from '@utils/media';
import styled from 'styled-components';

const Container = styled.div`
  margin: 64px 0;

  @media ${from(Device.TabletLarge)} {
    margin: 64px -100px;
  }
`;

export default {
  Container,
};
