import { graphql } from 'gatsby';
import React from 'react';

import ProductCtaPres, { image } from '@presentation/ProductCta';
import { ContentModels } from 'schema/ContentModels';
import { isProduct } from '@utils/guards';
import { Contentful } from 'schema/Contentful';
import addToCart from '@redux/reducers/shopify/thunks/addToCart';
import { useAppDispatch, useAppSelector } from '@redux/store';

export interface ProductCtaProps {
  data: ContentModels.ContentfulProductCta;
}

const ProductCta: React.FC<ProductCtaProps> = ({ data }) => {
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.shopify);

  const product = getProductEntry(data.product);

  return (
    <ProductCtaPres
      content={data.content?.content}
      cta1Text={data.cta1Text}
      cta2Text={data.cta2Text}
      productId={product?.shopifyProduct?.shopifyId}
      productImage={image(product?.image?.[0]?.public_id)}
      productOption={product?.shopifyProduct?.selectedOptions?.[0]?.value}
      productPrice={product?.shopifyProduct?.priceNumber}
      productTitle={product?.title}
      productUrl={product?.url}
      reverse={data.reverse}
      subtitle={data.subtitle}
      title={data.title ?? ''}
      onAddToCart={(variantId) => {
        if (loading === 'idle') {
          dispatch(addToCart({ variantId, quantity: 1 }));
        }
      }}
    />
  );
};

export default ProductCta;

function getProductEntry(
  entry?: Contentful.ContentfulEntry,
): ContentModels.ContentfulProduct | undefined {
  if (entry && isProduct(entry)) {
    return entry;
  }

  return undefined;
}

export const ContentfulProductCtaFragment = graphql`
  fragment ContentfulProductCtaFragment on ContentfulProductCta {
    content {
      content
    }
    cta1Text
    cta2Text
    product {
      id
      sys {
        contentType {
          sys {
            id
          }
        }
      }
      ... on ContentfulProduct {
        image
        shopifyProduct {
          priceNumber
          selectedOptions {
            name
            value
          }
          shopifyId
        }
        title
        url
      }
    }
    reverse
    subtitle
    title
  }
`;
