import { Contentful } from 'schema/Contentful';

export interface LinkedItemProps {
  inline?: boolean;
  index?: number;
  item: Contentful.ContentfulEntry;
}

/**
 * This enum maps to the Contentful content type names.
 */

export enum LinkedItemType {
  BackgroundImageCta = 'backgroundImageCta',
  CentreBanner = 'centreBanner',
  ContactForm = 'contactForm',
  ContentImage = 'contentImage',
  ContentSection = 'contentSection',
  CocktailsCarousel = 'cocktailsCarousel',
  CocktailsList = 'cocktailsList',
  CtaSection = 'ctaSection',
  FactsSection = 'factsSection',
  FramedCta = 'framedCta',
  Gallery = 'gallery',
  HeroBanner = 'heroBanner',
  MapSection = 'mapSection',
  OurRumBanner = 'ourRumBanner',
  ProductCta = 'productCta',
  QuoteBlock = 'quoteBlock',
  ReviewsCarousel = 'reviewsCarousel',
  RumFacts = 'rumFacts',
  SimpleBanner = 'simpleBanner',
  StockistsGrid = 'stockistsGrid',
  Table = 'table',
  Testimonials = 'testimonials',
  Ticker = 'ticker',
  VerticalFactsSection = 'verticalFactsSection',
  Video = 'video',
}
