import React, { useState } from 'react';
import SectionWrapper, { ComponentThemeTaxonomy } from '@presentation/SectionWrapper';
import s from './ReviewsCarousel.styles';
import CarouselButton from '@presentation/CarouselButton';
import Markdown from 'markdown-to-jsx';
import { motion, Variants } from 'framer-motion';
import useWasViewed from '@hooks/useWasViewed';

export interface ReviewModel {
  id: string;
  content: string;
  citation: string;
}

export interface ReviewsCarouselItemProps extends ReviewModel {
  active: boolean;
}

export interface ReviewsCarouselProps {
  title?: string;
  items: ReviewModel[];
  theme?: ComponentThemeTaxonomy;
}

const ReviewsCarouselItem: React.FC<ReviewsCarouselItemProps> = ({ active, citation, content }) => {
  const itemVariants: Variants = {
    hidden: {
      transition: {
        delayChildren: 0,
        staggerChildren: 0.15,
        staggerDirection: -1,
      },
    },
    visible: {
      transition: {
        delayChildren: 0.6,
        staggerChildren: 0.15,
        staggerDirection: 1,
      },
    },
  };

  const contentVariants: Variants = {
    hidden: {
      opacity: 0,
      scale: 0.9,
      y: 20,
    },
    visible: {
      opacity: 1,
      scale: 1,
      y: 0,
      transition: {
        delayChildren: 0.15,
        staggerChildren: 0.15,
      },
    },
  };

  const citationVariants: Variants = {
    hidden: {
      opacity: 0,
      scale: 0.9,
    },
    visible: {
      opacity: 1,
      scale: 1,
    },
  };

  const waveVariants: Variants = {
    hidden: {
      opacity: 0,
      pathLength: 0,
    },
    visible: {
      opacity: 1,
      pathLength: 1,
    },
  };

  return (
    <s.Item initial="hidden" animate={active ? 'visible' : 'hidden'} variants={itemVariants}>
      <s.ItemContent initial="hidden" variants={contentVariants}>
        <Markdown>{content}</Markdown>
      </s.ItemContent>
      <s.ItemCitation>
        <s.ItemCitationText initial="hidden" variants={citationVariants}>
          {citation}
        </s.ItemCitationText>
        <s.ItemWave viewBox="0 0 511.9 52">
          <motion.path
            initial="hidden"
            variants={waveVariants}
            d="M511.9,44c-20.9,0-30.4-6.7-42.6-15.3C455.5,19,439.9,8,409.5,8s-46,11-59.8,20.7C337.6,37.3,328,44,307.1,44 c-20.9,0-30.4-6.7-42.6-15.3C250.8,19,235.1,8,204.8,8s-46,11-59.8,20.7C132.8,37.3,123.2,44,102.4,44S72,37.3,59.8,28.7 C46,19,30.3,8,0,8"
            style={{
              fill: 'none',
              stroke: 'currentColor',
              strokeWidth: 16,
              strokeMiterlimit: 10,
            }}
          />
        </s.ItemWave>
      </s.ItemCitation>
    </s.Item>
  );
};

const ReviewsCarousel: React.FC<ReviewsCarouselProps> = ({
  items,
  title,
  theme = ComponentThemeTaxonomy.NONE,
}) => {
  const { ref: refInView, wasViewed } = useWasViewed({
    rootMargin: '0px 0px -50% 0px',
  });

  const [activeId, setActiveId] = useState(items?.[0]?.id);
  const activeIndex = items.findIndex((item) => item.id === activeId);

  const sugarcaneLeftVariants: Variants = {
    hidden: {
      opacity: 1,
      rotate: 0,
      x: '-110%',
      y: '-50%',
    },
    visible: {
      opacity: 1,
      rotate: 51,
      x: '-110%',
      y: '-50%',
      transition: {
        type: 'spring',
        damping: 10,
        restDelta: 0.1,
        restSpeed: 1,
        stiffness: 60,
      },
    },
  };

  const sugarcaneRightVariants: Variants = {
    hidden: {
      opacity: 1,
      rotate: 0,
      x: '110%',
      y: '-50%',
    },
    visible: {
      opacity: 1,
      rotate: -51,
      x: '110%',
      y: '-50%',
      transition: {
        type: 'spring',
        damping: 10,
        delay: 0.15,
        restDelta: 0.1,
        restSpeed: 1,
        stiffness: 60,
      },
    },
  };

  const titleVariants: Variants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  };

  return (
    <SectionWrapper ref={refInView} padding theme={theme}>
      <s.SugarcaneLeft
        animate={wasViewed ? 'visible' : 'hidden'}
        initial="hidden"
        variants={sugarcaneLeftVariants}
      >
        <s.Sugarcane />
      </s.SugarcaneLeft>
      <s.SugarcaneRight
        animate={wasViewed ? 'visible' : 'hidden'}
        initial="hidden"
        variants={sugarcaneRightVariants}
      >
        <s.Sugarcane />
      </s.SugarcaneRight>
      <s.Wrapper animate={wasViewed ? 'visible' : 'hidden'}>
        {title && (
          <s.Title initial="hidden" variants={titleVariants}>
            {title}
          </s.Title>
        )}
        <s.Items>
          {items.map((item) => (
            <ReviewsCarouselItem
              key={item.id}
              {...item}
              active={wasViewed && activeId === item.id}
            />
          ))}
        </s.Items>
        {items.length > 1 && (
          <s.Controls>
            <CarouselButton
              direction="prev"
              disabled={activeIndex === 0}
              size="medium"
              onClick={(event) => {
                event.preventDefault();
                const previousItem = items[activeIndex - 1];
                setActiveId(previousItem?.id ?? activeId);
              }}
            />
            <CarouselButton
              direction="next"
              disabled={activeIndex === items.length - 1}
              size="medium"
              onClick={(event) => {
                event.preventDefault();
                const nextItem = items[activeIndex + 1];
                setActiveId(nextItem?.id ?? activeId);
              }}
            />
          </s.Controls>
        )}
      </s.Wrapper>
    </SectionWrapper>
  );
};

export default ReviewsCarousel;
