/** Load the Plyr library and resolve the promise when the Plyr instance is ready. */
async function loadPlyr(element: HTMLElement, options?: Plyr.Options): Promise<Plyr> {
  const { default: Plyr } = await import('plyr');
  const plyr = new Plyr(element, options);

  return new Promise((resolve) => {
    plyr.on('ready', () => {
      resolve(plyr);
    });
  });
}

export default loadPlyr;
