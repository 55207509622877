import { graphql } from 'gatsby';
import React from 'react';
import QuoteBlockPres from '@presentation/QuoteBlock';
import { ContentModels } from 'schema/ContentModels';

export interface QuoteBlockProps {
  data: ContentModels.ContentfulQuoteBlock;
}

const QuoteBlock: React.FC<QuoteBlockProps> = ({ data }) => {
  return <QuoteBlockPres content={data.quote?.quote ?? ''} citation={data.citation} />;
};

export default QuoteBlock;

export const ContentfulQuoteBlockFragment = graphql`
  fragment ContentfulQuoteBlockFragment on ContentfulQuoteBlock {
    citation
    quote {
      quote
    }
  }
`;
