import imageUrl from '@utils/cloudinary';
import GatsbyImage, { FluidObject } from 'gatsby-image';
import React from 'react';
import s from './ContentImage.styles';

export interface ContentImageProps {
  title?: string;
  image?: FluidObject;
}

const ContentImage: React.FC<ContentImageProps> = ({ image, title }) => {
  if (!image) {
    return null;
  }

  return (
    <s.Container>
      <GatsbyImage alt={title} fluid={image} />
    </s.Container>
  );
};

export default ContentImage;

export function image(publicId?: string, ratio?: number): FluidObject {
  return {
    aspectRatio: ratio ?? 1,
    sizes: '(max-width: 1000px) 100vw, 1000px',
    src: imageUrl(publicId, 'c_fill,g_auto,w_1000'),
    srcSet: `
      ${imageUrl(publicId, 'c_fill,g_auto,w_400')} 400w,
      ${imageUrl(publicId, 'c_fill,g_auto,w_500')} 500w,
      ${imageUrl(publicId, 'c_fill,g_auto,w_1000')} 1000w,
      ${imageUrl(publicId, 'c_fill,g_auto,w_1500')} 1500w,
      ${imageUrl(publicId, 'c_fill,g_auto,w_2000')} 2000w
    `,
  };
}
