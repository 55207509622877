import { graphql } from 'gatsby';
import React from 'react';

import GalleryPres, { GalleryItemModel, image } from '@presentation/Gallery';
import { ContentModels } from 'schema/ContentModels';
import { Contentful } from 'schema/Contentful';
import { isGalleryItem } from '@utils/guards';

export interface GalleryProps {
  data: ContentModels.ContentfulGallery;
}

const Gallery: React.FC<GalleryProps> = ({ data }) => {
  return (
    <GalleryPres
      items={createItemArray(data.items)}
      content={data.content?.content}
      subtitle={data.subtitle}
      title={data.title}
    />
  );
};

export default Gallery;

function createItemArray(entries?: Contentful.ContentfulEntry[]): GalleryItemModel[] {
  const menuItems = entries?.filter(isGalleryItem) ?? [];
  return menuItems.map(createItemModel);
}

function createItemModel(entry: ContentModels.ContentfulGalleryItem): GalleryItemModel {
  return {
    id: entry.id ?? '',
    image: image(entry.galleryItemImage?.[0]?.public_id),
    title: entry.title,
  };
}

export const ContentfulGalleryFragment = graphql`
  fragment ContentfulGalleryFragment on ContentfulGallery {
    content {
      content
    }
    items {
      id
      sys {
        contentType {
          sys {
            id
          }
        }
      }
      ... on ContentfulGalleryItem {
        galleryItemImage: image
        title
      }
    }
    subtitle
    title
  }
`;
