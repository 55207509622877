import useWasViewed from '@hooks/useWasViewed';
import { Link } from '@presentation/Button';
import SectionWrapper, { ComponentThemeTaxonomy } from '@presentation/SectionWrapper';
import { Variants } from 'framer-motion';
import GatsbyImage, { FixedObject } from 'gatsby-image';
import Markdown from 'markdown-to-jsx';
import React from 'react';
import s from './FramedCta.styles';

export interface FramedCtaProps {
  badge?: FixedObject;
  content?: string;
  ctaText?: string;
  ctaUrl?: string;
  ctaNewWindow?: boolean;
  title?: string;
  turtle?: FixedObject;
  theme?: ComponentThemeTaxonomy;
}

const FramedCta: React.FC<FramedCtaProps> = ({
  badge,
  content,
  ctaNewWindow,
  ctaText,
  ctaUrl,
  title,
  turtle,
  theme = ComponentThemeTaxonomy.NONE,
}) => {
  const { ref: refInView, wasViewed } = useWasViewed({
    rootMargin: '0px 0px -50% 0px',
  });

  const containerVariants: Variants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
    },
  };

  const badgeVariants: Variants = {
    hidden: {
      opacity: 0,
      scale: 1.5,
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delay: 0.45,
      },
    },
  };

  const turtleVariants: Variants = {
    hidden: {
      opacity: 0,
      x: '-100%',
    },
    visible: {
      opacity: 1,
      x: '0%',
    },
  };

  const titleVariants: Variants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  };

  const contentVariants: Variants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.15,
      },
    },
  };

  const ctaVariants: Variants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.3,
      },
    },
  };

  return (
    <SectionWrapper ref={refInView} padding theme={theme}>
      <s.Container
        animate={wasViewed ? 'visible' : 'hidden'}
        initial="hidden"
        variants={containerVariants}
      >
        {badge && (
          <s.Badge initial="hidden" style={{ x: '-50%' }} variants={badgeVariants}>
            <GatsbyImage fixed={badge} />
          </s.Badge>
        )}
        {turtle && (
          <s.Turtle
            initial="hidden"
            style={{ y: '100%' }}
            variants={turtleVariants}
            transition={{
              delay: 0.15,
              duration: 0.5,
              ease: 'easeOut',
            }}
          >
            <GatsbyImage fixed={turtle} />
          </s.Turtle>
        )}
        <s.Inner>
          {title && (
            <s.Title initial="hidden" variants={titleVariants}>
              {title}
            </s.Title>
          )}
          {content && (
            <s.Content initial="hidden" variants={contentVariants}>
              <Markdown>{content}</Markdown>
            </s.Content>
          )}
          {ctaUrl && (
            <s.CallToAction initial="hidden" variants={ctaVariants}>
              <Link href={ctaUrl} target={ctaNewWindow ? '_blank' : '_self'}>
                {ctaText}
              </Link>
            </s.CallToAction>
          )}
        </s.Inner>
      </s.Container>
    </SectionWrapper>
  );
};

export default FramedCta;
