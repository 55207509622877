import useWasViewed from '@hooks/useWasViewed';
import Form from '@presentation/Form';
import SectionWrapper, { ComponentThemeTaxonomy, SectionFlush } from '@presentation/SectionWrapper';
import SocialIcon, { SocialLinkProps } from '@presentation/SocialIcon';
import { fadeUp } from '@utils/animations';
import { EMAIL_REGEX } from '@utils/validation';
import { Variants } from 'framer-motion';
import Markdown from 'markdown-to-jsx';
import React from 'react';
import { useBoolean } from 'react-use';
import s from './ContactForm.styles';

export interface ContactFormProps {
  checkboxLabel: string;
  confirmationContent?: string;
  email?: string;
  formContent?: string;
  phone?: string;
  socialLinks: SocialLinkProps[];
  subtitle?: string;
  title?: string;
  theme?: ComponentThemeTaxonomy;
}

const ContactForm: React.FC<ContactFormProps> = ({
  checkboxLabel,
  confirmationContent = '',
  email,
  formContent,
  phone,
  socialLinks,
  subtitle,
  title,
  theme = ComponentThemeTaxonomy.NONE,
}) => {
  const { ref: refInView, wasViewed } = useWasViewed({
    rootMargin: '0px 0px -30% 0px',
  });

  const [submitted, toggleSubmitted] = useBoolean(false);

  const wrapperVariants: Variants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
    },
  };

  const stampVariants: Variants = {
    hidden: {
      opacity: 0,
      scale: 1.5,
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delay: 0.75,
      },
    },
  };

  const separatorVariants: Variants = {
    hidden: {
      backgroundSize: '100% 0%',
    },
    visible: {
      backgroundSize: '100% 100%',
      transition: {
        delay: 0.15,
      },
    },
  };

  return (
    <SectionWrapper ref={refInView} flush={SectionFlush.TOP} padding theme={theme}>
      <s.Wrapper
        animate={wasViewed ? 'visible' : 'hidden'}
        initial="hidden"
        variants={wrapperVariants}
      >
        <s.Container>
          <s.Frame
            clipPath={
              <clipPath id="form-frame" clipPathUnits="objectBoundingBox">
                <path d="M1,0 H0 v0.476 c0,0,0,0,0,0 c0.006,0,0.011,0.01,0.011,0.022 s-0.005,0.022,-0.011,0.022 c0,0,0,0,0,0 V1 h1 V0.52 c-0.006,0,-0.01,-0.011,-0.01,-0.023 c0,-0.012,0.005,-0.021,0.01,-0.022 V0"></path>
              </clipPath>
            }
            clipPathId="form-frame"
          >
            <s.Inner>
              <s.ContentColumn>
                <s.StampWrapper initial="hidden" variants={stampVariants}>
                  <s.Stamp />
                </s.StampWrapper>
                {title && <s.Title {...fadeUp()}>{title}</s.Title>}
                {email && (
                  <s.ContactDetail {...fadeUp(0.15)}>
                    Email:{' '}
                    <s.ContactDetailLink href={`mailto:${email}`}>{email}</s.ContactDetailLink>
                  </s.ContactDetail>
                )}
                {phone && (
                  <s.ContactDetail {...fadeUp(0.3)}>
                    Phone: <s.ContactDetailLink href={`tel:${phone}`}>{phone}</s.ContactDetailLink>
                  </s.ContactDetail>
                )}
                {socialLinks && (
                  <>
                    {subtitle && <s.Subtitle {...fadeUp(0.45)}>{subtitle}</s.Subtitle>}
                    <s.SocialLinks {...fadeUp(0.6)}>
                      {socialLinks.map((s) => (
                        <SocialIcon key={s.id} {...s} />
                      ))}
                    </s.SocialLinks>
                  </>
                )}
              </s.ContentColumn>
              <s.Separator initial="hidden" variants={separatorVariants} />
              <s.FormColumn>
                {submitted ? (
                  <s.Content {...fadeUp()}>
                    <Markdown>{confirmationContent}</Markdown>
                  </s.Content>
                ) : (
                  <s.FormWrapper {...fadeUp()}>
                    <Form
                      name="ContactForm"
                      submitText="Send Enquiry"
                      onSuccess={() => {
                        toggleSubmitted(true);
                      }}
                      content={formContent}
                      fields={[
                        {
                          id: 'full-name',
                          label: 'Full Name',
                          required: true,
                          type: 'text',
                        },
                        {
                          id: 'email',
                          label: 'Email address',
                          pattern: EMAIL_REGEX,
                          required: true,
                          type: 'email',
                        },
                        {
                          id: 'contact-number',
                          label: 'Contact number',
                          required: false,
                          type: 'tel',
                        },
                        {
                          id: 'enquiry-type',
                          label: 'Enquiry type',
                          required: true,
                          type: 'select',
                          options: [
                            { value: '' },
                            { value: 'Online order enquires' },
                            { value: 'Customer service team' },
                            { value: 'Press and advertising' },
                            { value: 'Other' },
                          ],
                        },
                        {
                          id: 'message',
                          label: 'Message',
                          required: true,
                          type: 'textarea',
                        },
                        {
                          id: 'marketing',
                          label: checkboxLabel,
                          required: false,
                          type: 'checkbox',
                        },
                        {
                          id: 'terms',
                          label: 'I agree to terms of use and privacy policy',
                          required: true,
                          type: 'checkbox',
                        },
                      ]}
                    />
                  </s.FormWrapper>
                )}
              </s.FormColumn>
            </s.Inner>
          </s.Frame>
        </s.Container>
      </s.Wrapper>
    </SectionWrapper>
  );
};

export default ContactForm;
