import SvgSugarcane from '!babel-loader!react-svg-loader!@img/sugarcane-01.svg';
import { markdownStyles } from '@utils/markdown';
import { Device, from, until } from '@utils/media';
import theme from '@utils/theme';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const Container = styled.section``;

const ContentContainer = styled(motion.div)`
  position: relative;
  display: flex;
  flex-direction: column;
  place-content: center;
  place-items: center;
  text-align: center;
  z-index: 2;
`;

const SvgSugarcane1 = styled(SvgSugarcane)`
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  color: #1d1d1b;
  opacity: 0.1;
  transform: translate3d(50%, -100%, 0) translate3d(380px, -220px, 0) rotate(230deg);
  transform-origin: center bottom;
  z-index: 1;

  @media ${from(Device.Tablet)} {
    display: block;
  }
`;

const SvgSugarcane2 = styled(SvgSugarcane)`
  position: absolute;
  bottom: 0;
  left: 0;
  display: none;
  color: #1d1d1b;
  opacity: 0.1;
  transform: translate3d(-50%, 0, 0) translate3d(-320px, 240px, 0) rotate(40deg);
  transform-origin: center bottom;
  z-index: 1;

  @media ${from(Device.Tablet)} {
    display: block;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;

const Title = styled(motion.h2)`
  color: ${theme.brand.red.default};
  font-size: 40px;
  line-height: 45px;
  margin: 0 0 16px;
  max-width: 820px;
  text-transform: uppercase;

  @media ${from(Device.DesktopSmall)} {
    font-size: 60px;
    line-height: 70px;
  }
`;

const Subtitle = styled(motion.h3)`
  font-size: 14px;
  letter-spacing: 0.05em;
  line-height: 16px;
  margin: 0 0 16px;
  max-width: 820px;
  text-transform: uppercase;

  @media ${from(Device.DesktopSmall)} {
    font-size: 18px;
    letter-spacing: 0.05em;
    line-height: 20px;
  }
`;

const Content = styled(motion.div)`
  ${markdownStyles};
  ${theme.fonts.brandon.bold};
  font-size: 16px;
  letter-spacing: 0.02em;
  line-height: 26px;
  margin: 0 0 32px;
  max-width: 820px;
  text-transform: uppercase;

  @media ${from(Device.DesktopSmall)} {
    font-size: 20px;
    letter-spacing: 0.02em;
    line-height: 28px;
  }
`;

const ImageContainer = styled(motion.div)`
  position: relative;
  display: block;
  place-content: center;
  place-items: center;
  pointer-events: none;
  text-align: center;
  user-select: none;
  width: 100%;
  z-index: 2;

  @media ${from(Device.Tablet)} {
    display: grid;
    gap: 64px;
    grid-template-columns: 1fr 1fr;
  }

  @media ${from(Device.DesktopSmall)} {
    gap: 32px;
    grid-template-columns: 1fr;
    padding: 48px 0;
  }
`;

const Image = styled.div`
  position: relative;
  display: none;
  margin: 0 auto 0 0;
  max-width: 311px;
  width: 100%;

  @media ${from(Device.Tablet)} {
    display: block;
  }

  @media ${from(Device.DesktopSmall)} {
    grid-column: 1;
    grid-row: 1;
    margin: 0 auto;
  }
`;

const Badge = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  display: none;
  height: 190px;
  transform: translate3d(50%, 50%, 0) translate3d(20px, -50px, 0);
  width: 190px;

  @media ${from(Device.DesktopSmall)} {
    display: block;
  }
`;

const FactsWrapper = styled.div`
  display: grid;
  width: 100%;

  @media ${from(Device.DesktopSmall)} {
    grid-column: 1;
    grid-row: 1;
  }
`;

const FactsList = styled(motion.div)`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(1, 1fr);
  margin: 0 0 48px;

  &:first-child {
    opacity: 1;
  }

  @media ${from(Device.Tablet)} {
    gap: 64px;
    margin: 96px 0 0;
  }

  @media ${from(Device.DesktopSmall)} {
    gap: 128px 640px;
    grid-template-columns: repeat(2, 1fr);
    margin: 0 0 48px;
  }
`;

const FactItem = styled(motion.div)`
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  min-width: 0;
  place-items: center;
  text-align: left;

  @media ${from(Device.Tablet)} {
    flex-direction: column;
    padding-left: 32px;
    place-items: flex-start;
  }

  @media ${from(Device.DesktopSmall)} {
    &:nth-child(even) {
      transform: translate3d(0, -60px, 0);

      ${() => FactArrow} {
        top: 0;
        left: 0;
        transform: translate3d(-100%, 50%, 0) translate3d(-32px, 0, 0) rotate(153deg);
      }
    }

    &:nth-child(odd) {
      text-align: right;
      align-items: flex-end;
      padding-right: 32px;
      transform: translate3d(0, 60px, 0);

      ${() => FactArrow} {
        bottom: 0;
        right: 0;
        transform: translate3d(100%, 50%, 0) translate3d(24px, 0, 0) rotate(333deg);
      }
    }

    &:nth-child(2) {
      ${() => FactArrow} {
        transform: translate3d(-100%, 50%, 0) translate3d(-64px, 0, 0) rotate(153deg);
      }
    }
  }
`;

const FactItemIconWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  background: ${theme.brand.paper.default};
  border: 3px solid ${theme.brand.blue.dark};
  border-radius: 50%;
  color: ${theme.brand.red.default};
  flex: 0 0 66px;
  height: 66px;
  margin: 0 32px 0 0;
  padding: 16px;
  place-content: center;
  place-items: center;
  width: 66px;

  @media ${from(Device.Tablet)} {
    margin: 0 0 16px 0;
  }

  @media ${from(Device.TabletLarge)} {
    flex: 0 0 110px;
    height: 110px;
    padding: 24px;
    width: 110px;
  }
`;

const FactItemIcon = styled.img`
  display: block;
  height: 100%;
  object-fit: contain;
  object-position: center center;
  width: 100%;
`;

const FactItemContent = styled(motion.div)`
  color: ${theme.brand.blue.dark};
  flex: 1 1 auto;
  ${theme.fonts.brandon.bold};
  font-size: 16px;
  letter-spacing: 0.02em;
  line-height: 26px;
  margin: 0;
  max-width: 180px;
  text-transform: uppercase;

  @media ${from(Device.Tablet)} {
    font-size: 14px;
    letter-spacing: normal;
    line-height: 21px;
    max-width: none;
  }

  @media ${from(Device.TabletLarge)} {
    font-size: 25px;
    line-height: 40px;
  }
`;

const FactArrow = styled.svg`
  position: absolute;
  display: none;
  color: ${theme.brand.red.default};
  height: 100px;
  transform-origin: center center;
  width: 100px;

  @media ${from(Device.Tablet)} {
    display: block;
  }

  @media ${until(Device.DesktopSmall)} {
    transform: translate3d(-100%, 0, 0) translate3d(-32px, 0, 0) rotate(153deg);
  }

  @media ${until(Device.TabletLarge)} {
    height: 50px;
    width: 50px;
  }

  @media ${until(Device.Tablet)} {
    transform: translate3d(-20%, -80%, 0) translate3d(-16px, -8px, 0) rotate(153deg);
  }
`;

export default {
  Badge,
  Content,
  ContentContainer,
  Container,
  FactArrow,
  FactsList,
  FactItem,
  FactItemIconWrapper,
  FactItemIcon,
  FactItemContent,
  FactsWrapper,
  Image,
  ImageContainer,
  Subtitle,
  SvgSugarcane1,
  SvgSugarcane2,
  Title,
  TitleContainer,
};
