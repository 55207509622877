import IconClose from '!babel-loader!react-svg-loader!@img/icon-close.svg';
import SvgSugarcane from '!babel-loader!react-svg-loader!@img/sugarcane-01.svg';
import bgTiled from '@img/stressed-bg-tiled.jpg';
import { markdownStyles } from '@utils/markdown';
import { Device, from, until } from '@utils/media';
import plyrStyles from '@utils/plyrStyles';
import theme from '@utils/theme';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const Wrapper = styled.div``;

const Inner = styled(motion.div)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 158px;
  z-index: 2;

  @media ${from(Device.TabletLarge)} {
    padding-top: 224px;
  }

  @media ${from(Device.Desktop)} {
    display: grid;
    align-items: center;
    gap: 32px;
    grid-template-columns: repeat(12, 1fr);
  }
`;

const Image = styled(motion.div)`
  grid-column: 1 / span 4;
  grid-row: 1;
  margin: 0 auto;
  max-width: 400px;
  padding: 0 40px;
  width: 100%;

  @media ${from(Device.Desktop)} {
    padding: 0;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  place-items: center;
  grid-column: 6 / span 7;
  grid-row: 1;
  text-align: center;

  @media ${from(Device.Desktop)} {
    place-items: flex-start;
    text-align: left;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin: 0 0 24px;

  @media ${until(Device.Desktop)} {
    order: 1;
  }
`;

const Title = styled(motion.h1)`
  color: ${theme.brand.red.default};
  font-size: 40px;
  line-height: 45px;
  margin: 0;
  text-transform: uppercase;

  @media ${from(Device.Tablet)} {
    font-size: 60px;
    line-height: 68px;
  }
`;

const Subtitle = styled(motion.h2)`
  color: ${theme.brand.blue.default};
  font-size: 14px;
  letter-spacing: 0.02em;
  line-height: 16px;
  margin: 0 0 16px;
  text-transform: uppercase;

  @media ${from(Device.Tablet)} {
    font-size: 18px;
    letter-spacing: 0.05em;
    line-height: 20px;
  }
`;

const Content = styled(motion.div)`
  ${markdownStyles};
  color: ${theme.brand.blue.dark};
  ${theme.fonts.brandon.bold};
  font-size: 16px;
  letter-spacing: 0.02em;
  line-height: 26px;
  margin: 0 0 32px;
  max-width: 645px;
  text-transform: uppercase;

  @media ${until(Device.Desktop)} {
    order: 3;
  }

  @media ${from(Device.Tablet)} {
    font-size: 20px;
    line-height: 28px;
  }
`;

const IngredientList = styled(motion.div)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -16px 32px;
  place-content: center;
  place-items: center;

  @media ${until(Device.Desktop)} {
    order: 4;
  }
`;

const IngredientItem = styled(motion.div)`
  display: flex;
  flex-direction: column;
  margin: 0 16px 16px;
  place-content: center;
  place-items: center;

  @media ${from(Device.Desktop)} {
    margin: 0 16px;
  }
`;

const IngredientIconWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  border: 2px solid ${theme.brand.blue.dark};
  border-radius: 50%;
  height: 73px;
  margin-bottom: 8px;
  padding: 16px;
  place-content: center;
  place-items: center;
  width: 73px;

  @media ${from(Device.Tablet)} {
    height: 100px;
    width: 100px;
  }
`;

const IngredientIcon = styled.img`
  display: block;
  height: 100%;
  object-fit: contain;
  object-position: center center;
  width: 100%;
`;

const IngredientTitle = styled(motion.div)`
  color: ${theme.brand.red.default};
  ${theme.fonts.brandon.bold};
  font-size: 16px;
  letter-spacing: 0.02em;
  line-height: 23px;
  margin: 0;
  text-transform: uppercase;
  white-space: nowrap;
`;

const CallToActions = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;

  @media ${until(Device.Desktop)} {
    order: 2;
  }

  @media ${from(Device.Tablet)} {
    flex-direction: row;
    place-items: center;
  }

  @media ${from(Device.Desktop)} {
    margin-bottom: 0;
  }
`;

const CallToAction1 = styled(motion.div)``;

const CallToAction2 = styled(motion.div)`
  margin-top: 16px;

  @media ${from(Device.Tablet)} {
    margin-top: 0;
    margin-left: 32px;
  }
`;

const Sugarcane = styled(SvgSugarcane)`
  position: absolute;
  bottom: 0;
  right: 0;
  display: block;
  color: #1d1d1b;
  opacity: 0.1;
  transform: translate3d(50%, 0, 0) translate3d(280px, 240px, 0) rotate(-50deg) scale(0.8);
  transform-origin: center bottom;
  z-index: 1;
`;

const VideoContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  background: ${theme.brand.black.default};
  border-radius: 5px;
  place-content: center;
  place-items: center;
  z-index: 100;
`;

const Video = styled.div`
  ${plyrStyles};
  width: 100%;
`;

const VideoClose = styled.button`
  position: absolute;
  top: 24px;
  right: 24px;
  z-index: 4;
  display: flex;
  background: transparent url(${bgTiled});
  background-repeat: repeat;
  border: 0;
  border-radius: 50%;
  color: ${theme.brand.white.default};
  cursor: pointer;
  height: 48px;
  place-content: center;
  place-items: center;
  margin: 0;
  outline: 0;
  padding: 0;
  transition: filter 0.3s ease-out, transform 0.3s ease-out;
  width: 48px;
  will-change: transform;
  z-index: 2;

  &:focus,
  &:hover {
    filter: brightness(1.35);
    transform: translate3d(0, -2px, 0);
  }
`;

const VideoCloseIcon = styled(IconClose)`
  display: block;
  height: 50%;
  width: 50%;
`;

export default {
  CallToActions,
  CallToAction1,
  CallToAction2,
  Content,
  ContentWrapper,
  Image,
  IngredientIcon,
  IngredientIconWrapper,
  IngredientItem,
  IngredientList,
  IngredientTitle,
  Inner,
  Subtitle,
  Sugarcane,
  Title,
  TitleContainer,
  Video,
  VideoClose,
  VideoCloseIcon,
  VideoContainer,
  Wrapper,
};
