import { graphql } from 'gatsby';
import React from 'react';

import CtaSectionPres from '@presentation/CtaSection';
import { ContentModels } from 'schema/ContentModels';

export interface CtaSectionProps {
  data: ContentModels.ContentfulCtaSection;
}

const CtaSection: React.FC<CtaSectionProps> = ({ data }) => {
  return (
    <CtaSectionPres
      ctaNewWindow={data.ctaNewWindow}
      ctaText={data.ctaText}
      ctaUrl={data.ctaUrl}
      subtitle={data.subtitle}
      title={data.title}
    />
  );
};

export default CtaSection;

export const ContentfulCtaSectionFragment = graphql`
  fragment ContentfulCtaSectionFragment on ContentfulCtaSection {
    ctaNewWindow
    ctaText
    ctaUrl
    subtitle
    title
  }
`;
