import React from 'react';
import SectionWrapper, { ComponentThemeTaxonomy } from '@presentation/SectionWrapper';
import s from './MapSection.styles';
import Markdown from 'markdown-to-jsx';
import { Variants } from 'framer-motion';
import useWasViewed from '@hooks/useWasViewed';

export interface MapSectionProps {
  title: string;
  subtitle?: string;
  content?: string;
  theme?: ComponentThemeTaxonomy;
}

const MapSection: React.FC<MapSectionProps> = ({
  content,
  subtitle,
  title,
  theme = ComponentThemeTaxonomy.LIGHT_BLUE,
}) => {
  const { ref: refInView, wasViewed } = useWasViewed({
    rootMargin: '0px 0px -50% 0px',
  });

  const titleVariants: Variants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.15,
      },
    },
  };

  const subtitleVariants: Variants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  };

  const contentVariants: Variants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.3,
      },
    },
  };

  const stampVariants: Variants = {
    hidden: {
      opacity: 0,
      scale: 1.5,
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delay: 0.15,
      },
    },
  };

  return (
    <SectionWrapper ref={refInView} padding theme={theme}>
      <s.SvgMap />
      <s.Wrapper animate={wasViewed ? 'visible' : 'hidden'}>
        <s.StampWrapper initial="hidden" variants={stampVariants}>
          <s.SvgStamp />
        </s.StampWrapper>
        <s.TitleContainer>
          {title && (
            <s.Title initial="hidden" variants={titleVariants}>
              {title}
            </s.Title>
          )}
          {subtitle && (
            <s.Subtitle initial="hidden" variants={subtitleVariants}>
              {subtitle}
            </s.Subtitle>
          )}
        </s.TitleContainer>
        {content && (
          <s.Content initial="hidden" variants={contentVariants}>
            <Markdown>{content}</Markdown>
          </s.Content>
        )}
      </s.Wrapper>
    </SectionWrapper>
  );
};

export default MapSection;
