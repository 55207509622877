import { Variant, Variants } from 'framer-motion';

type AnimationOptions = { hidden: Variant; visible: Variant };

export const FadeUpVariants: AnimationOptions = {
  hidden: {
    opacity: 0,
    y: 50,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
};

export const FadeRightVariants: AnimationOptions = {
  hidden: {
    opacity: 0,
    x: -50,
  },
  visible: {
    opacity: 1,
    x: 0,
  },
};

export const FadeLeftVariants: AnimationOptions = {
  hidden: {
    opacity: 0,
    x: 50,
  },
  visible: {
    opacity: 1,
    x: 0,
  },
};

type AnimationProps = {
  initial: string;
  variants: Variants;
};

export const fadeUp = (delay?: number, options?: AnimationOptions): AnimationProps => {
  const variants = {
    hidden: {
      ...FadeUpVariants.hidden,
      ...(options?.hidden ?? {}),
    },
    visible: {
      ...FadeUpVariants.visible,
      ...(options?.visible ?? {}),
    },
  };

  if (delay && delay > 0) {
    if (!variants.visible.transition) {
      variants.visible.transition = {};
    }

    variants.visible.transition.delay = delay;
  }

  return {
    initial: 'hidden',
    variants,
  };
};

export const fadeRight = (delay?: number, options?: AnimationOptions): AnimationProps => {
  const variants = {
    hidden: {
      ...FadeRightVariants.hidden,
      ...(options?.hidden ?? {}),
    },
    visible: {
      ...FadeRightVariants.visible,
      ...(options?.visible ?? {}),
    },
  };

  if (delay && delay > 0) {
    if (!variants.visible.transition) {
      variants.visible.transition = {};
    }

    variants.visible.transition.delay = delay;
  }

  return {
    initial: 'hidden',
    variants,
  };
};

export const fadeLeft = (delay?: number, options?: AnimationOptions): AnimationProps => {
  const variants = {
    hidden: {
      ...FadeLeftVariants.hidden,
      ...(options?.hidden ?? {}),
    },
    visible: {
      ...FadeLeftVariants.visible,
      ...(options?.visible ?? {}),
    },
  };

  if (delay && delay > 0) {
    if (!variants.visible.transition) {
      variants.visible.transition = {};
    }

    variants.visible.transition.delay = delay;
  }

  return {
    initial: 'hidden',
    variants,
  };
};
