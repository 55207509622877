import { graphql } from 'gatsby';
import React from 'react';

import BackgroundImageCta from '@connected/BackgroundImageCta';
import CentreBanner from '@connected/CentreBanner';
import ContactForm from '@connected/ContactForm';
import ContentImage from '@connected/ContentImage';
import ContentSection from '@connected/ContentSection';
import CocktailsCarousel from '@connected/CocktailsCarousel';
import CocktailsList from '@connected/CocktailsList';
import CtaSection from '@connected/CtaSection';
import FactsSection from '@connected/FactsSection';
import FramedCta from '@connected/FramedCta';
import Gallery from '@connected/Gallery';
import HeroBanner from '@connected/HeroBanner';
import MapSection from '@connected/MapSection';
import OurRumBanner from '@connected/OurRumBanner';
import ProductCta from '@connected/ProductCta';
import QuoteBlock from '@connected/QuoteBlock';
import ReviewsCarousel from '@connected/ReviewsCarousel';
import RumFacts from '@connected/RumFacts';
import SimpleBanner from '@connected/SimpleBanner';
import StockistsGrid from '@connected/StockistsGrid';
import Table from '@connected/Table';
import Testimonials from '@connected/Testimonials';
import Ticker from '@connected/Ticker';
import VerticalFactsSection from '@connected/VerticalFactsSection';
import Video from '@connected/Video';

import { LinkedItemProps, LinkedItemType } from './LinkedItem.types';

/*
  Create async components for all potential linked items.

  This does cause weird behavior on load in dev mode only, unfortunately
  the benefits in production are too important.
*/

const components = {
  [LinkedItemType.BackgroundImageCta]: BackgroundImageCta,
  [LinkedItemType.CentreBanner]: CentreBanner,
  [LinkedItemType.ContactForm]: ContactForm,
  [LinkedItemType.ContentImage]: ContentImage,
  [LinkedItemType.ContentSection]: ContentSection,
  [LinkedItemType.CocktailsCarousel]: CocktailsCarousel,
  [LinkedItemType.CocktailsList]: CocktailsList,
  [LinkedItemType.CtaSection]: CtaSection,
  [LinkedItemType.FactsSection]: FactsSection,
  [LinkedItemType.FramedCta]: FramedCta,
  [LinkedItemType.Gallery]: Gallery,
  [LinkedItemType.HeroBanner]: HeroBanner,
  [LinkedItemType.MapSection]: MapSection,
  [LinkedItemType.OurRumBanner]: OurRumBanner,
  [LinkedItemType.ProductCta]: ProductCta,
  [LinkedItemType.QuoteBlock]: QuoteBlock,
  [LinkedItemType.ReviewsCarousel]: ReviewsCarousel,
  [LinkedItemType.RumFacts]: RumFacts,
  [LinkedItemType.SimpleBanner]: SimpleBanner,
  [LinkedItemType.StockistsGrid]: StockistsGrid,
  [LinkedItemType.Table]: Table,
  [LinkedItemType.Testimonials]: Testimonials,
  [LinkedItemType.Ticker]: Ticker,
  [LinkedItemType.VerticalFactsSection]: VerticalFactsSection,
  [LinkedItemType.Video]: Video,
};

const LinkedItem: React.FC<LinkedItemProps> = ({ item, ...otherProps }) => {
  const type = item?.sys?.contentType?.sys?.id as LinkedItemType;

  if (!Object.keys(components).includes(type)) {
    return null;
  }

  const AsyncComponent = components[type];

  // @note: "as any" is awful, but the alternative is a type guard for every item.
  return <AsyncComponent data={item} {...otherProps} />;
};

export default LinkedItem;

/**
 * This fragment should be extended to include the fragments for all
 * content types that can be used in a "sections" field.
 */
export const LinkedItemsFragment = graphql`
  fragment LinkedItemsFragment on ContentfulReference {
    contentful_id
    id
    sys {
      contentType {
        sys {
          id
        }
      }
    }
    ... on ContentfulBackgroundImageCta {
      ...ContentfulBackgroundImageCtaFragment
    }
    ... on ContentfulCentreBanner {
      ...ContentfulCentreBannerFragment
    }
    ... on ContentfulContactForm {
      ...ContentfulContactFormFragment
    }
    ... on ContentfulContentSection {
      ...ContentfulContentSectionFragment
    }
    ... on ContentfulCocktailsCarousel {
      ...ContentfulCocktailsCarouselFragment
    }
    ... on ContentfulCocktailsList {
      ...ContentfulCocktailsListFragment
    }
    ... on ContentfulCtaSection {
      ...ContentfulCtaSectionFragment
    }
    ... on ContentfulFactsSection {
      ...ContentfulFactsSectionFragment
    }
    ... on ContentfulFramedCta {
      ...ContentfulFramedCtaFragment
    }
    ... on ContentfulGallery {
      ...ContentfulGalleryFragment
    }
    ... on ContentfulHeroBanner {
      ...ContentfulHeroBannerFragment
    }
    ... on ContentfulMapSection {
      ...ContentfulMapSectionFragment
    }
    ... on ContentfulOurRumBanner {
      ...ContentfulOurRumBannerFragment
    }
    ... on ContentfulProductCta {
      ...ContentfulProductCtaFragment
    }
    ... on ContentfulReviewsCarousel {
      ...ContentfulReviewsCarouselFragment
    }
    ... on ContentfulRumFacts {
      ...ContentfulRumFactsFragment
    }
    ... on ContentfulSimpleBanner {
      ...ContentfulSimpleBannerFragment
    }
    ... on ContentfulStockistsGrid {
      ...ContentfulStockistsGridFragment
    }
    ... on ContentfulTestimonials {
      ...ContentfulTestimonialsFragment
    }
    ... on ContentfulTicker {
      ...ContentfulTickerFragment
    }
    ... on ContentfulVerticalFactsSection {
      ...ContentfulVerticalFactsSectionFragment
    }
  }
`;
