import useWasViewed from '@hooks/useWasViewed';
import { Link } from '@presentation/Button';
import ImageFrame from '@presentation/ImageFrame';
import { LinkUnderline } from '@presentation/LinkUnderline';
import SectionWrapper, { ComponentThemeTaxonomy } from '@presentation/SectionWrapper';
import imageUrl from '@utils/cloudinary';
import { Variants } from 'framer-motion';
import GatsbyImage, { FluidObject } from 'gatsby-image';
import Markdown from 'markdown-to-jsx';
import React from 'react';
import s from './CocktailsCarousel.styles';

export interface CocktailsCarouselItemModel {
  id: string;
  content: string;
  image: FluidObject;
  title: string;
  url: string;
}

export interface CocktailsCarouselItemProps extends CocktailsCarouselItemModel {}

export interface CocktailsCarouselProps {
  title: string;
  subtitle?: string;
  content?: string;
  ctaText?: string;
  ctaUrl?: string;
  ctaNewWindow?: boolean;
  items: CocktailsCarouselItemModel[];
  theme?: ComponentThemeTaxonomy;
}

const CocktailsCarouselItem: React.FC<CocktailsCarouselItemProps> = ({
  content,
  image,
  title,
  url,
}) => {
  const itemVariants: Variants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.15,
      },
    },
  };

  const imageVariants: Variants = {
    hidden: {
      opacity: 0,
      scale: 0.5,
      y: 50,
    },
    visible: {
      opacity: 1,
      scale: 1,
      y: 0,
    },
  };

  const titleVariants: Variants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  };

  const contentVariants: Variants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  };

  const ctaVariants: Variants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  };

  return (
    <s.Item initial="hidden" variants={itemVariants}>
      <s.ItemImage initial="hidden" variants={imageVariants}>
        <ImageFrame>
          <GatsbyImage fluid={image} alt={title} />
        </ImageFrame>
      </s.ItemImage>
      <s.ItemTitle initial="hidden" variants={titleVariants}>
        {title}
      </s.ItemTitle>
      <s.ItemContent initial="hidden" variants={contentVariants}>
        <Markdown>{content}</Markdown>
      </s.ItemContent>
      <s.ItemCallToAction initial="hidden" variants={ctaVariants}>
        <LinkUnderline href={url} target="_self">
          View Cocktail
        </LinkUnderline>
      </s.ItemCallToAction>
    </s.Item>
  );
};

const CocktailsCarousel: React.FC<CocktailsCarouselProps> = ({
  content,
  ctaNewWindow,
  ctaText,
  ctaUrl,
  items,
  subtitle,
  title,
  theme = ComponentThemeTaxonomy.LIGHT_BLUE,
}) => {
  const { ref: refInView, wasViewed } = useWasViewed({
    rootMargin: '0px 0px -50% 0px',
  });

  const titleVariants: Variants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.15,
      },
    },
  };

  const subtitleVariants: Variants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  };

  const contentVariants: Variants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.3,
      },
    },
  };

  const ctaVariants: Variants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.45,
      },
    },
  };

  const itemsVariants: Variants = {
    hidden: {},
    visible: {
      transition: {
        delayChildren: 0.15,
        staggerChildren: 0.15,
      },
    },
  };

  return (
    <SectionWrapper ref={refInView} padding theme={theme}>
      <s.Wave1 />
      <s.Wave2 />
      <s.Wrapper animate={wasViewed ? 'visible' : 'hidden'}>
        <s.ContentContainer>
          <s.Wave3 />
          <s.TitleContainer>
            <s.Title initial="hidden" variants={titleVariants}>
              {title}
            </s.Title>
            <s.Subtitle initial="hidden" variants={subtitleVariants}>
              {subtitle}
            </s.Subtitle>
          </s.TitleContainer>
          {content && (
            <s.Content initial="hidden" variants={contentVariants}>
              <Markdown>{content}</Markdown>
            </s.Content>
          )}
          <s.CallToAction initial="hidden" variants={ctaVariants}>
            <Link href={ctaUrl} target={ctaNewWindow ? '_blank' : '_self'}>
              {ctaText}
            </Link>
          </s.CallToAction>
        </s.ContentContainer>
        <s.ItemsScroller>
          <s.Items initial="hidden" variants={itemsVariants}>
            {items.map((item) => (
              <CocktailsCarouselItem key={item.id} {...item} />
            ))}
          </s.Items>
        </s.ItemsScroller>
      </s.Wrapper>
    </SectionWrapper>
  );
};

export default CocktailsCarousel;

export function itemImage(publicId?: string): FluidObject {
  return {
    aspectRatio: 268 / 321,
    sizes: '(max-width: 268px) 100vw, 268px',
    src: imageUrl(publicId, 'c_thumb,g_auto,w_268,ar_268:321'),
    srcSet: `
      ${imageUrl(publicId, 'c_thumb,g_auto,w_268,ar_268:321')} 268w,
      ${imageUrl(publicId, 'c_thumb,g_auto,w_402,ar_268:321')} 402w,
      ${imageUrl(publicId, 'c_thumb,g_auto,w_536,ar_268:321')} 536w
    `,
  };
}
