import { graphql } from 'gatsby';
import React from 'react';

import CocktailsCarouselPres, {
  CocktailsCarouselItemModel,
  itemImage,
} from '@presentation/CocktailsCarousel';
import { ContentModels } from 'schema/ContentModels';
import { isCocktail } from '@utils/guards';
import { Contentful } from 'schema/Contentful';

export interface CocktailsCarouselProps {
  data: ContentModels.ContentfulCocktailsCarousel;
}

const CocktailsCarousel: React.FC<CocktailsCarouselProps> = ({ data }) => {
  return (
    <CocktailsCarouselPres
      content={data.content?.content}
      ctaNewWindow={data.ctaNewWindow}
      ctaText={data.ctaText}
      ctaUrl={data.ctaUrl}
      items={createItemArray(data.items)}
      subtitle={data.subtitle}
      title={data.title ?? ''}
    />
  );
};

export default CocktailsCarousel;

function createItemArray(entries?: Contentful.ContentfulEntry[]): CocktailsCarouselItemModel[] {
  const menuItems = entries?.filter(isCocktail) ?? [];
  return menuItems.map(createItemModel);
}

function createItemModel(entry: ContentModels.ContentfulCocktail): CocktailsCarouselItemModel {
  return {
    id: entry.id ?? '',
    content: entry.content?.content ?? '',
    image: itemImage(entry.image?.[0]?.public_id),
    title: entry.title ?? '',
    url: `/cocktails/#${entry.id}`,
  };
}

export const ContentfulCocktailsCarouselFragment = graphql`
  fragment ContentfulCocktailsCarouselFragment on ContentfulCocktailsCarousel {
    content {
      content
    }
    ctaNewWindow
    ctaText
    ctaUrl
    items {
      id
      sys {
        contentType {
          sys {
            id
          }
        }
      }
      ... on ContentfulCocktail {
        content {
          content
        }
        image
        title
      }
    }
    subtitle
    title
  }
`;
