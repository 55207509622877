import { loadCSS } from 'fg-loadcss';
import { useEffect } from 'react';

const LOADED: Record<string, HTMLLinkElement> = {};

async function useStyleSheet(href: string, deps: React.DependencyList = []): Promise<void> {
  useEffect(() => {
    if (LOADED[href]) {
      return;
    }

    LOADED[href] = loadCSS(href);
  }, deps);
}

export default useStyleSheet;
