import { graphql } from 'gatsby';
import React from 'react';

import StockistsGridPres, { logo, StockistModel } from '@presentation/StockistsGrid';
import { ContentModels } from 'schema/ContentModels';
import { Contentful } from 'schema/Contentful';
import { isStockist } from '@utils/guards';

export interface StockistsGridProps {
  data: ContentModels.ContentfulStockistsGrid;
}

const StockistsGrid: React.FC<StockistsGridProps> = ({ data }) => {
  return (
    <StockistsGridPres
      instore={createItemArray(data.instore)}
      online={createItemArray(data.online)}
      ctaText={data.ctaText}
      ctaTitle={data.ctaTitle}
      ctaUrl={data.ctaUrl}
    />
  );
};

export default StockistsGrid;

function createItemArray(entries?: Contentful.ContentfulEntry[]): StockistModel[] {
  const items = entries?.filter(isStockist) ?? [];
  return items.map(createItemModel);
}

function createItemModel(entry: ContentModels.ContentfulStockist): StockistModel {
  return {
    id: entry.id ?? '',
    logo: logo(entry.logo?.[0]?.public_id),
    name: entry.title ?? '',
    url: entry.url,
  };
}

export const ContentfulStockistsGridFragment = graphql`
  fragment ContentfulStockistsGridFragment on ContentfulStockistsGrid {
    ctaText
    ctaTitle
    ctaUrl
    instore {
      id
      sys {
        contentType {
          sys {
            id
          }
        }
      }
      ... on ContentfulStockist {
        logo
        title
        url
      }
    }
    online {
      id
      sys {
        contentType {
          sys {
            id
          }
        }
      }
      ... on ContentfulStockist {
        logo
        title
        url
      }
    }
    title
  }
`;
