import ChevronDown from '!babel-loader!react-svg-loader!@img/chevron-down.svg';
import { Device, from } from '@utils/media';
import srOnly from '@utils/srOnly';
import theme from '@utils/theme';
import unicode from '@utils/unicode';
import styled, { css } from 'styled-components';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  place-items: flex-start;
  margin: 0;
`;

export interface FormFieldProps {
  active: boolean;
  error: boolean;
  type?: string;
}

const FormField = styled.div<FormFieldProps>`
  display: grid;
  margin-bottom: 32px;
  max-width: 480px;
  width: 100%;

  ${({ active, type }) =>
    active &&
    type !== 'checkbox' &&
    css`
      ${FormLabel} {
        transform: translate3d(0, -50%, 0) scale(0.75);
      }
    `}

  ${({ error }) =>
    error &&
    css`
      ${FormLabel} {
        color: ${theme.brand.red.default};
      }

      ${FormInput} {
        color: ${theme.brand.red.default};
        border-color: ${theme.brand.red.default};
      }
    `}

  ${({ error, type }) => {
    switch (type) {
      case 'checkbox':
        return css`
          ${FormInput} {
            ${srOnly};
          }

          ${FormLabel} {
            position: relative;
            cursor: pointer;
            height: auto;
            pointer-events: all;
            padding-left: 48px;

            &::before {
              content: '';
              position: absolute;
              top: 50%;
              left: 0;
              display: flex;
              border: 2px solid ${theme.brand.blue.default};
              color: ${theme.brand.blue.default};
              height: 20px;
              flex: 0 0 20px;
              font-size: 14px;
              margin-top: 0;
              margin-right: 16px;
              place-content: center;
              place-items: center;
              transform: translate3d(0, -50%, 0);
              width: 20px;

              ${error &&
              css`
                border-color: ${theme.brand.red.default};
                color: ${theme.brand.red.default};
              `}

              @media ${from(Device.Tablet)} {
                height: 30px;
                flex: 0 0 30px;
                font-size: 22px;
                width: 30px;
              }
            }
          }

          ${FormInput}:checked + ${FormLabel} {
            &::before {
              content: '${unicode(2713)}';
            }
          }
        `;

      default:
        return null;
    }
  }}
`;

const FormLabel = styled.label`
  position: relative;
  display: block;
  border: 0;
  border-bottom: 2px solid transparent;
  color: ${theme.brand.blue.dark};
  ${theme.fonts.brandon.bold};
  font-size: 20px;
  grid-column: 1;
  grid-row: 1;
  line-height: 30px;
  height: 40px;
  margin: 0;
  outline: 0;
  padding: 0;
  pointer-events: none;
  text-transform: uppercase;
  transform: translate3d(0, 5px, 0);
  transform-origin: top left;
  transition: transform 0.3s ease-out;
  z-index: 2;
`;

const FormInput = styled.input`
  position: relative;
  display: block;
  background: transparent;
  border: 0;
  border-bottom: 2px solid ${theme.brand.blue.default};
  color: ${theme.brand.blue.dark};
  ${theme.fonts.brandon.bold};
  font-size: 20px;
  grid-column: 1;
  grid-row: 1;
  line-height: 30px;
  height: 40px;
  margin: 0;
  outline: 0;
  padding: 4px;
  transition: background-color 0.3s ease-out;
  width: 100%;
  z-index: 1;

  &:focus-visible {
    outline: 0;
  }

  select& {
    appearance: none;
    text-transform: uppercase;
  }

  textarea& {
    height: auto;
    min-height: 96px;
  }
`;

const FormSelectWrapper = styled.div`
  position: relative;
  grid-column: 1;
  grid-row: 1;
`;

const FormSelectIcon = styled(ChevronDown)`
  position: absolute;
  top: 50%;
  right: 16px;
  display: inline-block;
  color: ${theme.brand.red.default};
  height: 12px;
  pointer-events: none;
  transform: translateY(-50%);
  width: 24px;
  z-index: 1;
`;

const Honeypot = styled.input`
  ${srOnly};
`;

const FormContent = styled.div`
  margin-bottom: 32px;
`;

export default {
  Form,
  FormContent,
  FormField,
  FormLabel,
  FormInput,
  FormSelectIcon,
  FormSelectWrapper,
  Honeypot,
};
