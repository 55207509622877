import React from 'react';
import { FieldElement } from 'react-hook-form';
import { useBoolean } from 'react-use';
import s from './Form.styles';

export interface FormFieldModel {
  id: string;
  label: string;
  name?: string;
  options?: { text?: string; value: string | undefined }[];
  pattern?: RegExp;
  required?: boolean;
  type?: string;
}

export interface FormFieldProps extends Omit<FormFieldModel, 'pattern'> {
  hasError: boolean;
  hasValue: boolean;
}

const FormField = React.forwardRef<FieldElement, FormFieldProps>(
  ({ hasError, hasValue, id, label, name = id, options, required, type }, ref) => {
    const [isFocused, toggleFocused] = useBoolean(false);

    return (
      <s.FormField active={hasValue || isFocused} error={hasError} type={type}>
        {renderInput()}
        <s.FormLabel htmlFor={id}>
          {label}
          {required && '*'}
        </s.FormLabel>
      </s.FormField>
    );

    function renderInput(): React.ReactNode {
      switch (type) {
        case 'select':
          return (
            <s.FormSelectWrapper>
              <s.FormInput
                as="select"
                ref={ref as React.RefObject<HTMLSelectElement>}
                id={id}
                name={name}
                onFocus={() => {
                  toggleFocused(true);
                }}
                onBlur={() => {
                  toggleFocused(false);
                }}
              >
                {options?.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.text || option.value}
                  </option>
                ))}
              </s.FormInput>
              <s.FormSelectIcon />
            </s.FormSelectWrapper>
          );

        case 'textarea':
          return (
            <s.FormInput
              as="textarea"
              ref={ref as React.RefObject<HTMLTextAreaElement>}
              id={id}
              name={name}
              onFocus={() => {
                toggleFocused(true);
              }}
              onBlur={() => {
                toggleFocused(false);
              }}
            />
          );

        case 'checkbox':
          return (
            <s.FormInput
              as="input"
              ref={ref as React.RefObject<HTMLInputElement>}
              id={id}
              name={name}
              type={type}
            />
          );

        default:
          return (
            <s.FormInput
              as="input"
              ref={ref as React.RefObject<HTMLInputElement>}
              id={id}
              name={name}
              type={type}
              onFocus={() => {
                toggleFocused(true);
              }}
              onBlur={() => {
                toggleFocused(false);
              }}
            />
          );
      }
    }
  },
);

FormField.displayName = 'FormField';

export default FormField;
