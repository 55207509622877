import useWasViewed from '@hooks/useWasViewed';
import ImageFrame from '@presentation/ImageFrame';
import SectionWrapper from '@presentation/SectionWrapper';
import imageUrl from '@utils/cloudinary';
import { Variants } from 'framer-motion';
import { navigate } from 'gatsby';
import GatsbyImage, { FluidObject } from 'gatsby-image';
import React, { useState } from 'react';
import s from './StockistsGrid.styles';

export interface StockistModel {
  id: string;
  name: string;
  logo: FluidObject;
  url?: string;
}

export interface StockistsGridItemProps extends StockistModel {}

const StockistsGridItem: React.FC<StockistsGridItemProps> = ({ logo, name, url }) => {
  if (!logo) {
    return null;
  }

  const itemVariants: Variants = {
    hidden: {
      opacity: 0,
      scale: 0.8,
      y: 20,
    },
    visible: {
      opacity: 1,
      scale: 1,
      y: 0,
    },
  };

  const innerVariants: Variants = {
    inactive: {
      scale: 1,
    },
    active: {
      scale: 1.05,
      transition: {
        type: 'spring',
        damping: 8,
        restDelta: 0.01,
        restSpeed: 0.01,
        stiffness: 300,
      },
    },
  };

  return (
    <s.Item initial="hidden" variants={itemVariants}>
      <s.ItemLink
        href={url}
        target="_blank"
        initial="inactive"
        variants={innerVariants}
        whileFocus="active"
        whileHover="active"
      >
        <ImageFrame>
          <s.ItemImage>
            <GatsbyImage fluid={logo} alt={name} />
          </s.ItemImage>
        </ImageFrame>
      </s.ItemLink>
    </s.Item>
  );
};

export interface StockistsGridCtaProps {
  title: string;
  text: string;
  url: string;
}

const StockistsGridCta: React.FC<StockistsGridCtaProps> = ({ text, title, url }) => {
  const itemVariants: Variants = {
    hidden: {
      opacity: 0,
      scale: 0.8,
      y: 20,
    },
    visible: {
      opacity: 1,
      scale: 1,
      y: 0,
    },
  };

  const innerVariants: Variants = {
    inactive: {
      scale: 1,
    },
    active: {
      scale: 1.05,
      transition: {
        type: 'spring',
        damping: 8,
        restDelta: 0.01,
        restSpeed: 0.01,
        stiffness: 300,
      },
    },
  };

  return (
    <s.Cta initial="hidden" variants={itemVariants}>
      <s.CtaLink
        href={url}
        initial="inactive"
        variants={innerVariants}
        whileFocus="active"
        whileHover="active"
        onClick={(event) => {
          // Hack to work around the fact that Gatsby Link doesn't pass the motion props on.
          event.preventDefault();
          navigate(url);
        }}
      >
        <ImageFrame>
          <s.CtaInner>
            <s.CtaTitle>{title}</s.CtaTitle>
            <s.CtaButton>{text}</s.CtaButton>
          </s.CtaInner>
        </ImageFrame>
      </s.CtaLink>
    </s.Cta>
  );
};

export interface StockistsGridProps {
  ctaText?: string;
  ctaTitle?: string;
  ctaUrl?: string;
  instore: StockistModel[];
  online: StockistModel[];
}

const StockistsGrid: React.FC<StockistsGridProps> = ({
  ctaText,
  ctaTitle,
  ctaUrl,
  instore,
  online,
}) => {
  const { ref: refInView, wasViewed } = useWasViewed({
    rootMargin: '0px 0px -30% 0px',
  });

  const groups = [
    {
      id: 'online',
      items: online,
      title: 'Online',
    },
    {
      id: 'instore',
      items: instore,
      title: 'Instore',
    },
  ];

  const [activeId, setActiveId] = useState(groups[0].id);

  const navigationVariants: Variants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  };

  const itemGridVariants: Variants = {
    hidden: {
      pointerEvents: 'none',
      transition: {
        when: 'afterChildren',
      },
      transitionEnd: {
        display: 'none',
      },
    },
    visible: {
      display: 'grid',
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.15,
        staggerDirection: 1,
        when: 'beforeChildren',
      },
      transitionEnd: {
        pointerEvents: 'all',
      },
    },
  };

  return (
    <SectionWrapper ref={refInView} padding>
      <s.Container animate={wasViewed ? 'visible' : 'hidden'}>
        <s.Wave1 />
        <s.Wave2 />
        <s.Navigation initial="hidden" variants={navigationVariants}>
          {groups.map((group) => (
            <s.NavigationItem
              key={group.id}
              active={group.id === activeId}
              type="button"
              onClick={(event) => {
                event.preventDefault();
                setActiveId(group.id);
              }}
            >
              {group.title}
            </s.NavigationItem>
          ))}
        </s.Navigation>
        <s.ItemGridContainer>
          {groups.map((group) => (
            <s.ItemGrid
              key={group.id}
              animate={wasViewed && group.id === activeId ? 'visible' : 'hidden'}
              variants={itemGridVariants}
            >
              {group.items.map((item) => (
                <StockistsGridItem key={item.id} {...item} />
              ))}
              {ctaTitle && ctaText && ctaUrl && (
                <StockistsGridCta title={ctaTitle} text={ctaText} url={ctaUrl} />
              )}
            </s.ItemGrid>
          ))}
        </s.ItemGridContainer>
      </s.Container>
    </SectionWrapper>
  );
};

export default StockistsGrid;

export function logo(publicId?: string): FluidObject {
  return {
    aspectRatio: 1,
    sizes: '300px',
    src: imageUrl(publicId, 'c_pad,b_white,w_300,ar_1:1'),
    srcSet: `
      ${imageUrl(publicId, 'c_pad,b_white,w_300,ar_1:1')} 300w,
      ${imageUrl(publicId, 'c_pad,b_white,w_450,ar_1:1')} 450w,
      ${imageUrl(publicId, 'c_pad,b_white,w_600,ar_1:1')} 600w
    `,
  };
}
