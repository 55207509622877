import LinkedItem from '@connected/LinkedItem';
import RichTextPres from '@presentation/RichText';
import { Options } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { graphql } from 'gatsby';
import { renderRichText, RenderRichTextData } from 'gatsby-source-contentful/rich-text';
import React from 'react';
import { Contentful } from 'schema/Contentful';
import { Custom } from 'schema/Custom';
import RichTextLink from './RichTextLink';

export interface RichTextProps {
  className?: string;
  data: RenderRichTextData<Contentful.ContentfulEntry>;
}

const RichText: React.FC<RichTextProps> = ({ data, ...otherProps }) => {
  const options: Options = {
    renderNode: {
      /** Handle embedded components e.g. blockquotes etc */
      // eslint-disable-next-line react/display-name
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        // Get the target data, essentially a minimal data structure for a contentful entry.
        const target = node.data.target as Contentful.ContentfulEntry;

        // Locate the full reference, this includes data from the RichTextFragment.
        const item = findReference(target.contentful_id);

        // Ensure we have an item to render.
        if (!item) {
          return null;
        }

        // Delegate to the linked item component.
        return <LinkedItem item={item} index={0} inline />;
      },

      /** Handle links to content items. */
      // eslint-disable-next-line react/display-name
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        // Get the target data, essentially a minimal data structure for a contentful entry.
        const target = node.data.target as Contentful.ContentfulEntry;

        // Locate the full reference, this includes data from the RichTextFragment.
        const item = findReference(target.contentful_id) as Custom.NodeWithUrl;

        // Ensure we have an item to render.
        if (!item) {
          return null;
        }

        return <RichTextLink item={item}>{children}</RichTextLink>;
      },

      // Note: We can ignore these, there are no valid use cases for the site currently.
      [BLOCKS.EMBEDDED_ASSET]: () => null,
      [INLINES.ASSET_HYPERLINK]: () => null,
      [INLINES.EMBEDDED_ENTRY]: () => null,
    },
  };

  return <RichTextPres {...otherProps}>{renderRichText(data, options)}</RichTextPres>;

  /**
   * Get the referenced Contentful item.
   * @param id Contentful ID
   */
  function findReference(id: string): Contentful.ContentfulEntry | undefined {
    const item = data.references.find((r) => r.contentful_id === id);
    return item;
  }
};

export default RichText;

/**
 * This fragment should be extended to include the fragments for all
 * content types that can be used in a rich text field.
 */
export const RichTextFragment = graphql`
  fragment RichTextFragment on ContentfulRichText {
    raw
    references {
      __typename
      contentful_id
      id
      sys {
        contentType {
          sys {
            id
          }
        }
      }

      # Handle links to entries, the only thing that can be linked to is a page which should have a URL field.
      ... on NodeWithUrl {
        url
      }

      # Handle embedded content items - "widgets".

      ... on ContentfulContentImage {
        ...ContentfulContentImageFragment
      }

      ... on ContentfulQuoteBlock {
        ...ContentfulQuoteBlockFragment
      }

      ... on ContentfulTable {
        ...ContentfulTableFragment
      }

      ... on ContentfulVideo {
        ...ContentfulVideoFragment
      }
    }
  }
`;
