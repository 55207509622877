import { Link } from '@presentation/Button';
import ImageFrame from '@presentation/ImageFrame';
import { ButtonUnderline } from '@presentation/LinkUnderline';
import SectionWrapper, { ComponentThemeTaxonomy } from '@presentation/SectionWrapper';
import formatPrice from '@utils/formatPrice';
import imageUrl from '@utils/cloudinary';
import GatsbyImage, { FluidObject } from 'gatsby-image';
import Markdown from 'markdown-to-jsx';
import React from 'react';
import s from './ProductCta.styles';
import { Variants } from 'framer-motion';
import useWasViewed from '@hooks/useWasViewed';

export interface ProductCtaProps {
  content?: string;
  cta1Text?: string;
  cta2Text?: string;
  productId?: string;
  productImage?: FluidObject;
  productOption?: string;
  productPrice?: number;
  productTitle?: string;
  productUrl?: string;
  reverse?: boolean;
  subtitle?: string;
  title: string;
  onAddToCart: (shopifyId: string) => void;
  theme?: ComponentThemeTaxonomy;
}

const ProductCta: React.FC<ProductCtaProps> = ({
  content,
  cta1Text = 'View Details',
  cta2Text = 'Add to Basket',
  productId,
  productImage,
  productOption,
  productPrice,
  productTitle,
  productUrl,
  reverse = false,
  subtitle,
  title,
  onAddToCart,
  theme = ComponentThemeTaxonomy.LIGHT_BLUE,
}) => {
  const { ref: refInView, wasViewed } = useWasViewed({
    rootMargin: '0px 0px -50% 0px',
  });

  const titleVariants: Variants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.15,
      },
    },
  };

  const subtitleVariants: Variants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  };

  const contentVariants: Variants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.3,
      },
    },
  };

  const ctaVariants: Variants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.45,
      },
    },
  };

  const imageVariants: Variants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  };

  return (
    <SectionWrapper ref={refInView} padding theme={theme}>
      <s.Wrapper animate={wasViewed ? 'visible' : 'hidden'} reverse={reverse}>
        <s.Stamp />
        <s.ContentWrapper>
          <s.TitleWrapper>
            {title && (
              <s.Title initial="hidden" variants={titleVariants}>
                {title}
              </s.Title>
            )}
            {subtitle && (
              <s.Subtitle initial="hidden" variants={subtitleVariants}>
                {subtitle}
              </s.Subtitle>
            )}
          </s.TitleWrapper>
          {content && (
            <s.Content initial="hidden" variants={contentVariants}>
              <Markdown>{content}</Markdown>
            </s.Content>
          )}
          <s.CallToActions>
            <s.CallToAction1 initial="hidden" variants={ctaVariants}>
              <Link href={productUrl} target="_self">
                {cta1Text}
              </Link>
            </s.CallToAction1>
            <s.CallToAction2 initial="hidden" variants={ctaVariants}>
              <ButtonUnderline
                onClick={(event) => {
                  event.preventDefault();

                  if (productId) {
                    onAddToCart(productId);
                  }
                }}
              >
                {cta2Text}
              </ButtonUnderline>
            </s.CallToAction2>
          </s.CallToActions>
        </s.ContentWrapper>
        <s.ProductWrapper initial="hidden" variants={imageVariants}>
          <ImageFrame>
            {productImage && (
              <s.ProductImage>
                <GatsbyImage fluid={productImage} />
              </s.ProductImage>
            )}
            {productTitle && <s.ProductTitle>{productTitle}</s.ProductTitle>}
            <s.ProductMeta>
              {!!productPrice && <s.ProductPrice>{formatPrice(productPrice)}</s.ProductPrice>}
              {productOption && <s.ProductOption>{productOption}</s.ProductOption>}
            </s.ProductMeta>
          </ImageFrame>
        </s.ProductWrapper>
      </s.Wrapper>
    </SectionWrapper>
  );
};

export default ProductCta;

export function image(publicId?: string): FluidObject {
  return {
    aspectRatio: 420 / 560,
    sizes: '(max-width: 420px) 100vw, 420px',
    src: imageUrl(publicId, 'c_thumb,g_auto,w_420,ar_420:560'),
    srcSet: `
      ${imageUrl(publicId, 'c_thumb,g_auto,w_420,ar_420:560')} 420w,
      ${imageUrl(publicId, 'c_thumb,g_auto,w_840,ar_420:560')} 840w
    `,
  };
}
