import RichText from '@connected/RichText';
import SectionWrapper from '@presentation/SectionWrapper';
import React from 'react';
import { RichTextField } from 'schema/ContentModels';

export interface ContentSectionProps {
  content?: RichTextField;
}

const ContentSection: React.FC<ContentSectionProps> = ({ content }) => {
  if (!content) {
    return null;
  }

  return (
    <SectionWrapper>
      <RichText data={content} />
    </SectionWrapper>
  );
};

export default ContentSection;
