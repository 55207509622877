import { motion } from 'framer-motion';
import React from 'react';

export interface TurtleSwimmerProps {
  className?: string;
}

const TurtleSwimmer = React.forwardRef<SVGSVGElement, TurtleSwimmerProps>(
  ({ className, ...otherProps }, ref) => {
    return (
      <motion.svg ref={ref} className={className} viewBox="0 0 68.7 68.7" {...otherProps}>
        <motion.g id="body" transform="translate(68.684 68.685) rotate(180)">
          <path
            d="M17.2,43.8c-0.5-1.1-1-2.2-1.4-3.4l0,0c-1.3-3.8-2-7.7-2.1-11.7l-3.4-17.1c-0.5-2.8,0.4-5.7,2.2-7.9c2-2.4,4.9-3.7,8-3.8 c3.2,0,5.9,3,6.6,6.2c1-0.9,3.7-3.3,4.1-3.6c0.8-0.8,1.9-1.3,3-1.4c1.2,0.1,2.2,0.6,3.1,1.4c0.4,0.3,3,2.7,4.1,3.6 C42.2,3,44.8,0,48.1,0c3.1,0,6,1.4,8,3.8c1.9,2.2,2.7,5.1,2.2,7.9l-3.4,17.1c0,4-0.7,7.9-2.1,11.7l0,0c-0.4,1.2-1,2.3-1.4,3.4 c-1.2,3.2-5.2,8.4-8,10.3c-1.4,1-2.9,1.8-4.5,2.3l1,3.9c0,0.1,0,0.2,0,0.3v4.6c0,0.3-0.1,0.6-0.3,0.8l-2.3,2.3 c-0.2,0.2-0.5,0.3-0.8,0.3h-4.6c-0.3,0-0.6-0.1-0.8-0.3L29,66.1c-0.2-0.2-0.3-0.5-0.3-0.8v-4.6c0-0.1,0-0.2,0-0.3l1-3.9 c-1.6-0.5-3.1-1.3-4.5-2.3C22.6,52.1,18.6,46.9,17.2,43.8z M28.1,46.9l-5.7-5.7h-3.9c1.3,3.5,3.4,6.7,6,9.4L28.1,46.9z M37.3,19.5 h-5.9l-6.9,6.9l5.7,5.7h8.2l5.7-5.7L37.3,19.5z M45.8,24.7l4.3-4.3c-1.5-2.7-3.3-5.2-5.3-7.5l-5.4,5.4L45.8,24.7z M29.3,18.3 l-5.4-5.4c-2,2.3-3.8,4.8-5.3,7.5l4.3,4.3L29.3,18.3z M30.2,34.3l-5.7,5.7l5.7,5.7h8.2l5.7-5.7l-5.7-5.7H30.2z M17.7,38.9h4.7 l5.7-5.7L17.5,22.6c-0.9,1.9-1.4,4-1.5,6.1C16.1,32.1,16.6,35.6,17.7,38.9L17.7,38.9z M25.2,8c0-2.6-2.1-5.7-4.6-5.7 c-2.4,0-4.7,1.1-6.3,2.9c-1.4,1.6-2.1,3.8-1.7,6l2.3,11.7c0.4-1,0.8-1.9,1.3-2.8c0.3-0.6,0.6-1.2,1-1.7l-2.2-8.9l2.2-0.6l1.7,6.8 c0.8-1.2,1.7-2.3,2.7-3.5l-2-6.1l2.2-0.7l1.6,4.8c0.6-0.7,1.2-1.4,1.9-2L25.2,8z M37.1,5.4c-0.4-0.4-0.8-0.7-1.2-1.1 c-1.2-1.1-1.9-1.1-3.1,0C32.5,4.6,32,5,31.6,5.4c-2.2,1.8-4.2,3.8-6.2,5.8l6,6h5.9l6-6C41.3,9.1,39.2,7.2,37.1,5.4L37.1,5.4z M53.8,22.9l2.3-11.6c0.4-2.2-0.3-4.4-1.7-6c-1.6-1.9-3.9-2.9-6.3-2.9c-2.5,0-4.6,3.1-4.6,5.7c0,0,1.2,1.4,1.9,2.1L47,5.4l2.2,0.7 l-2,6.1c1,1.2,1.9,2.4,2.7,3.5l1.7-6.8l2.2,0.6l-2.2,8.9c0.4,0.6,0.7,1.2,1,1.7C53,21,53.4,21.9,53.8,22.9L53.8,22.9z M52.7,28.6 c-0.1-2.1-0.6-4.2-1.5-6.1L40.5,33.2l5.7,5.7H51C52.1,35.6,52.6,32.1,52.7,28.6L52.7,28.6z M44.2,50.6c2.6-2.7,4.6-5.9,6-9.4h-3.9 l-5.7,5.7L44.2,50.6z M30.9,64.8l1.6,1.6h3.6l1.6-1.6v-4L36.8,57c-1.6,0.3-3.3,0.3-5,0l-0.9,3.8L30.9,64.8z M34.3,54.9 c2.9,0,5.8-1,8.1-2.9l-4-4h-8.2l-4,4C28.5,53.9,31.4,54.9,34.3,54.9L34.3,54.9z"
            fill="currentColor"
          />
        </motion.g>
        <motion.g id="flipper-l">
          <path
            d="M22.9,17.8l0-1.6c0-3.2-2.6-5.7-5.7-5.7h-5.7c-1.9-0.2-3.7,0.5-4.9,2L3.9,15H16c1.9,0,3.4,1.5,3.4,3.4v0V22l-2.3,4.2v-7.7 c0-0.6-0.5-1.1-1.1-1.1l0,0H1.1c-0.6,0-1.1-0.5-1.1-1.1c0-0.3,0.1-0.6,0.3-0.8l4.6-4.6C6.6,9,9,8,11.4,8.2h5.7c4.3,0,7.8,3.4,8,7.7 L22.9,17.8z"
            fill="currentColor"
          />
        </motion.g>
        <motion.g id="flipper-r">
          <path
            d="M43.5,15.9c0.2-4.3,3.7-7.7,8-7.7h5.7c2.5-0.2,4.9,0.8,6.5,2.6l4.6,4.6c0.2,0.2,0.3,0.5,0.3,0.8c0,0.6-0.5,1.1-1.1,1.1 H52.7l0,0c-0.6,0-1.1,0.5-1.1,1.1v7.7L49.2,22v-3.5v0c0-1.9,1.5-3.4,3.4-3.4h12.1l-2.6-2.6c-1.2-1.4-3.1-2.1-4.9-2h-5.7 c-3.2,0-5.7,2.6-5.7,5.7l0,1.6L43.5,15.9z"
            fill="currentColor"
          />
        </motion.g>
      </motion.svg>
    );
  },
);

TurtleSwimmer.displayName = 'TurtleSwimmer';

export default TurtleSwimmer;
