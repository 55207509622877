import theme from '@utils/theme';
import styled from 'styled-components';

const Container = styled.section`
  color: ${theme.brand.blue.dark};
  ${theme.fonts.brandon.medium};
  font-size: 20px;
  line-height: 28px;
  margin: 0 auto;
  max-width: 800px;
  width: 100%;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 1em;
    ${theme.fonts.venusian};
    margin-bottom: 1em;
    text-transform: uppercase;
  }

  h1 {
    font-size: 1.5em;
  }

  h2 {
    font-size: 1.3em;
  }

  h3 {
    font-size: 1.2em;
  }

  p {
    margin-bottom: 1em;
  }

  b,
  strong {
    color: ${theme.brand.red.dark};
    ${theme.fonts.brandon.bold};
  }

  i,
  em {
    color: ${theme.brand.red.dark};
    ${theme.fonts.brandon.mediumItalic};
  }

  a {
    background: linear-gradient(to right, #2067ad, #2067ad) no-repeat;
    background-position: 0 6px;
    background-size: 0% 75%;
    color: ${theme.brand.red.dark};
    margin: -4px;
    outline: 0;
    padding: 4px;
    text-decoration: none;
    transition: background-size 0.15s ease-out, color 0.15s ease-out;

    &:focus,
    &:hover {
      background-size: 100% 75%;
      color: ${theme.brand.white.default};
      outline: 0;
    }
  }

  ol,
  ul {
    margin: 0 0 1em 32px;

    li {
      margin: 0 0 0.5em;

      &::marker {
        color: ${theme.brand.red.dark};
      }

      p {
        margin: 0;
      }
    }
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }
`;

export default {
  Container,
};
