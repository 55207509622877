import { Link } from '@presentation/Button';
import ContentFrame from '@presentation/ContentFrame';
import { LinkUnderline } from '@presentation/LinkUnderline';
import SectionWrapper, { ComponentThemeTaxonomy } from '@presentation/SectionWrapper';
import imageUrl from '@utils/cloudinary';
import GatsbyImage, { FluidObject } from 'gatsby-image';
import React from 'react';
import s, { ContentWrapperProps } from './BackgroundImageCta.styles';

export interface BackgroundImageCtaProps extends ContentWrapperProps {
  cta1NewWindow?: boolean;
  cta1Text?: string;
  cta1Url?: string;
  cta2NewWindow?: boolean;
  cta2Text?: string;
  cta2Url?: string;
  image: FluidObject;
  showContent?: boolean;
  title: string;
  theme?: ComponentThemeTaxonomy;
}

const BackgroundImageCta: React.FC<BackgroundImageCtaProps> = ({
  align = 'left',
  cta1NewWindow,
  cta1Text,
  cta1Url,
  cta2NewWindow,
  cta2Text,
  cta2Url,
  image,
  showContent = true,
  title,
  theme = ComponentThemeTaxonomy.NONE,
}) => {
  return (
    <SectionWrapper fullWidth theme={theme}>
      <s.Wrapper>
        <s.Image>
          <GatsbyImage alt="" fluid={image} />
        </s.Image>
        {hasContent() && (
          <s.SiteWide>
            <s.ContentWrapper align={align}>
              <s.ContentFrame>
                <ContentFrame>
                  <s.Title>{title}</s.Title>
                  {(cta1Url || cta2Url) && (
                    <s.CallToActions>
                      {cta1Url && (
                        <s.CallToAction1>
                          <Link href={cta1Url} target={cta1NewWindow ? '_blank' : '_self'}>
                            {cta1Text}
                          </Link>
                        </s.CallToAction1>
                      )}
                      {cta2Url && (
                        <s.CallToAction2>
                          <LinkUnderline href={cta2Url} target={cta2NewWindow ? '_blank' : '_self'}>
                            {cta2Text}
                          </LinkUnderline>
                        </s.CallToAction2>
                      )}
                    </s.CallToActions>
                  )}
                </ContentFrame>
              </s.ContentFrame>
            </s.ContentWrapper>
          </s.SiteWide>
        )}
      </s.Wrapper>
    </SectionWrapper>
  );

  function hasContent(): boolean {
    return showContent && !!(title || cta1Url || cta2Url);
  }
};

export default BackgroundImageCta;

export function image(publicId?: string): FluidObject {
  return {
    aspectRatio: 4 / 3,
    sizes: '(max-width: 1920px) 100vw, 1920px',
    src: imageUrl(publicId, 'c_fill,g_auto,w_1920,ar_4:3'),
    srcSet: `
      ${imageUrl(publicId, 'c_fill,g_auto,w_480,ar_4:3')} 480w,
      ${imageUrl(publicId, 'c_fill,g_auto,w_960,ar_4:3')} 960w,
      ${imageUrl(publicId, 'c_fill,g_auto,w_1440,ar_4:3')} 1440w,
      ${imageUrl(publicId, 'c_fill,g_auto,w_1920,ar_4:3')} 1920w`,
  };
}
