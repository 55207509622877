/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from '@presentation/Button';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useBoolean } from 'react-use';
import FormField, { FormFieldModel } from './FormField';
import s from './Form.styles';
import Markdown from 'markdown-to-jsx';

export interface FormProps {
  content?: string;
  fields: FormFieldModel[];
  name: string;
  submitText: string;
  onSuccess?: () => void;
}

const Form: React.FC<FormProps> = ({ content, fields, name, submitText, onSuccess }) => {
  const { register, handleSubmit, watch, errors } = useForm();

  const [isSubmitting, setIsSubmitting] = useBoolean(false);

  // POST to Netlify forms.
  // @see: https://docs.netlify.com/forms/setup/#submit-javascript-rendered-forms-with-ajax
  const onSubmit = async (
    data: Record<string, any>,
    event?: React.BaseSyntheticEvent,
  ): Promise<void> => {
    setIsSubmitting(true);

    await fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        ...data,
        'form-name': event?.target.getAttribute('name'),
        honeypot: 'bot-field',
      }),
    });

    if (onSuccess) {
      onSuccess();
    }

    setIsSubmitting(false);
  };

  const data = watch();

  return (
    <s.Form
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      name={name}
      onSubmit={handleSubmit(onSubmit)}
    >
      <input name="form-name" type="hidden" value={name} />
      {fields?.map((field) => (
        <FormField
          key={field.id}
          {...field}
          ref={register({ pattern: field.pattern, required: field.required })}
          hasError={!!errors[field.id]}
          hasValue={!!data[field.id]}
        />
      ))}
      <s.Honeypot
        ref={register({ required: false })}
        autoComplete="off"
        name="bot-field"
        type="text"
      />
      {content && (
        <s.FormContent>
          <Markdown>{content}</Markdown>
        </s.FormContent>
      )}
      <Button disabled={isSubmitting} type="submit">
        {submitText}
      </Button>
    </s.Form>
  );
};

export default Form;

// Encode data using URL-encoding.
// @see: https://docs.netlify.com/forms/setup/#submit-javascript-rendered-forms-with-ajax
function encode(data: Record<string, any>): string {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}
