import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import FramedCtaPres from '@presentation/FramedCta';
import { ContentModels } from 'schema/ContentModels';
import { FixedObject } from 'gatsby-image';

export interface FramedCtaProps {
  data: ContentModels.ContentfulFramedCta;
}

const FramedCta: React.FC<FramedCtaProps> = ({ data }) => {
  const { badge, turtle } = useImages();

  return (
    <FramedCtaPres
      badge={badge}
      content={data.content?.content}
      ctaNewWindow={data.ctaNewWindow}
      ctaText={data.ctaText}
      ctaUrl={data.ctaUrl}
      title={data.title}
      turtle={turtle}
    />
  );
};

export default FramedCta;

interface FileQuery {
  badge?: {
    childCloudinaryAsset?: {
      fixed?: FixedObject;
    };
  };
  turtle?: {
    childCloudinaryAsset?: {
      fixed?: FixedObject;
    };
  };
}

interface UseImagesReturn {
  badge?: FixedObject;
  turtle?: FixedObject;
}

function useImages(): UseImagesReturn {
  const data = useStaticQuery<FileQuery>(graphql`
    {
      badge: file(name: { eq: "sot-badge" }) {
        name
        childCloudinaryAsset {
          fixed(width: 150, height: 150, transformations: "f_auto,q_auto") {
            aspectRatio
            height
            src
            srcSet
            width
          }
        }
      }
      turtle: file(name: { eq: "turtle-red" }) {
        name
        childCloudinaryAsset {
          fixed(width: 308, height: 219, transformations: "f_auto,q_auto") {
            aspectRatio
            height
            src
            srcSet
            width
          }
        }
      }
    }
  `);

  return {
    badge: data.badge?.childCloudinaryAsset?.fixed,
    turtle: data.turtle?.childCloudinaryAsset?.fixed,
  };
}

export const ContentfulFramedCtaFragment = graphql`
  fragment ContentfulFramedCtaFragment on ContentfulFramedCta {
    content {
      content
    }
    ctaNewWindow
    ctaText
    ctaUrl
    title
  }
`;
