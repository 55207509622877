import { markdownStyles } from '@utils/markdown';
import theme from '@utils/theme';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const Wrapper = styled(motion.div)`
  text-align: center;
`;

const Title = styled(motion.h2)`
  ${theme.fonts.brandon.bold};
  font-size: 22px;
  letter-spacing: 0.05em;
  line-height: 31px;
  margin-bottom: 24px;
  text-transform: uppercase;
`;

const Items = styled(motion.div)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  place-content: center;
  place-items: center;
`;

const Item = styled(motion.blockquote)`
  flex: 1 1 25%;
  margin: 0 24px 24px;
  max-width: 280px;
`;

const Logo = styled(motion.div)`
  margin-bottom: 16px;
`;

const Content = styled(motion.div)`
  ${markdownStyles};
  ${theme.fonts.brandon.bold};
  font-size: 20px;
  letter-spacing: 0.02em;
  line-height: 28px;
  text-transform: uppercase;
  quotes: '“' '”';

  &::before {
    content: open-quote;
  }

  &::after {
    content: close-quote;
  }
`;

export default {
  Content,
  Item,
  Items,
  Logo,
  Title,
  Wrapper,
};
