import Markdown from 'markdown-to-jsx';
import React from 'react';
import s from './QuoteBlock.styles';

export interface QuoteBlockProps {
  citation?: string;
  content: string;
}

const QuoteBlock: React.FC<QuoteBlockProps> = ({ citation, content }) => {
  return (
    <s.Container>
      {content && (
        <s.Content>
          <Markdown>{content}</Markdown>
        </s.Content>
      )}
      {citation && <s.Citation>{citation}</s.Citation>}
    </s.Container>
  );
};

export default QuoteBlock;
