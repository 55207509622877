import { Link } from 'gatsby';
import React from 'react';
import { Contentful } from 'schema/Contentful';
import { Custom } from 'schema/Custom';

interface RichTextLink {
  asset?: Contentful.ContentfulAsset;
  item?: Custom.NodeWithUrl;
}

const RichTextLink: React.FC<RichTextLink> = ({ asset, children, item }) => {
  // If an asset then render link to open in new window.
  if (asset?.file?.url) {
    return (
      <a href={asset.file.url} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  }

  // If an entry then render Gatsby Link for client-side navigation.
  if (item?.url) {
    return <Link to={item.url}>{children}</Link>;
  }

  // Fallback to children with no link.
  return <a href="#">{children}</a>;
};

export default RichTextLink;
