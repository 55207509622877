import { graphql } from 'gatsby';
import React from 'react';
import TickerPres, { TickerItemModel } from '@presentation/Ticker';
import { isTickerItem } from '@utils/guards';
import { ContentModels } from 'schema/ContentModels';
import { Contentful } from 'schema/Contentful';

interface TickerProps {
  data: ContentModels.ContentfulTicker;
}

const Ticker: React.FC<TickerProps> = ({ data }) => {
  const direction = getDirection(data.direction);

  return <TickerPres direction={direction} items={createItemArray(data.items)} />;
};

export default Ticker;

function createItemArray(entries?: Contentful.ContentfulEntry[]): TickerItemModel[] {
  const menuItems = entries?.filter(isTickerItem) ?? [];
  return menuItems.map(createItemModel);
}

function createItemModel(entry: ContentModels.ContentfulTickerItem): TickerItemModel {
  return {
    id: entry.id ?? '',
    content: entry.tickerItemContent ?? '',
  };
}

function getDirection(input?: string): 'forwards' | 'backwards' {
  switch (input?.toLowerCase()) {
    case 'backwards':
      return 'backwards';

    default:
      return 'forwards';
  }
}

export const ContentfulTickerFragment = graphql`
  fragment ContentfulTickerFragment on ContentfulTicker {
    direction
    items {
      id
      sys {
        contentType {
          sys {
            id
          }
        }
      }
      ... on ContentfulTickerItem {
        tickerItemContent: content
      }
    }
  }
`;
