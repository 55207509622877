import { graphql } from 'gatsby';
import React from 'react';
import VideoPres from '@presentation/Video';
import { ContentModels } from 'schema/ContentModels';

export interface VideoProps {
  data: ContentModels.ContentfulVideo;
}

const Video: React.FC<VideoProps> = ({ data }) => {
  return <VideoPres video={data.video ?? ''} videoProvider="youtube" />;
};

export default Video;

export const ContentfulVideoFragment = graphql`
  fragment ContentfulVideoFragment on ContentfulVideo {
    video
  }
`;
