import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import SimpleBannerPres from '@presentation/SimpleBanner';
import { ContentModels } from 'schema/ContentModels';
import { FixedObject } from 'gatsby-image';

export interface SimpleBannerProps {
  data: ContentModels.ContentfulSimpleBanner;
}

const SimpleBanner: React.FC<SimpleBannerProps> = ({ data }) => {
  const turtleImage = useTurtleImage();

  return (
    <SimpleBannerPres
      ctaNewWindow={data.ctaNewWindow}
      ctaText={data.ctaText}
      ctaUrl={data.ctaUrl}
      subtitle={data.subtitle}
      title={data.title}
      turtleImage={turtleImage}
    />
  );
};

export default SimpleBanner;

interface FileQuery {
  file?: {
    childCloudinaryAsset?: {
      fixed?: FixedObject;
    };
  };
}

function useTurtleImage(): FixedObject | undefined {
  const data = useStaticQuery<FileQuery>(graphql`
    {
      file(name: { eq: "turtle-red" }) {
        name
        childCloudinaryAsset {
          fixed(width: 483, height: 343, transformations: "f_auto,q_auto") {
            aspectRatio
            height
            src
            srcSet
            width
          }
        }
      }
    }
  `);

  return data.file?.childCloudinaryAsset?.fixed;
}

export const ContentfulSimpleBannerFragment = graphql`
  fragment ContentfulSimpleBannerFragment on ContentfulSimpleBanner {
    ctaNewWindow
    ctaText
    ctaUrl
    subtitle
    title
  }
`;
