import { graphql } from 'gatsby';
import React from 'react';

import CocktailsListPres, { CocktailModel, itemImage } from '@presentation/CocktailsList';
import { ContentModels } from 'schema/ContentModels';
import { Contentful } from 'schema/Contentful';
import { isCocktail } from '@utils/guards';

export interface CocktailsListProps {
  data: ContentModels.ContentfulCocktailsList;
}

const CocktailsList: React.FC<CocktailsListProps> = ({ data }) => {
  return <CocktailsListPres items={createItemArray(data.cocktails)} />;
};

export default CocktailsList;

function createItemArray(entries?: Contentful.ContentfulEntry[]): CocktailModel[] {
  const menuItems = entries?.filter(isCocktail) ?? [];
  return menuItems.map(createItemModel);
}

function createItemModel(entry: ContentModels.ContentfulCocktail): CocktailModel {
  return {
    id: entry.id ?? '',
    content: entry.content?.content ?? '',
    image: itemImage(entry.image?.[0]?.public_id),
    imageId: entry.image?.[0]?.public_id ?? '',
    ingredients: entry.ingredients,
    published: new Date(entry.updatedAt ?? ''),
    title: entry.title ?? '',
    url: `/cocktails#${entry.id}`,
  };
}

export const ContentfulCocktailsListFragment = graphql`
  fragment ContentfulCocktailsListFragment on ContentfulCocktailsList {
    cocktails {
      id
      sys {
        contentType {
          sys {
            id
          }
        }
      }
      ... on ContentfulCocktail {
        content {
          content
        }
        image
        ingredients
        title
        updatedAt
      }
    }
    title
  }
`;
