import TurtleSwimmer from '@presentation/TurtleSwimmer';
import { Device, from } from '@utils/media';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const Container = styled(motion.div)`
  position: relative;
  display: flex;
  flex-direction: column;
  place-content: center;
  place-items: center;
`;

const CallToAction = styled(motion.div)``;

const TurtleWrapper = styled.div`
  position: absolute;
  display: none;

  @media ${from(Device.TabletLarge)} {
    display: block;
  }
`;

const Turtle = styled(motion.custom(TurtleSwimmer))`
  position: absolute;
  width: 72px;
`;

const TurtlePath = styled.svg`
  position: absolute;
  visibility: hidden;
`;

export default {
  CallToAction,
  Container,
  Turtle,
  TurtlePath,
  TurtleWrapper,
};
