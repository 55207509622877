import { Device, from } from '@utils/media';
import theme from '@utils/theme';
import unicode from '@utils/unicode';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const Container = styled.section`
  display: grid;
  gap: 32px 16px;
  grid-template-columns: repeat(4, 1fr);

  @media ${from(Device.TabletLarge)} {
    display: grid;
    gap: 32px;
    grid-template-columns: repeat(12, 1fr);
  }
`;

const ItemContainer = styled(motion.div)`
  grid-column: 1 / span 4;
  text-decoration: none;

  @media ${from(Device.TabletLarge)} {
    display: grid;
    grid-column: 2 / span 10;
    grid-template-columns: 386px 1fr;
  }
`;

const ImageContainer = styled.div`
  @media ${from(Device.DesktopSmall)} {
    background: linear-gradient(to right, ${theme.brand.blue.light}, ${theme.brand.blue.light});
    background-position: top right;
    background-repeat: no-repeat;
    background-size: 50% 100%;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  background: ${theme.brand.blue.light};
  border: 3px solid ${theme.brand.blue.dark};
  border-top: 0;
  border-radius: 0 0 10px 10px;
  flex-direction: column;
  justify-content: center;
  padding: 32px;

  @media ${from(Device.TabletLarge)} {
    border: 3px solid ${theme.brand.blue.dark};
    border-left: 0;
    border-radius: 0 10px 10px 0;
    padding: 32px 128px 32px 48px;
  }
`;

const Title = styled.h3`
  color: ${theme.brand.red.default};
  font-size: 24px;
  line-height: 1.2em;
  margin: 0 0 16px;
  text-transform: uppercase;

  @media ${from(Device.DesktopSmall)} {
    font-size: 40px;
    line-height: 45px;
  }
`;

const Content = styled.div`
  color: ${theme.brand.blue.dark};
  ${theme.fonts.brandon.bold};
  font-size: 16px;
  letter-spacing: 0.02em;
  line-height: 1.5em;
  margin: 0 0 32px;
  text-transform: uppercase;

  @media ${from(Device.DesktopSmall)} {
    font-size: 20px;
    letter-spacing: 0.02em;
    line-height: 28px;
  }
`;

const Subtitle = styled.h4`
  color: ${theme.brand.blue.dark};
  ${theme.fonts.venusian};
  font-size: 16px;
  letter-spacing: 0.02em;
  line-height: 1.5em;
  margin: 0 0 8px;
  text-transform: uppercase;

  @media ${from(Device.DesktopSmall)} {
    font-size: 18px;
    letter-spacing: normal;
    line-height: 28px;
  }
`;

const Ingredients = styled.ul`
  display: grid;
  color: ${theme.brand.blue.dark};
  ${theme.fonts.brandon.bold};
  font-size: 16px;
  grid-template-columns: repeat(1, 1fr);
  letter-spacing: 0.02em;
  line-height: 28px;
  margin: 0;
  text-transform: uppercase;

  @media ${from(Device.Tablet)} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${from(Device.TabletLarge)} {
    grid-template-columns: repeat(1, 1fr);
  }

  @media ${from(Device.DesktopSmall)} {
    font-size: 16px;
    grid-template-columns: repeat(2, 1fr);
    letter-spacing: 0.02em;
    line-height: 28px;
  }
`;

const Ingredient = styled.li`
  &::before {
    content: '${unicode(2014)}${unicode(20)}';
  }
`;

const Anchor = styled.a`
  display: block;
  grid-column: 1 / span 2;
  grid-row: 1;
`;

export default {
  Anchor,
  Container,
  Content,
  ContentContainer,
  ImageContainer,
  Ingredient,
  Ingredients,
  ItemContainer,
  Subtitle,
  Title,
};
