import GatsbyImage, { FixedObject, FluidObject } from 'gatsby-image';
import chunk from 'lodash/chunk';
import React from 'react';
import SectionWrapper, { ComponentThemeTaxonomy } from '@presentation/SectionWrapper';
import s from './RumFacts.styles';
import { useMedia } from 'react-use';
import { Device, from } from '@utils/media';
import imageUrl from '@utils/cloudinary';
import Markdown from 'markdown-to-jsx';
import { motion, Variants } from 'framer-motion';
import useWasViewed from '@hooks/useWasViewed';

export interface FactModel {
  id: string;
  icon: string;
  content: string;
}

export interface FactItemProps extends FactModel {}

const FactItem: React.FC<FactItemProps> = ({ content, icon }) => {
  const itemVariants: Variants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.15,
      },
    },
  };

  const iconVariants: Variants = {
    hidden: {
      opacity: 0,
      scale: 0.5,
    },
    visible: {
      opacity: 1,
      scale: 1,
    },
  };

  const contentVariants: Variants = {
    hidden: {
      opacity: 0,
      x: -50,
    },
    visible: {
      opacity: 1,
      x: 0,
    },
  };

  const arrowVariants: Variants = {
    hidden: {
      opacity: 0,
      pathLength: 0,
    },
    visible: {
      opacity: 1,
      pathLength: 1,
    },
  };

  return (
    <s.FactItem initial="hidden" variants={itemVariants}>
      <s.FactItemIconWrapper initial="hidden" variants={iconVariants}>
        <s.FactItemIcon src={icon} />
      </s.FactItemIconWrapper>
      <s.FactItemContent initial="hidden" variants={contentVariants}>
        {content}
      </s.FactItemContent>
      <s.FactArrow viewBox="0 0 96.7 98.44">
        <g>
          <motion.path
            d="M4,4S2.5,32.47,21.53,51.5c22,22,50.94,21.8,50.94,21.8"
            style={{
              fill: 'none',
              stroke: 'currentColor',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '8px',
            }}
            initial="hidden"
            variants={arrowVariants}
          />
          <motion.polyline
            points="66.53 52.5 92.7 73.3 66.53 94.44"
            style={{
              fill: 'none',
              stroke: 'currentColor',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '8px',
            }}
            initial="hidden"
            variants={arrowVariants}
          />
        </g>
      </s.FactArrow>
    </s.FactItem>
  );
};

export interface RumFactsProps {
  title?: string;
  subtitle?: string;
  content?: string;
  image: FluidObject;
  badge: FixedObject;
  facts: FactModel[];
  theme?: ComponentThemeTaxonomy;
}

const RumFacts: React.FC<RumFactsProps> = ({
  title,
  subtitle,
  content,
  image,
  badge,
  facts,
  theme = ComponentThemeTaxonomy.NONE,
}) => {
  const isTabletOrLarger = useMedia(from(Device.MobileLarge));

  const { ref: refInView, wasViewed } = useWasViewed({
    rootMargin: '0px 0px -50% 0px',
  });

  const titleVariants: Variants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.15,
      },
    },
  };

  const subtitleVariants: Variants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  };

  const contentVariants: Variants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.3,
      },
    },
  };

  const itemListVariants: Variants = {
    hidden: {},
    visible: {
      transition: {
        delay: 0.45,
        delayChildren: 0.45,
        staggerChildren: 0.15,
      },
    },
  };

  const imageVariants: Variants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.45,
      },
    },
  };

  return (
    <SectionWrapper ref={refInView} padding theme={theme}>
      <s.SvgSugarcane1 />
      <s.SvgSugarcane2 />
      <s.ContentContainer animate={wasViewed ? 'visible' : 'hidden'}>
        <s.TitleContainer>
          {title && (
            <s.Title initial="hidden" variants={titleVariants}>
              {title}
            </s.Title>
          )}
          {subtitle && (
            <s.Subtitle initial="hidden" variants={subtitleVariants}>
              {subtitle}
            </s.Subtitle>
          )}
        </s.TitleContainer>
        {content && (
          <s.Content initial="hidden" variants={contentVariants}>
            <Markdown>{content}</Markdown>
          </s.Content>
        )}
      </s.ContentContainer>
      <s.ImageContainer
        animate={wasViewed ? 'visible' : 'hidden'}
        initial="hidden"
        variants={imageVariants}
      >
        <s.Image>
          <GatsbyImage fluid={image} fadeIn={false} />
          <s.Badge>
            <GatsbyImage fixed={badge} fadeIn={false} />
          </s.Badge>
        </s.Image>
        <s.FactsWrapper>
          <s.FactsList initial="hidden" variants={itemListVariants}>
            {facts.map((item) => (
              <FactItem key={item.id} {...item} />
            ))}
          </s.FactsList>
        </s.FactsWrapper>
      </s.ImageContainer>
    </SectionWrapper>
  );
};

export default RumFacts;

export function image(publicId?: string): FluidObject {
  return {
    aspectRatio: 311 / 791,
    sizes: '(max-width: 311px) 100vw, 311px',
    src: imageUrl(publicId, 'c_fill,g_auto,w_311,ar_311:791'),
    srcSet: `
      ${imageUrl(publicId, 'c_fill,g_auto,w_311,ar_311:791')} 311w,
      ${imageUrl(publicId, 'c_fill,g_auto,w_622,ar_311:791')} 622w
    `,
  };
}

export function badge(publicId?: string): FixedObject {
  return {
    height: 190,
    src: imageUrl(publicId, 'c_fill,g_auto,w_190,ar_1:1'),
    srcSet: `
      ${imageUrl(publicId, 'c_fill,g_auto,w_190,ar_1:1')} 1x,
      ${imageUrl(publicId, 'c_fill,g_auto,w_380,ar_1:1')} 2x
    `,
    width: 190,
  };
}
